import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import ModuleTitle from "@/component/ModuleTitle";
import { Table, Button, message } from "antd";
import { getTableScroll } from "@/store/index";
import { useLocation } from "react-router-dom";
import { getAIReportDetail, downloadAIReport } from "@/pages/LuZhiBI/api";

function ReportFormDetail() {
  let location = useLocation();
  const [id, setId] = useState(location.state?.reportId);
  const [reportName, setReportName ] = useState(location.state?.reportName);
  const [columns, setColumns] = useState<any>([]);
  // 历史问句数据源
  const [tableDataSource, setTableDataSource] = useState<any>([]);
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });

  useEffect(() => {
    getReportDetail();
  }, [pageQuery.pageNo, pageQuery.pageSize]);
  
  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: "updateTime", isAsc: "desc" };
    } else {
      sortStore = {
        orderByColumn: sorter.columnKey,
        isAsc: sorter.order === "ascend" ? "asc" : "desc",
      };
    }

    getReportDetail();
  };

  // 获取报表列表
  const getReportDetail = async () => {
    getAIReportDetail(id).then((res) => {
      if (res?.code === 200) {
        setTableDataSource(res.data);
        let columnsArr: {
          title: string;
          dataIndex: string;
          key: string;
          width: number;
        }[] = [];
        const tableKeys = Object.keys(res.data[0]);
        tableKeys.forEach((key: string) => {
          columnsArr.push({
            title: key,
            dataIndex: key,
            key: key,
            width: 250,
          });
        });
        setColumns(columnsArr);
        setPageQuery({
          pageSize: pageQuery.pageSize,
          pageNo: pageQuery.pageNo,
          total: pageQuery.total,
        });
      }
    });
  };
  const downloadReportForm = async () => {
    const res = await downloadAIReport(
      id,
      {
        taskLocation: "BI",
        moduleName: "BI-报表",
      }
    );
    if (res) {
      message.success(DOWNLOAD_SUCCESS_TEXT);
    }
  };

  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title={reportName} />
        </div>
        <div>
          <Button type="default" onClick={downloadReportForm}>
            下载
          </Button>
        </div>
      </div>
      <div className={styles.tableBox}>
        <Table
          columns={columns}
          dataSource={tableDataSource}
          rowKey={(record) => record.key}
          scroll={{ x: 750, y: getTableScroll() }}
          pagination={{
            total: pageQuery.total,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50", "100"],
            showTotal: (total) => `共 ${total} 条`,
          }}
          onChange={handleStandardTableChange}
        />
      </div>
    </div>
  );
}

export default ReportFormDetail;
