/**
 * 商品销售追踪
 */
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import TaskList from "./../../Industry/component/TaskList";
import { Tabs, Radio } from "antd";
import Header from "../component/Header";
import AnalysisChart from "./component/AnalysisChart"; // 商品/品类-销售帕累托分析
import GoodsTop10Chart from "./component/Top10Chart"; // 商品-top10销售分布
import GategoryTop7Chart from "./component/Top7Chart"; // 品类-top7销售分布
import SalesDetail from "./component/SalesDetail";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";
import { checkPermissions } from "@/utils/utils";
import { INDUSTRYPRODUCTSALES_KEY } from "@/router/Permissions";

const ProductSalesTracking: React.FC = () => {
  const { metricAllList } = useGetMetricList();
  const [taskListVisible, setTaskListVisible] = useState(false);
  const [tabKey, seTabKey] = useState("0");
  const [searchForm, setSearchForm] = useState<any>({});

  const tabList = [
    {
      key: "0",
      label: "商品",
    },
    {
      key: "1",
      label: "品类",
    },
  ];
  const [radioType, setRadioType] = useState("SPU");

  return (
    <div>
      {checkPermissions(INDUSTRYPRODUCTSALES_KEY.OVERVIEW_LIST) && (
        <>
          <Header
            title={"商品销售追踪"}
            searchForm={searchForm}
            hasExport={false}
            setSearchForm={setSearchForm}
          />
          <div className={styles.productSales}>
            <Tabs
              activeKey={tabKey}
              items={tabList}
              onChange={(e) => seTabKey(e)}
              style={{ marginTop: 12 }}
            />
            {tabKey === "0" && (
              <div>
                <Radio.Group
                  value={radioType}
                  style={{ marginTop: "5px" }}
                  onChange={(e) => setRadioType(e.target.value)}
                >
                  <Radio value={"SPU"}>Spu</Radio>
                  <Radio value={"SKU"}>Sku</Radio>
                </Radio.Group>
                <div className={styles.wrap}>
                  <div className={styles.item}>
                    <GoodsTop10Chart searchForm={searchForm} type={radioType} />
                  </div>
                  <div className={styles.item}>
                    <AnalysisChart
                      searchForm={searchForm}
                      id={"goodsChart"}
                      title={"商品销售帕累托分析"}
                      tips={"商品销售额及其累计占比TTL"}
                      type={radioType}
                    />
                  </div>
                </div>
              </div>
            )}
            {tabKey === "1" && (
              <div className={styles.wrap}>
                <div className={styles.item}>
                  <GategoryTop7Chart searchForm={searchForm} />
                </div>
                <div className={styles.item}>
                  <AnalysisChart
                    searchForm={searchForm}
                    id={"categoryChart"}
                    title={"品类销售帕累托分析"}
                    tips={"品类销售额及其累计占比TTL"}
                    type={"CATEGORY"}
                  />
                </div>
              </div>
            )}
            <SalesDetail
              goodsType={radioType}
              tabType={tabKey}
              searchForm={searchForm}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ProductSalesTracking;
