import React, { useContext, useEffect, useRef, useState } from 'react';
import type { GetRef, InputRef } from 'antd';
import { Button, Form, Input, Popconfirm, Select } from 'antd';

type FormInstance<T> = GetRef<typeof Form<T>>;

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

interface EditableRowProps {
  index: number;
}

export const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  levelOptions: any[];
  parentIdOptions: any[];
  checkInfo: any;
  handleSave: (record: Item) => void;
}

 export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  levelOptions,
  parentIdOptions,
  checkInfo,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;
  // const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
    
  }, [editing]);

  // const toggleEdit = () => {
  //   setEditing(!editing);
  //   form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  // };

  const save = async () => {
    try {
      const values = await form.validateFields();

      // toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  // if (editable) {
  //   if (title === '父级ID') {
  //     childNode = 
  //       <Form.Item
  //         style={{ margin: 0 }}
  //         name={dataIndex}
  //       >
  //         <Select allowClear placeholder='请选择' onSelect={save} options={parentIdOptions}/>
  //       </Form.Item>
      
  //   } else if (title === '类目层级')  {
  //     childNode = 
  //       <Form.Item
  //         style={{ margin: 0 }}
  //         name={dataIndex}
  //         rules={[
  //           {
  //             required: true,
  //             message: `${title}必填`,
  //           },
  //         ]}
  //       >
  //         <Select allowClear placeholder='请选择' onSelect={save} options={levelOptions}/>
  //       </Form.Item>
     
  //   } else if (title === '类目名称' || title === '产品线名称' || title === '品牌名称') {
  //     childNode = 
  //       <Form.Item
  //         style={{ margin: 0 }}
  //         name={dataIndex}
  //         rules={[
  //           {
  //             required: true,
  //             message: `${title}必填`,
  //           },
  //         ]}
  //       >
  //         <Input allowClear placeholder='类目名称' ref={inputRef} onPressEnter={save} onBlur={save} />
  //       </Form.Item>
  //   }
  // }
              
  if (editable) {
    childNode = <Form.Item
    style={{ margin: 0 }}
    name={dataIndex}
  >
    {(title === '类目名称' || title === '产品线名称' || title === '品牌名称') && <Input placeholder={title as string} allowClear ref={inputRef} onPressEnter={save} onBlur={save} />}
    {title === '类目层级' && <Select placeholder="请选择" allowClear onSelect={save} options={levelOptions}/>}
    {title === '父级ID' && <Select placeholder="请选择" allowClear onSelect={save} options={parentIdOptions}/>}
  </Form.Item>
  }

  return <td {...restProps}>{childNode}</td>;
};
