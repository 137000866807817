import React, { useState, useEffect, useRef } from 'react'
import styles from '../../../DimensionalModel/index.module.scss'
import ModuleTitle from "@/component/ModuleTitle"
import SearchIconInput from '@/component/Input/SearchIconInput/searchIconInput'
import { LeftOutlined } from '@ant-design/icons'
import { Table, Button, TableColumnsType, Form, Flex, Select, Input, Popconfirm, message } from "antd";
import { getDimensionDetailApi, getStratumApi, getParentIdApi, operateCategoryApi, operateProductLineApi, operateBrandApi, uniqueTableSearchApi } from '@/api/dimensionModel/dimensionModel'
import { getTableScroll, state } from '@/store/index'
import { EditableCell, EditableRow } from '../EditableContext/editable'
import ButtonPermissions from '@/component/Button/SystemButton'
import { DIMENSION_KEY } from '@/router/Permissions'

let addList = [] as any[]
let deletes = [] as any;
let currentIndex = 0 as number;
 let userOperate = false
type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  name: string;
  age: string;
  address: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

const TableCheck = (props: {
  checkInfo?: any,
  detailTableBack?: Function
}) => {
  const { checkInfo, detailTableBack } = props
  const [columns, setColumns] = useState([] as any);
  const [scrollY, setScrollY] = useState("")
  const [isShowEdit, setIsShowEdit] = useState(false);
  // 父级类目
  const [levelOptions, setLevelOptions] = useState([] as any)
  // 父级ID
  const [parentIdOptions, setParentIdOptions] = useState([] as any)
  // 表格list
  const [tableList, setTableList] = useState([] as any);
  // 属性分页
  const [pageQuery, setPageQuery] = useState({
    pageNum: 1,
    pageSize: 10,
    total: 0
  })
  let searchParam: any

  const [editing, setEditing] = useState(false)

  // 省市区街道表
  const columnsProvince: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: 'area_id',
      key: 'id',
      dataIndex: 'id',
      // width: '150px',
    },
    {
      title: '位置类型',
      key: 'level',
      dataIndex: 'level',
      width: '350px',
    },
    {
      title: '位置名称',
      key: 'name',
      dataIndex: 'name',
      width: '350px',
    },
    {
      title: '父级ID',
      key: 'pid',
      dataIndex: 'pid',
      width: '350px',
    }
  ]

  const isEditing = (record: any) => {
    // return editing && record.sort === 1
    return editing && record.isNew
  }
  // 商品类目表
  const columnsCategories = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: 'categories_id',
      key: 'categoryId',
      dataIndex: 'categoryId',
      width: '150px',
    },
    {
      title: '类目层级',
      key: 'stratum',
      width: '200px',
      dataIndex: 'stratum',
      editable: true,
      // render: (text, record, index) => 
      //   <Form.Item key={index}>
      //     <Input placeholder="请输入名称1" /> 
      //   </Form.Item>
    },
    {
      title: '类目名称',
      key: 'name',
      width: '300px',
      dataIndex: 'name',
      editable: true,

    },
    {
      title: '父级ID',
      key: 'parentId',
      width: '150px',
      dataIndex: 'parentId',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      key: 'operation',
      hidden: true,
      width: 80,
      render: (_: any, record: any, _index: any) =>
        <Popconfirm title="确定删除吗?" onConfirm={() => handleDelete(record)}>
          <Button type='link' size='small'>删除</Button>
        </Popconfirm>
    },
  ]

  // product_line
  const columnsProductLine: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '产品线名称',
      key: 'productLineName',
      dataIndex: 'productLineName',
      width: '150px',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      hidden: true,
      width: 80,
      render: (_, record) =>
        <Popconfirm title="确定删除吗?" onConfirm={() => handleDelete(record)}>
          <Button type='link' size='small'>删除</Button>
        </Popconfirm>
    },
  ]
  // brand
  const columnsBrand: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '品牌名称',
      key: 'brand',
      dataIndex: 'brand',
      width: '150px',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      hidden: true,
      width: 80,
      render: (_, record) =>
        <Popconfirm title="确定删除吗?" onConfirm={() => handleDelete(record)}>
          <Button type='link' size='small'>删除</Button>
        </Popconfirm>
    },
  ]
  // shop
  const columnsShop: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '店铺名称',
      key: 'shop_name',
      dataIndex: 'shop_name',
      width: '150px',
    },
  ]
  // Platform
  const columnsPlatform: TableColumnsType<any> = [
    {
      title: '序号',
      key: 'sort',
      dataIndex: 'sort',
      width: '88px',
    },
    {
      title: '平台名称',
      key: 'channel_name',
      dataIndex: 'channel_name',
      width: '150px',
    },
  ]

  const [searchContent, setSearchContent] = useState('')
  const [flag, setFlag] = useState(false)

  const searchInfo = (pages: any) => {
    switch (checkInfo.id) {
      case 4:
        // area表
        getDetailData({ pageNum: pages.pageNum, pageSize: pages.pageSize })
        break;
      case 7:
        // dim_category表
        uniqueTableSearch({ pageNum: pages.pageNum, pageSize: pages.pageSize })
        break;
      case 9:
        uniqueTableSearch({ pageNum: pages.pageNum, pageSize: pages.pageSize })
        break;
      case 8:
        getDetailData({ pageNum: pages.pageNum, pageSize: pages.pageSize })
        break;
      case 10:
        uniqueTableSearch({ pageNum: pages.pageNum, pageSize: pages.pageSize })
        setFlag(true)
        break;
      case 11:
        getDetailData({ pageNum: pages.pageNum, pageSize: pages.pageSize })
        break;
      default:
        break
    }
  }

  useEffect(() => {
    switch (checkInfo.id) {
      case 4:
        // area表
        setColumns(columnsProvince as any)
        setSearchContent('请输入位置名称')
        break;
      case 7:
        // dim_category表
        setColumns(columnsCategories as any)
        setSearchContent('请输入类目名称')
        setFlag(true)
        break;
      case 9:
        setColumns(columnsProductLine as any)
        setSearchContent('请输入产品线名称')
        setFlag(true)
        break;
      case 8:
        setColumns(columnsPlatform as any)
        setSearchContent('请输入平台名称')
        break;
      case 10:
        setColumns(columnsBrand as any)
        setSearchContent('请输入品牌名称 ')
        setFlag(true)
        break;
      case 11:
        setColumns(columnsShop as any)
        setSearchContent('请输入店铺名称')
        break;
      default:
        break
    }

    searchInfo({pageNum: 1, pageSize: 10})
    setIsShowEdit(false)
    deletes = []
  }, [])
  // 添加枚举值（操作表格）
  const addTableData = () => {
    let newData = {}
    currentIndex += 1
    if (checkInfo.id === 7) {
      newData = {
        key: `_${pageQuery.total+currentIndex}`,
        isNew: true,
        // sort: pageQuery.total+currentIndex,
        categoryId: null,
        stratum: '',
        name: '',
        parentId: ''
      }

    } else if (checkInfo.id === 9) {
      newData = {
        key: `_${pageQuery.total+currentIndex}`,
        isNew: true,
        // sort: pageQuery.total+currentIndex,
        productLineName: '',
      }
    } else if (checkInfo.id === 10) { //品牌
      newData = {
        key: `_${pageQuery.total+currentIndex}`,
        isNew: true,
        // sort: currentIndex,
        brand: '',
      }
    }
    
    const list = [...tableList]
    list.unshift(newData);
    setTableList([newData, ...tableList])
    addList = [...list]

    setPageQuery({
      // ...pageQuery,
      pageNum: pageQuery.pageNum,
      pageSize: pageQuery.pageSize+1,
      total: pageQuery.total
    })
    // setUserOperate(true)
    userOperate = true
  }

    // 删除(操作表格)
    const handleDelete = (data: any) => {
      // return;
      addList.forEach((it: any) => {
        if (!it.isNew) {
          // 表中原有数据
          if ((it.sort) === data.sort) {
            deletes.push(it)
          }
        } else {
          // 新增数据的删除不用push到deletes里
        }
      })
      addList = addList.filter((item: any) => item.key !== data.key);
      setTableList(addList);
      // setPageQuery({
      //   // ...pageQuery,
      //   pageNum: pageQuery.pageNum,
      //   pageSize: pageQuery.pageSize,
      //   total: pageQuery.total
      // })
      // setUserOperate(true)
      userOperate = true
    }

  const handleSave = (row: DataType) => {
    const newData = [...tableList];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    // console.log(newData, '====================newData');
    addList = [...newData]
    setTableList(newData);
  };
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns1 = columns.map((col: any) => {

    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: isEditing(record),
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
        levelOptions,
        parentIdOptions,
        checkInfo,

      }),
    };
  });
  // 新增/删除类目接口
  const operateCategory = async () => {
    let newList = tableList.filter((it: any) => it.isNew)
    const addParams = [] as any[];
    const deleteParams = [] as any[];
    newList.forEach((it: any) => {
      addParams.push({
        categoryName: it.name,
        parentId: it.parentId,
        stratum: it.stratum
      })
    })
    deletes.forEach((it: any) => {
      deleteParams.push(it.categoryId)
    })
    // console.log(newList, '=======================newList类目');
    const uniqueArr = Array.from(new Set(deleteParams))
    if (addParams.length > 0) {
      const index1 = addParams.findIndex((it: any) => !it.categoryName)
      const index2 = addParams.findIndex((it: any) => !it.stratum)
      if (index1 > -1 || index2 > -1) {
        message.error('请填写完整类目层级和类目名称')
        return
      }
    }
    const res = await operateCategoryApi({
      categoryBos: addParams,
      categoryIdList: uniqueArr
    })
    if (res) {
      message.success(res.msg)
      cancleEdit()
      newList = []
      deletes = []
      // getDetailData({ pageNum: 1, pageSize: 10 })
      // setIsShowEdit(false)
    }

  }
  // 新增/删除产品线表
  const operateProduceLine = async() => {
    let newList = tableList.filter((it: any) => it.isNew)
    // console.log(deletes, '==================deletes产品线');
    let addParams = [] as any[];
    let deleteParams = [] as any[];
    // console.log(newList, '=======================newList产品线');
    
    newList.forEach((it: any) => {
      addParams.push({
        productLineName: it.productLineName? it.productLineName : '',
        description: ''
      })
    })
    deletes.forEach((it: any) => {
      deleteParams.push(it.productLineId)
    })
    const uniqueArr = Array.from(new Set(deleteParams))
    if (addParams.length > 0) {
      const index = addParams.findIndex((it: any) => !it.productLineName)
      if (index > -1) {
        message.error('请填写完整产品线名称')
        return
      }
    }
    const res = await operateProductLineApi({
      addList: addParams,
      productLineIdList: uniqueArr
    })
    if (res) {
      message.success(res.msg)
      cancleEdit()
      newList = []
      deletes = []
      // getDetailData({ pageNum: 1, pageSize: 10 })
      // setIsShowEdit(false)
    }
  }

    // 新增/删除品牌表
    const operateBrandLine = async() => {
      // console.log(deletes, '==================deletes');
      // return 
      
      let newList = tableList.filter((it: any) => it.isNew)
      let addParams = [] as any[];
      let deleteParams = [] as any[];
      newList.forEach((it: any) => {
        addParams.push(it.brand)
      })
      deletes.forEach((it: any) => {
        deleteParams.push(it.brandId)
      })
      // console.log(addParams, '===================addParams');
      // console.log(newList, '=======================newList品牌');
      if (addParams.length > 0) {
        const index = addParams.findIndex((it: any) => it === '')
        if (index > -1) {
          message.error('请填写完整品牌名称')
          return
        }
      }
      const uniqueArr = Array.from(new Set(deleteParams))
      const res = await operateBrandApi({
        brandNameList: addParams,
        brandIdList: uniqueArr
      })
      if (res) {
        message.success(res.msg)
        cancleEdit()
        newList = []
        deletes = []
      }
    }

  // 编辑按钮
  const editTable = () => {
    setEditing(true)
    // setUserOperate(false)
    setIsShowEdit(true)
    switch (checkInfo.id) {
      case 7:
        // dim_category表
        columnsCategories[5].hidden = false
        setColumns(columnsCategories as any)
        break;
      case 9:
        columnsProductLine[2].hidden = false
        setColumns(columnsProductLine as any)
        break;
      case 10:
        columnsBrand[2].hidden = false
        setColumns(columnsBrand as any)
        break;
      default:
        break
    }
    if (!isShowEdit) {
      // 列表状态下点击编辑，调用接口查询下拉框内容
      if (checkInfo.id === 7) {
        getStratum();
        getParentId();
      }
      
    } 
    else {
      // 编辑状态下，点击确定调用接口
      let news = []
      news = tableList.filter((it: any) => it.isNew)
      
      if (!userOperate && (deletes.length===0 && news.length===0)) {
        cancleEdit()
        return
      }
      if (userOperate) {
        if (deletes.length===0 && news.length===0) {
          // message.error('请先进行编辑操作')
          // return;
          cancleEdit()
          return
        }
      }
      switch (checkInfo.id) {
        case 7:
          // dim_category表
          operateCategory()
          break;
        case 9:
          // 产品线表
          operateProduceLine()
          break;
        case 10:
          // 品牌表
          operateBrandLine()
          break;
        default:
          break
      }
    }
  }

// 取消编辑
const cancleEdit =  async() => {
  await searchInfo({pageNum: 1, pageSize: 10})
  setIsShowEdit(false);
  switch (checkInfo.id) {
    case 7:
      // dim_category表
      columnsCategories[5].hidden = true
      setColumns(columnsCategories as any)
      break;
    case 9:
      columnsProductLine[2].hidden = true
      setColumns(columnsProductLine as any)
      break;
    case 10:
      columnsBrand[2].hidden = true
      setColumns(columnsBrand as any)
      break;
    default:
      break
  }
}

  // 处理下拉列表数据
  const transformData = (list: any[]) => {
    const newList = list.map((value: number, _index: number) => {
      return { value: value, label: value };
    });
    return newList
  }
  // 新增下拉框类目查询
  const getStratum = async () => {
    const res = await getStratumApi();
    if (res.data) {
      setLevelOptions(transformData(res.data))
    }

  }
  // 新增下拉框父级ID查询
  const getParentId = async () => {
    const res = await getParentIdApi();
    if (res.data) {
      setParentIdOptions(transformData(res.data))
    }
  }

  // 列表查询
  const getDetailData = async (page: any) => {
    const params = {
      tableName: checkInfo.tableNameEn,
      ...page,
      param: searchParam,
      orderByColumn: '',
      isAsc: ''
    }
    const res = await getDimensionDetailApi({ ...params })
    if (res) {
      if (res.data.result.length > 0) {
        res.data.result.map((item: any, index: any) => {
          item.sort = (page.pageNum - 1) * page.pageSize + (index + 1);
          item.key = `_${(page.pageNum - 1) * page.pageSize + (index + 1)}`
        })
      }

      setTableList(res.data.result)
      addList = [...res.data.result]
      
      setPageQuery(
        {
          pageNum: page.pageNum,
          pageSize: page.pageSize,
          total: res.data.total
        }
      )
      setScrollY(getTableScroll())
    }
  }

  // 类目表/品牌表/产品线表查询
  const uniqueTableSearch = async(page: any) => {
    let url = '' as string
    // 类目
    const categoryParams = {
      categoryName: searchParam? searchParam : '',
      orderByColumn: '',
      isAsc: '',
      ...page,
    }
    // 产品线
    const productLineParams = {
      productLineName: searchParam? searchParam : '',
      orderByColumn: '',
      isAsc: '',
      ...page,
    }
    // 品牌
    const brandParams = {
      brandName: searchParam? searchParam : '',
      orderByColumn: '',
      isAsc: '',
      ...page,
    }
    let params = {}
    switch (checkInfo.id) {
      case 7:
        // dim_category表
        url = '/system/category/getCategoryPage'
        params = {...categoryParams}
        break;
      case 9:
        url = '/system/productLine/getPage'
        params = {...productLineParams}
        break;
      case 10:
        // 品牌
        url = '/system/brand/getPage'
        params = {...brandParams}
        break;
      default:
        break
    }
    console.log(params, '=========================params');
    console.log(page, '=========================page');
    
    const res = await uniqueTableSearchApi(url,  {
      ...params
    })
    if (res) {
      if (res.data.rows.length > 0) {
        res.data.rows.map((item: any, index: any) => {
          item.sort = (page.pageNum - 1) * page.pageSize + (index + 1);
          item.key = `_${(page.pageNum - 1) * page.pageSize + (index + 1)}`
        })
      }

      setTableList(res.data.rows)
      addList = [...res.data.rows]
      
      setPageQuery(
        {
          pageNum: page.pageNum,
          pageSize: page.pageSize,
          total: res.data.total
        }
      )
      setScrollY(getTableScroll())
    }
  }

  // 分页
  const handelTableChange = (page: any) => {
    setPageQuery({
      ...pageQuery,
      pageNum: page.current,
      pageSize: page.pageSize
    })
    searchInfo({ pageNum: page.current, pageSize: page.pageSize })
  }

  // 返回按钮
  const comeBack = () => {
    if (detailTableBack) {
      detailTableBack();
    }
  }
  const BackBtn = () => {
    return (
      <div className={styles['BackList_title']} onClick={comeBack}>
        <LeftOutlined />
        <span className={styles['BackList_text']}>返回</span>
      </div>
    )
  }
  return (
    <>
      {
        columns.length > 0 &&
        <div>
          <div className='headBox'>
            <div style={{ display: 'flex' }}>
              <BackBtn />
              <div>
                <ModuleTitle mark="large" title={`${checkInfo.tableNameEn}表`} />
                <div style={{ color: 'var(--text-font-color4)' }}>描述：{checkInfo.tableDescribe}</div>
              </div>
            </div>
            <div>
              <div className={styles.searchBox}>
                <SearchIconInput
                  placeholder={searchContent}
                  onSearch={(e: string) => { searchParam = e; searchInfo({ pageNum: 1, pageSize: 10 }); }}
                ></SearchIconInput>
                {(isShowEdit && flag) && <Button onClick={cancleEdit} style={{ marginLeft: '10px' }}>取消</Button>}
                {
                  // flag && <Button type="primary" onClick={editTable} style={{ marginLeft: '10px' }}>{isShowEdit ? '确定' : '编辑'}</Button>
                }
                {flag && <ButtonPermissions permissions={DIMENSION_KEY.TABLE_EDIT} type="primary" onClick={editTable} style={{ marginLeft: '10px' }} title={isShowEdit ? '确定' : '编辑'}></ButtonPermissions>}
              </div>
              {isShowEdit && <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button type='link' onClick={addTableData}>+ 添加枚举值</Button>
              </div>}
            </div>
          </div>
          <div className={styles.detailTableBox}>
            <Table
              columns={columns1 as ColumnTypes}
              scroll={{ x: 1400, y: scrollY }}
              rowClassName={() => 'editable-row'}
              components={components}
              dataSource={tableList}
              pagination={{
                current: pageQuery.pageNum,
                pageSize: pageQuery.pageSize,
                total: pageQuery.total,
                showTotal: (total) => `共${total}条`,
                showSizeChanger: true,
              }}
              onChange={(page, _filters, _sort) => handelTableChange(page)}
            />
          </div>
        </div>
      }
    </>
  )
}
export default TableCheck