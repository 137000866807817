import { useEffect, useState } from "react";
import style from "./index.module.scss";
import { Row, Col, Table, Button, Empty, message } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

import {
  TimeTitle,
  TableSearch2,
  TableSearch3,
  ExportTitle,
  TableNumber,
  changeTableList,
  RingChart2,
  LineBarChart2,
  TableSearch,
} from "../../../../component/DataBoardStyles";
import { TextWithQuestion } from "../../../../component/MiniStyles";
import { crmTipsMap } from "../../../../assets/public/pageMap";

import {
  getTradeOverview,
  getTradeTrend,
  getTradeAnalysis,
  getTradeDownload,
} from "@/crm/api/dataBoardApi";
import dayjs from "dayjs";
import handScrollTop from "@/crm/assets/public/handscrollTop";

// 全渠道会员交易数据总览
const CustomerPieSection = () => {
  const [chartList, setChartList] = useState([]);

  useEffect(() => {
    getChartList();
  }, []);

  // 获得数据列表
  const getChartList = async () => {
    const res = await getTradeOverview();
    if (res?.code === 200) {
      setChartList(res?.data || []);
    } else {
    }
  };

  // 环形图
  const PieSection = ({ info, index }: { info: any; index: number }) => {
    return (
      <Col span={8}>
        <div style={{ width: "100%" }}>
          <div style={{ marginTop: "24px" }}>
            <RingChart2
              options={info}
              chartId={`memberTransaction_chart_${index}`}
            />
          </div>
        </div>
      </Col>
    );
  };

  return (
    <div style={{ marginTop: "8px" }}>
      <TimeTitle
        title="全渠道会员交易数据总览"
        tips={crmTipsMap.get("全渠道会员交易数据总览")}
      />
      <Row>
        {chartList.length > 0 &&
          chartList.map((item: any, index: number) => (
            <PieSection info={item} index={index} key={item.type} />
          ))}
        {chartList.length === 0 && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{ margin: "64px auto" }}
          />
        )}
      </Row>
    </div>
  );
};

// 全渠道会员购买趋势
const CustomerLineSection = () => {
  const [chartList, setChartList] = useState([]);

  // 获得折线图列表
  const getChartList = async (e: any) => {
    const res = await getTradeTrend({ ...e });
    if (res?.code === 200) {
      const arr =
        res?.data?.map((item: any) => ({
          time: item.xbizDate,
          bar1: Number(item.memberAmt) || 0,
          barRate1: item.memberAmtTb || 0,
          rate1: Number(item.memberOrderCntPriceTb) || 0,
          rateNum1: Number(item.memberOrderCntPrice) || 0,
          rate2: Number(item.memberSkuCntPriceTb) || 0,
          rateNum2: Number(item.memberSkuCntPrice) || 0,
        })) || [];
      setChartList(arr);
    } else {
      setChartList([]);
    }
  };

  return (
    <div>
      <TimeTitle
        title="全渠道会员购买趋势"
        tips={crmTipsMap.get("全渠道会员购买趋势")}
        noTime
      />
      <TableSearch2
        onFinish={getChartList}
        type="member"
        background="transparent"
      />
      <div style={{ marginTop: "24px" }}>
        <LineBarChart2
          chartId="memberTransaction_chart-lineBar"
          options={chartList}
        />
      </div>
    </div>
  );
};

// 全渠道会员交易明细数据
const CustomerTableSection = () => {
  const [tableList, setTableList] = useState<any[]>([]);
  // 记录展开行
  const [rowList, setRowList] = useState<any[]>([]);
  // 记录搜索内容
  const [searchInfo, setSearchInfo] = useState<any>({});

  // 获得表格列表
  const getTableList = async (e: any) => {
    setSearchInfo(e);
    const res = await getTradeAnalysis({
      ...e,
      memberCardId: "0",
      platformType: e.platformType || "all",
      shopId: e.shopId || "0",
    });
    if (res?.code === 200) {
      const arr = changeTableList(res?.data, 2);
      setTableList(arr);
    } else {
      setTableList([]);
    }
  };

  // 导出
  const handDownload = async () => {
    const params = {
      query: {
        ...searchInfo,
        shopId: searchInfo.shopId || "0",
        platformType: searchInfo.platformType || "all",
        memberCardId: "0",
      },
      file: {
        taskName: `全渠道会员交易明细数据-会员交易分析-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`, //任务名称 + 日期时间
        taskLocation: "看板/基础看板/会员交易分析", //页面位置
        //文件上传信息
        // service: "eshop", //服务编码 （eshop 电商，zt 中台）
        name: "全渠道会员交易明细数据", //文件名
        originalFilename: `全渠道会员交易明细数据-会员交易分析-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}.csv`, //文件原名
        contentType: "text/csv", //文件类型
      },
    };
    const res = await getTradeDownload(params);
    if (res?.code === 200) {
      message.success(DOWNLOAD_SUCCESS_TEXT);
    } else {
      message.success("导出失败");
    }
  };

  // 展开折叠table
  const onRowOpen = (key: any) => {
    const index = tableList.findIndex((item: any) => item.id === key) + 1;
    const rowIndex = tableList[index].id;
    let roleArr: number[] = [];
    if (rowList.length > 0 && rowList.includes(rowIndex)) {
      roleArr = rowList.filter((item) => item !== rowIndex);
    } else {
      roleArr = [...rowList, rowIndex];
    }
    setRowList(roleArr);
  };

  // 表格标题
  const TableTitle = ({ children }: { children: any }) => {
    return (
      <TextWithQuestion
        black
        title={<span>{children}</span>}
        tips={crmTipsMap.get(`会员交易分析_${children}`)}
      />
    );
  };

  const tableCell = (_: any, index: number = 0) => {
    if (index % 2 === 0) {
      return { rowSpan: 2 };
    } else return { rowSpan: 0 };
  };

  return (
    <div style={{ marginTop: "24px" }}>
      <ExportTitle
        title="全渠道会员交易明细数据"
        tips={crmTipsMap.get("全渠道会员交易明细数据")}
        handExport={handDownload}
        permission="会员交易分析导出"
      />
      <TableSearch
        onFinish={getTableList}
        type="member"
        background="transparent"
        disabledNum={0}
      />
      <Table
        dataSource={tableList}
        pagination={false}
        style={{ marginTop: "24px" }}
        scroll={{ x: 1500, y: 500 }}
        rowKey="id"
        rowClassName={style["table-row"]}
        expandable={{
          expandedRowClassName: () => {
            return style["expanded-row-expended"];
          },
          expandedRowKeys: rowList,
          expandIcon: ({ expanded, onExpand, record }) => {
            const index =
              tableList.findIndex((item: any) => item?.id === record.id) + 1;

            return !tableList[index]?.children ? (
              ""
            ) : rowList.includes(tableList[index]?.id) ? (
              <Button
                icon={
                  <MinusOutlined style={{ fontSize: "var(--text-font5)" }} />
                }
                size="small"
                style={{ transform: "scale(0.8)" }}
                onClick={() => onRowOpen(record.id)}
              />
            ) : (
              <Button
                icon={
                  <PlusOutlined style={{ fontSize: "var(--text-font2)" }} />
                }
                size="small"
                style={{ transform: "scale(0.7)" }}
                onClick={() => onRowOpen(record.id)}
              />
            );
          },
        }}>
        <Table.Column title="" onCell={tableCell} width={50} fixed="left" />
        <Table.Column
          className={style["table-column-blue"]}
          title="时间"
          dataIndex="bizDate"
          onCell={tableCell}
          width={110}
          fixed="left"
        />
        <Table.Column
          className={style["table-column-green"]}
          title="全部"
          dataIndex="name"
          onCell={tableCell}
          width={140}
          fixed="left"
          render={(value) => <div className={style["table-cell"]}>{value}</div>}
        />
        <Table.Column title="客户类型" dataIndex="type" width={100} />
        <Table.Column
          title={<TableTitle>GMV</TableTitle>}
          dataIndex="amt"
          render={(value) => <TableNumber value={value} precision={2} />}
          width={110}
        />
        <Table.Column
          title={
            <TableTitle>
              退单金额1
              <br />
              （当日退款）
            </TableTitle>
          }
          dataIndex="refundAmtBiz"
          render={(value) => <TableNumber value={value} precision={2} />}
          width={140}
        />
        {searchInfo.dateType !== "day" && (
          <Table.Column
            title={
              <TableTitle>
                退单金额2
                <br />
                （当日成交退款）
              </TableTitle>
            }
            dataIndex="refundAmt"
            render={(value) => <TableNumber value={value} />}
            width={170}
          />
        )}
        <Table.Column
          title={<TableTitle>购买人数</TableTitle>}
          dataIndex="num"
          render={(value) => <TableNumber value={value} />}
          width={110}
        />
        <Table.Column
          title={<TableTitle>订单数</TableTitle>}
          dataIndex="orderCnt"
          render={(value) => <TableNumber value={value} />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>购买频次</TableTitle>}
          dataIndex="buyFrequency"
          render={(value) => <TableNumber value={value} precision={2} />}
          width={110}
        />
        <Table.Column
          title={<TableTitle>客单价</TableTitle>}
          dataIndex="numPrice"
          render={(value) => <TableNumber value={value} precision={2} />}
          width={100}
        />

        <Table.Column
          title={<TableTitle>笔单价</TableTitle>}
          dataIndex="orderCntPrice"
          render={(value) => <TableNumber value={value} precision={2} />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>笔件数</TableTitle>}
          dataIndex="skuOrderCnt"
          render={(value) => <TableNumber value={value} precision={2} />}
          width={100}
        />
        <Table.Column
          title={<TableTitle>件单价</TableTitle>}
          dataIndex="skuCntPrice"
          render={(value) => <TableNumber value={value} precision={2} />}
          width={100}
        />
      </Table>
    </div>
  );
};

const MemberTransactionAnalysis = () => {
  useEffect(() => {
    handScrollTop();
  }, []);

  return (
    <div className={style["MemberTransactionAnalysis-bgd"]}>
      {/* 全渠道会员交易数据总览 */}
      <CustomerPieSection />
      {/* 全渠道会员购买趋势 */}
      <CustomerLineSection />
      {/* 全渠道会员交易明细数据 */}
      <CustomerTableSection />
    </div>
  );
};

export default MemberTransactionAnalysis;
