import { Input, InputNumber, Form, Row, Col, Select, DatePicker, Space, Button, Checkbox, Cascader, TreeSelect, ConfigProvider } from "antd"
import React, { useState, useImperativeHandle, useEffect } from "react";
import dayjs from "dayjs";
import { FilterOutlined, DownOutlined, CopyOutlined } from "@ant-design/icons";

import { getTableScroll } from '@/store/index'

import style from './filterView.module.scss'

import { motion } from 'framer-motion';

const { RangePicker } = DatePicker;
const { Option } = Select;
const { SHOW_CHILD } = Cascader;

let FilterView = React.forwardRef((props: {
    searchList?: searchItem[],
    finish?: Function,
    handleChange?: Function,
    getHeight?: Function,
    averageTwo?: boolean,
    doubelDate?: boolean,
    dateRangeTime?: boolean,
}, ref: any) => {
    const [arrow, setArrow] = useState(false)
    const [form] = Form.useForm();
    const [forceUpdate, setForceUpdate] = useState(false);// 更新状态以触发重新渲染
    const [initValue, setInitValue] = useState({} as any)
    // const [changeHeight, setChangeHeight] = useState('')
    const showFilterView = () => {
        if (arrow) {
            form.resetFields();
        } else {
            let a = {} as any
            props.searchList?.map((item) => {
              console.log(item)
                // form.setFieldValue(item.key, item.defaultValue)
                if (item.key?.indexOf(',') != -1) {
                    form.setFieldValue(item.key?.split(',')[0], item.defaultValue?.split(',')[0])
                    form.setFieldValue(item.key?.split(',')[1], item.defaultValue?.split(',')[1])
                    // if (item.key?.split(',')[0]) {
                    a[String(item.key?.split(',')[0])] = ''
                    a[String(item.key?.split(',')[1])] = ''
                    // }
                } else {
                    form.setFieldValue(item.key, item.defaultValue)
                    if (item.searchComponentType && item.searchComponentType.indexOf('input') > -1) {
                        a[item.key] = ''
                    } else {
                        a[item.key] = undefined
                    }
                }
                console.log(a);

            })
            setInitValue(a)
        }

        setArrow(!arrow)
    }


    //重置搜索数据
    const resetData = () => {
        console.log("11111111111111111111");



        form.resetFields();
    }
    //清除input框前面下拉框选择
    const resetOneData = () => {
        form.setFieldValue('mobile', '')
        form.setFieldValue('platformId', '')
        form.setFieldValue('orderId', '')
        form.setFieldValue('oneId', '')
        setForceUpdate(!forceUpdate);
    }
    useImperativeHandle(ref, () => ({
        showFilterView,
        resetData,
        resetOneData,
        // changeHeight

    }))
    // 获取按钮组宽度
    const getButtonBoxWidth = () => {
        if (props.searchList && props.searchList.length % 3 === 1) {
            return 16;
        } else if (props.searchList && props.searchList.length % 3 === 2) {
            return 8;
        } else if (props.searchList && props.searchList.length % 3 === 0) {
            return 24;
        }
    };

    const onFinish = (values: any) => {
        let data = {} as any
        Object.keys(values).forEach(key => {
            if (key.indexOf('-') !== -1 && values[key]) {
                const list = key.split('-');
                const a = values[key] as any[]
                console.log(a);
                a.forEach((item, index) => {
                    if (index === 0) {
                        data[list[index]] = props.dateRangeTime ? `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} ${item.$H}:${item.$m}:${item.$s}` :
                            `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 00:00:00`
                    } else {
                        data[list[index]] = props.dateRangeTime ? `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} ${item.$H}:${item.$m}:${item.$s}` :
                            `${item.$y}-${item.$M + 1 > 9 ? item.$M + 1 : '0' + (item.$M + 1)}-${item.$D > 9 ? item.$D : '0' + item.$D} 23:59:59`
                    }

                })
            } else {
                data[key] = values[key]
            }
        })
        if (props.finish) {
            props.finish(data)
        }
    }
    // 日期筛选截至到当前日
    const disabledDate = (date: any) => {
        return date.valueOf() >= dayjs().valueOf();
    };

    const computeHeight = () => {
        // setChangeHeight(getTableScroll())
        if (props.getHeight) {
            props.getHeight(getTableScroll())
        }
    }

    return <div className={style['container']} style={{ marginBottom: arrow ? '24px' : '0' }}>
        <motion.div onAnimationComplete={computeHeight} className={style['search-list']} style={{ height: 0, padding: '0 16px', overflow: 'hidden', }} animate={{ height: arrow ? 'auto' : 0, }}>
            <ConfigProvider
                          theme={{
                            components: {
                              Form: {
                                /* 这里是你的组件 token */
                                itemMarginBottom: 16
                              },
                            },
                          }}  >  
            <Form form={form} name="advanced_search" style={{ width: '100%', marginTop: 16 }} onFinish={onFinish} initialValues={initValue}  >
                <Row gutter={props.averageTwo ? 24 : 32} >
                    {props.searchList?.map((item: any, index: any) => (
                        // averageTwo 一行两个 doubelDate 两个时间 
                        <Col span={props.averageTwo ? props.doubelDate ? index == 0 ? 9 : index == 1 ? 3 : 12 : 12 : 8} key={index}>
                         
                            <Form.Item
                                name={item.key}
                                valuePropName={item.searchComponentType === 'checkBox' ? 'checked' : 'value'}
                                label={item.title}
                                className={item.searchComponentType === 'checkBox' ? style['specailInput'] : ''}
                            >
                                {/* addonAfter 后面的字 addonBefore 前方下拉选择条件 */}
                                {item.searchComponentType === 'input' &&
                                    <Input autoComplete="off"
                                        // defaultValue={item.defaultValue}
                                        placeholder={item.placeHolder}
                                        allowClear addonAfter={item.addonAfter}
                                        addonBefore={item.selectBefore}
                                        onChange={((e: any) => {

                                        })}
                                    ></Input>}
                                {/* 有两个input框需要的 */}
                                {item.searchComponentType === 'inputDouble' &&
                                    <div style={{ display: 'flex', height: '32px', }}>
                                        <Form.Item
                                            name={item.key?.split(',')[0]}
                                        >
                                            <InputNumber
                                                style={{ width: '95%', marginRight: '10px' }}
                                                min="0"
                                                // defaultValue={item.defaultValue?.split(',')[0]}
                                                max={item.max}
                                                maxLength={item.maxLength}
                                                placeholder={item.placeHolder}
                                                formatter={(value: any) => {//指定输入框展示值的格式
                                                    var reg = value
                                                    if (item.limitNum == 2) {
                                                        if (reg > 99999999.99) {
                                                            reg = 99999999.99
                                                        } else {
                                                            reg = `${value}`.replace(/^(\.*)(\d+)(\.?)(\d{0,2}).*$/g, '$2$3$4');
                                                        }
                                                    } else if (item.limitNum == 0) {
                                                        reg = `${value}`.replace(/^(\d+).*$/, '$1')
                                                    }
                                                    return reg;
                                                }}
                                            ></InputNumber >
                                        </Form.Item>
                                        <span className={style['inputDouble-text']} >-</span>
                                        <Form.Item
                                            name={item.key?.split(',')[1]}
                                        >
                                            <InputNumber
                                                style={{ width: '90%', marginRight: '10px', marginLeft: '10px' }}
                                                maxLength={item.maxLength}
                                                // defaultValue={item.defaultValue?.split(',')[1]}
                                                min="0"
                                                max={item.max}
                                                placeholder={item.placeHolder}
                                                formatter={(value: any) => {//指定输入框展示值的格式
                                                    var reg = value
                                                    if (item.limitNum == 2) {
                                                        if (reg > 99999999.99) {
                                                            reg = 99999999.99
                                                        } else {
                                                            reg = `${value}`.replace(/^(\.*)(\d+)(\.?)(\d{0,2}).*$/g, '$2$3$4');
                                                        }
                                                    } else if (item.limitNum == 0) {
                                                        reg = `${value}`.replace(/^(\d+).*$/, '$1')
                                                    }
                                                    return reg;
                                                }}
                                            ></InputNumber >
                                        </Form.Item>
                                        <span className={style['inputDouble-text']} > {item.addonAfter}</span>
                                    </div>
                                }
                                {item.searchComponentType === 'select' &&
                                    <Select
                                        // defaultValue={item.defaultValue}
                                        placeholder={item.placeHolder}
                                        mode={item.mode}
                                        options={item.options}
                                        fieldNames={item.fieldNames}
                                        optionFilterProp="label"
                                        allowClear ></Select>}
                                {/* selectVip会触发change事件 用户列表 所属会员卡与会员等级联动*/}
                                {item.searchComponentType === 'selectVip' &&
                                    <Select placeholder={item.placeHolder}  mode={item.mode} options={item.options} fieldNames={item.fieldNames} allowClear
                                        onChange={(data) => {
                                            if (props.handleChange) {
                                                props.handleChange(data)
                                                //将改变的字段清空
                                                form.setFieldValue(item.changeField, null)
                                            }
                                        }}
                                    ></Select>}
                                {/* 下拉框前面带icon */}
                                {item.searchComponentType === 'selectIcon' &&
                                    <Select placeholder={item.placeHolder} mode={item.mode}
                                        // defaultValue={item.defaultValue}
                                        // options={item.options} 
                                        // fieldNames={item.fieldNames} 
                                        optionLabelProp='label'
                                        allowClear >
                                        {item.options.map((option: any) => (
                                            <Option key={option.value} value={option.value} label={option.label}>
                                                <div style={{ display: "flex" }}>
                                                    <div className={style['inputDouble-selectIcon']}>{option.avatar}</div>
                                                    {/* <img src={option.avatar} alt="" className={style['inputDouble-selectIcon']}/> */}
                                                    <div>{option.label}</div>
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>}
                                {/* 级联多选框 */}
                                {item.searchComponentType === 'cascader' &&
                                    <Cascader
                                        style={{ width: '100%' }}
                                        options={item.options}
                                        placeholder={item.placeHolder}
                                        fieldNames={item.fieldNames}
                                        // maxTagCount="responsive"
                                        // showCheckedStrategy={SHOW_CHILD}
                                        changeOnSelect
                                        multiple
                                    />
                                }
                                {/* 树选择 */}
                                {item.searchComponentType === 'treeSelect' &&
                                    <TreeSelect
                                        showSearch
                                        multiple={item.mode}
                                        style={{ width: '100%' }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={item.placeHolder}
                                        allowClear
                                        treeData={item.options}
                                        fieldNames={item.fieldNames}
                                        treeNodeFilterProp="title"
                                    />
                                }
                                {item.searchComponentType === 'dateRange' &&
                                    <RangePicker style={{ width: '100%' }}
                                        disabledDate={disabledDate}
                                        showTime={item.showTime}
                                        format={item.format}
                                        allowClear />}
                                {item.searchComponentType === 'dateRangeDoubel' && <>
                                    <RangePicker
                                        style={{ width: '100%' }}
                                        disabledDate={disabledDate} allowClear
                                        disabled={form.getFieldValue('nolitTime')}
                                        onChange={(e: any) => {
                                            form.setFieldValue('startTime-endTime', e)
                                            setForceUpdate(!forceUpdate);
                                        }}
                                    />
                                </>}
                                {item.searchComponentType === 'checkBox' && <>
                                <ConfigProvider
                                  theme={{
                                    token: {
                                      /* 这里是你的全局 token */
                                      borderRadiusSM: 8
                                    },
                                  }}
                                >
                                    <Checkbox
                                        className={style['search-checkBox']}
                                        style={{ marginLeft: '8px' }}
                                        disabled={form.getFieldValue('startTime-endTime')}
                                        onChange={(e: any) => {
                                            if (e.target.checked) {
                                                form.setFieldValue('startTime-endTime', null)
                                            }
                                            setForceUpdate(!forceUpdate);
                                        }}
                                    >不限时间</Checkbox>
                                    </ConfigProvider>
                                </>}
                            </Form.Item>
                            
                        </Col>
                    ))}
                    <Col span={props.averageTwo ? 12 : getButtonBoxWidth()}>
                        <div style={{ textAlign: 'right', marginBottom: 16 }}>
                            <Space size="small">
                                <Button
                                    onClick={() => {
                                        form.resetFields();
                                        // form.setFieldValue('maxTradeAmount', '')
                                        // console.log(form.getFieldValue('maxTradeAmount'));
                                        // console.log(form.getFieldsValue());
                                        setForceUpdate(!forceUpdate);
                                    }}>
                                    重置
                                </Button>
                                <Button type="primary" htmlType="submit" >
                                    查询
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Form>
        </ConfigProvider>

        </motion.div>
    </div>

})

export default FilterView