/**
 * 老客 by recency回购
 */
import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Button, message } from "antd";
import dayjs from "dayjs";
import { downLoadOldCustomer } from "@/crm/api/industryBoardApi";
import OldCustomByRecencyPercent from "./component/OldCustomByRecencyPercent"; // 回购比例
import OldCustomByRecencySituation from "./component/OldCustomByRecencySituation"; // 回购情况
import OldCustomByRecencyPercentTTL from "./component/OldCustomByRecencyPercentTTL"; // 回购占比 TTL
import { checkPermissions } from "@/utils/utils";
import { INDUSTRYCROWD_KEY } from "@/router/Permissions";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function OldCustomByRecency(props: any) {
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const { searchForm } = props;
  const download = async () => {

      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
    const params = {
      moduleName: `行业看板-人群细分-老客by recency回购-${dayjs().format(
        "YYYYMMDDHHmmss"
      )}`,
      taskLocation: "行业看板/人群细分/老客by recency回购",
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.includes("all")
        ? channelAllList
            .filter((item) => item.value !== "all")
            .map((item) => item.value)
        : searchForm?.platformType?.split(","),
      shopIds: finalShopId,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
    };
    const res = await downLoadOldCustomer(params);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    } else {
      message.success("导出失败");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>老客 by recency回购</div>
        {checkPermissions(INDUSTRYCROWD_KEY.BYRECENCY_EXPORT) && (
          <Button onClick={download}>导出</Button>
        )}
      </div>
      <div>
        <OldCustomByRecencyPercent searchForm={searchForm} />
      </div>
      <div className={styles.wrap}>
        <div className={styles.item}>
          <OldCustomByRecencySituation searchForm={searchForm} />
        </div>
        <div className={styles.item}>
          <OldCustomByRecencyPercentTTL searchForm={searchForm} />
        </div>
      </div>
    </div>
  );
}

export default OldCustomByRecency;
