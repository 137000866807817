import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Form,
  Input,
  Modal,
  Dropdown,
  Space,
  Select,
  Tooltip,
  message,
} from "antd";
import {
  QuestionCircleOutlined,
  SettingOutlined,
  CrownOutlined,
  DeploymentUnitOutlined,
  TeamOutlined,
  IdcardOutlined
} from "@ant-design/icons";
import DY from '../../../../assets/images/DY.png';
import JD from '../../../../assets/images/JD.png';
import TM from '../../../../assets/images/TM.png';
import MD from '../../../../assets/images/offlineShop.png';
import YZ from '../../../../assets/images/YZ.png';
import WX from '../../../../assets/images/WX.png';
import jdBule from '../../../../assets/images/jdBule.png';
import tmBlue from '../../../../assets/images/tmall.png';
import dyBlue from '../../../../assets/images/douyin.png';
import mdBlue from '../../../../assets/images/offlineShopBlue.png';
import yzBlue from '../../../../assets/images/youzan.png';
import wxBlue from '../../../../assets/images/WXblue.png';
import { useNavigate } from 'react-router-dom';
import { getQueryParam } from '../../../../utils/tools';
import ChangeScore from "./components/ChangeScore";
import AddStaff from "./components/AddStaff";
import EditField from "./components/EditField";
import ExportRefirm from "./components/ExportRefirm";
import type { TableProps } from "antd";
import { FilterOutlined, DownOutlined, CopyOutlined } from "@ant-design/icons";
import ModuleTitle from "../../../../component/ModuleTitle";
import FilterView from "../../../../component/FilterView/filterView";
import ButtonPermissions from '../../../../component/Button/SystemButton';
import { USER_KEY } from '../../../../router/Permissions';
import { checkPermissions } from '@/utils/utils';
import { getTableScroll } from '@/store/index'
import {
  getUserInsightListApi,
  getUserQueryPageApi,
  getIdentityApi,
  getMemberCardListApi,
  getMemberLevelList,
  getMemberLevelNewList,
  getChannelListApi,
  exportData,
  getCustomerListTitle,
  getShopList,
} from "../../../../api/UserInsight/UserInsight";
import copyToClipboard from "../../../../crm/assets/public/clipboard";
import { UserListQuery, UserListVO, RecordQuery } from "../../../../api/UserInsight/types";
import styles from "./index.module.scss";
import { isNamedTupleMember } from "typescript";
import { state } from "@/store";
import { Value } from "sass";
// import { Router } from "react-router-dom";
const { Option } = Select;

let operatePageNum: any
let operatepageSize: any
let preParams: any

//手机号还是oneid查询字段
let phoneorIdData = "mobile" as any;
let channeldIconData: any = [];


const UserInsightUserSerch = () => {
  const navigate = useNavigate();
  const filterViewRef: any = useRef(null)
  // 防连点
  const [responseFinished, setResponseFinished] = useState(false);
  //渠道数据
  const [channeldData, setChanneldData] = useState([] as any);
  //店铺数据
  // const [shoplistData, setShoplistData] = useState([] as any);
  //会员卡数据
  const [memberCardData, setMemberCardData] = useState([] as any);
  //会员等级数据
  const [gradeProfileData, setGradeProfileData] = useState([] as any);
  // 客户身份列表
  const [identityList, setIdentityList] = useState([] as any);

  //数据列表
  const [listData, setListData] = useState([] as any);
  //数据列表
  const [phoneorId, setPhoneorId] = useState('mobile' as any);
  const [scrollY, setScrollY] = useState("")
  //手机号还是oneid查询字段
  const changePhoneorId = (data: any) => {
    setPhoneorId(data)
    phoneorIdData = data
    filterViewRef.current?.resetOneData()
  };
  const selectBefore = (
    <Select defaultValue={phoneorId} onChange={changePhoneorId}>
      <Option value="mobile">手机号</Option>
      <Option value="platformId">平台ID</Option>
      <Option value="orderId">订单号</Option>
      <Option value="oneId">OneID</Option>
    </Select>
  );
  //搜索条件
  const searchList: searchItem[] = [
    {
      searchType: "text",
      searchComponentType: "select",
      placeHolder: "请选择所属渠道",
      title: "所属渠道",
      defaultValue: preParams?.channelType ? String(preParams.channelType) : null,
      key: "channelType",
      options: channeldData,
      fieldNames: {
        label: 'channelName',
        value: 'channelType'
      },
    },
    {
      searchType: "text",
      searchComponentType: "input",
      placeHolder: "请输入",
      defaultValue: preParams?.[phoneorIdData] ? preParams?.[phoneorIdData] : null,
      title: "",
      key: phoneorId,
      selectBefore: selectBefore,
    },
    {
      searchType: "text",
      searchComponentType: "selectIcon",
      placeHolder: "请选择客户身份",
      title: "客户身份",
      defaultValue: preParams?.identity ? preParams.identity : null,
      key: "identity",
      // mode:'multiple',
      options: identityList,
    },
    // {
    //   searchType: "text",
    //   searchComponentType: "selectVip",
    //   placeHolder: "请选择所属会员卡",
    //   title: "所属会员卡",
    //   key: "memberCardId",
    //   // defaultValue:'',
    //   changeField:'gradeId',
    //   options: memberCardData,
    //   fieldNames: {
    //     label: 'memberCardName',
    //     value: 'memberCardId'
    //   }
    // },

    // {
    //   searchType: "text",
    //   searchComponentType: "select",
    //   placeHolder: "请选择会员等级",
    //   title: "会员等级",
    //   key: "gradeId",
    //   options: gradeProfileData
    // },
    {
      searchType: "text",
      searchComponentType: "inputDouble",
      addonAfter: '元',
      placeHolder: "请输入",
      title: "累计消费金额",
      defaultValue: `${preParams?.minTradeAmount ? preParams.minTradeAmount : ''},${preParams?.maxTradeAmount ? preParams.maxTradeAmount : ''}`,
      limitNum: 2,
      key: "minTradeAmount,maxTradeAmount",
    },
    {
      searchType: "text",
      searchComponentType: "inputDouble",
      addonAfter: '次',
      placeHolder: "请输入",
      title: "累计下单次数",
      defaultValue: `${preParams?.minTradeCount ? preParams.minTradeCount : ''},${preParams?.maxTradeCount ? preParams.maxTradeCount : ''}`,
      maxLength: 3,
      limitNum: 0,
      max: 999,
      key: "minTradeCount,maxTradeCount",
    },
  ];

  //搜索字段
  const [searcParam, setSearcParam] = useState({});
  // let searcParam: any = undefined

  // 查询
  const filterFinish = (data: any) => {
    delete data["minTradeAmount,maxTradeAmount"];
    delete data["minTradeCount,maxTradeCount"];
    console.log(data, 'data');
    // searcParam = data
    setSearcParam(data)
    getUserDetailList({ pageSize: 10, pageNum: 1 }, data);
  };
  // 查询会员等级
  const handleChangeFilter = (data: any) => {
    if (data) {
      getGradeProfileList(data)
    } else {
      setGradeProfileData([])
    }
  };
  //过滤器
  const [iconColor, setIconColor] = useState('var(--text-font-color4)');
  const handleMouseEnter = () => {
    // console.log('鼠标移入')
    setIconColor('var(--text-font-color9)');
  };
  const handleMouseLeave = () => {
    // console.log('鼠标移出')
    setIconColor('var(--text-font-color4)');
  };
  //抽屉  true  开启
  const [openScoreChange, setOpenScoreChange] = useState(false);
  //抽屉  true  开启添加员工
  const [openAddStaff, setOpenAddStaff] = useState(false);
  //抽屉  true  导出确认框
  const [openExportRefirm, setOpenExportRefirm] = useState(false);
  //抽屉  true  开启添加员工
  const [openEditField, setOpenEditField] = useState(false);
  const [popType, setPopType] = useState();
  // 打开变更抽屉 1 积分  2 等级 3 成长值
  const changeScoreItem = (type: any) => {
    setPopType(type)
    setOpenScoreChange(true);
  };
  // 关闭变更抽屉
  const closeScoreChange = (v: boolean) => {
    setOpenScoreChange(v);
  };
  // 打开添加员工抽屉 
  const toOpenAddStaff = () => {
    setOpenAddStaff(true);
  };
  // 打开导出二次确认框
  const toOpenExportRefirm = () => {
    setOpenExportRefirm(true);
  };
  // 关闭导出二次确认框
  const closeExportRefirm = (v: boolean) => {
    setOpenExportRefirm(v);
  };
  // 关闭添加员工抽屉
  const closeOpenAddStaff = (v: boolean) => {
    setOpenAddStaff(v);
  };
  // 打开添加员工抽屉 
  const toOpenEditField = () => {
    setOpenEditField(true);
  };
  // 关闭添加员工抽屉
  const closeOpenEditField = (v: boolean) => {
    setOpenEditField(v);
  };
  // 带有粘贴功能的文本
  const handCopy = (text: any) => {
    copyToClipboard(text)
      .then(() => {
        message.success("复制成功");
      })
      .catch((error) => {
        message.error("复制失败");
      });
  };
  //去到客户详情页面
  const gotoDetail = (data?: any) => {
    if (checkPermissions(USER_KEY.USER_DETAIL)) {
      //点击去详情页 将筛选项存到缓存
      localStorage.setItem('pageNum', String(operatePageNum))
      localStorage.setItem('pageSize', String(operatepageSize))
      localStorage.setItem('phoneorId', phoneorIdData)
      var da: any = preParams ? JSON.stringify(preParams) : null
      localStorage.setItem('searchListData', da)
      navigate('/userInsight/userInsightDocument/UserDetail')
      localStorage.setItem('oneId', data)
    }
  }
  // 渠道图标map
  const channelIconMapList = [
    {
      level: 'JD',
      name: '京东',
      img: JD,
      isLight: false,
      imgBlue: jdBule,
    },
    {
      level: 'TM',
      name: '天猫',
      img: TM,
      isLight: false,
      imgBlue: tmBlue,
    },
    {
      level: 'DY',
      name: '抖音',
      img: DY,
      isLight: false,
      imgBlue: dyBlue,
    },
    {
      level: 'MD',
      name: '线下门店',
      img: MD,
      isLight: false,
      imgBlue: mdBlue,
    },
    {
      level: 'YZ',
      name: '有赞',
      img: YZ,
      isLight: false,
      imgBlue: yzBlue,
    },
    {
      level: 'WX',
      name: '微信',
      img: WX,
      isLight: false,
      imgBlue: wxBlue,
    },
  ]

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });
  var shopData = [] as any;// 店铺信息
  var gradeData = [] as any;// 会员等级信息
  //会员等级处理
  const renderGradeData = (record: any) => {
    record.customerMemberInfo?.map((item: any) => {
      item.numCard = item.memberCardId + item.gradeId
      gradeData.map((itm: any) => {
        if (itm.numCard == item.numCard) {
          item.gradeName = itm.gradeName
        }
      })
    })
    return <>
      {
        record.customerMemberInfo?.map((it: any, index: number) =>
          <div key={index}>
            {it.gradeImgUrl && <Tooltip placement="top" title={it.gradeName} >
              <img src={it.gradeImgUrl} className={styles.UserDeatil_mesImg} alt="" />
            </Tooltip>}
            {!it.gradeImgUrl && <div style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', width: '60px' }}>{it.gradeName ? it.gradeName : '非会员'}</div>}
          </div>
        )
      }
    </>
  };
  //所属渠道处理
  const renderChannelType = (value: any) => {
    let newArray: any = [];
    let transformedInfo = Object.keys(value?.channelInfo).map(key => (
      { name: key, arr: value?.channelInfo[key] }
    ));
    channeldIconData.forEach((item: any) => {
      item.isLight = false
      item.unbindShopName = []
      item.bingingShopName = []
      if (transformedInfo.length > 0) {
        transformedInfo.map((itm: any) => {
          if (itm.name === item.level) {
            item.isLight = true
            shopData.map((i: any) => {
              itm.arr.forEach((im: any) => {
                if (i.shopId == im.shopId) {
                  item.bingingShopName.push(i.shopName)
                }
                // else{
                //   item.unbindShopName.push(i.shopName)
                // }
              })
            })
          }
        })
      } else {
        item.isLight = false
        item.unbindShopName = []
        item.bingingShopName = []
      }

    });

    return channeldIconData.map((item: any, index: any) => {
      return (
        <Tooltip placement="top" key={index} title={
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ width: (item.bingingShopName?.length > 0 || item.unbindShopName?.length > 0) ? '60px' : '' }}>{item.name}</div>
              <div style={{ flex: '4' }}>
                {item.bingingShopName && <div>
                  {item.bingingShopName.map((itm: any, indx: any) =>
                    <div key={indx}>{itm}</div>
                  )}
                </div>}
                {item.unbindShopName && <div>
                  {item.unbindShopName.map((itm: any, indx: any) =>
                    <div style={{ color: 'var(--background1)', opacity: '0.4' }} key={indx}>{itm}</div>
                  )}
                </div>}
              </div>
            </div>
          </>
        } >
          <img src={item.isLight ? item.imgBlue : item.img} alt="" style={{ opacity: item.isLight ? '' : '0.5', width: '20px', height: '20px', marginRight: '4px' }} />
        </Tooltip>
      )
    });
  };
  //选中的编辑字段 表头
  const [checkFieldList, setCheckFieldList] = useState(['oneId', 'mobile', 'memberCardName', 'channelType'] as any);
  const [columnsData, setColumnsData] = useState([
    {
      title: "客户身份",
      dataIndex: "identity",
      key: "identity",
      sort: 6,
      width: '200px',
      render: (text: string, record: any) =>
        <div style={{ display: 'flex' }}>
          <Tooltip placement="top" title={'存量会员'}>
            <CrownOutlined style={{ color: text == 'STOCK' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
          <Tooltip placement="top" title={'渠道会员'}>
            <DeploymentUnitOutlined style={{ color: text == 'CHANNEL' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
          <Tooltip placement="top" title={'普通客户'}>
            <TeamOutlined style={{ color: text == 'COMMON' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
          <Tooltip placement="top" title={'员工'}>
            <IdcardOutlined style={{ color: text == 'STAFF' ? 'var(--text-font-color7)' : 'var(--text-font-color15)', marginRight: '8px', fontSize: 'var(--text-font7)' }} />
          </Tooltip>
        </div>
      // ellipsis: true,
    },
    {
      title: "会员等级",
      dataIndex: "gradeId",
      key: "gradeId",
      sort: 7,
      width: '140px',
      render: (text: string, record: any) => renderGradeData(record)
      // <>
      //   {
      //     record.customerMemberInfo?.map((it: any, index: number) =>
      //       <div key={index}>
      //         {it.gradeImgUrl && <Tooltip placement="top" title={it.gradeName} >
      //           <img src={it.gradeImgUrl} className={styles.UserDeatil_mesImg}  alt="" />
      //         </Tooltip>}
      //         {!it.gradeImgUrl &&<div style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', cursor: 'pointer', width: '60px' }}>{it.gradeName ? it.gradeName : '非会员'}</div>}
      //       </div>
      //     )
      //   }
      // </>
    },
    {
      title: "当前成长值",
      dataIndex: "growthValue",
      key: "growthValue",
      sort: 8,
      width: 140,
      render: (text: string, record: any) =>
        <>
          {
            record.customerMemberInfo?.map((it: any, index: number) =>
              <Tooltip placement="topLeft" title={it.growthValue} key={index}>
                <div style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', cursor: 'pointer' }}>{it.growthValue}</div>
              </Tooltip>
            )
          }
        </>
    },
    {
      title: "当前积分",
      dataIndex: "totalPoint",
      key: "totalPoint",
      sort: 9,
      width: '140px',
      render: (text: string, record: any) =>
        <>
          {
            record.customerMemberInfo?.map((it: any, index: number) =>
              <Tooltip placement="topLeft" title={it.totalPoint} key={index}>
                <div style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', cursor: 'pointer' }}>{it.totalPoint}</div>
              </Tooltip>
            )
          }
        </>
    },
    {
      title: "累计消费金额",
      dataIndex: "tradeAmount",
      key: "tradeAmount",
      sort: 10,
      width: '140px',
    },
    {
      title: <>
        <span style={{ marginRight: '5px' }}>累计下单次数</span>
        <Tooltip placement="top" title="仅统计确认收货的订单">
          <QuestionCircleOutlined style={{ color: 'var(--text-font-color3)', opacity: '0.45' }} />
        </Tooltip>
      </>,
      dataIndex: "tradeCount",
      key: "tradeCount",
      sort: 11,
      width: '150px',
    },

  ]);
  const [tabelFinshData, setTabelFinshData] = useState([
    {
      title: "序号",
      dataIndex: "sort",
      fixed: "left",
      key: "sort",
      width: '88px',
      sort: 1,
    },
    {
      title: "OneID",
      dataIndex: "oneId",
      key: "oneId",
      fixed: "left",
      width: '350px',
      sort: 2,
      render: (data: any) => (
        <div style={{ whiteSpace: 'nowrap' }}>
          <Space className={styles.oneidCopyOutlined} style={{ color: checkPermissions(USER_KEY.USER_DETAIL) ? '' : 'var(--text-font-color3)' }} onClick={() => gotoDetail(data)}>
            {data}
          </Space>
          <CopyOutlined
            className={styles.UserCopyOutlined}
            onClick={() => handCopy(data)}
          />
        </div>
      ),
    },
    {
      title: "手机号",
      dataIndex: "mobile",
      key: "mobile",
      sort: 3,
      width: '334px',
    },
    {
      title: "所属会员卡",
      dataIndex: "memberCardName",
      key: "memberCardName",
      sort: 4,
      width: '334px',
      render: (text: string, record: any) =>
        <>
          {record.customerMemberInfo?.map((it: any, index: number) =>
            <div
              key={index}
              style={{ marginBottom: index === record.customerMemberInfo?.length - 1 ? '' : '10px', marginRight: '5px' }}>
              {it.memberCardName ? it.memberCardName : '无'}
            </div>
          )}
        </>
    },
    {
      title: "会员渠道",
      dataIndex: "channelType",
      key: "channelType",
      sort: 5,
      width: '334px',
      render: (value: any, record: any) => renderChannelType(record)
    },
    {
      title: <>
        <div className={styles.userlist_operate}>
          <span style={{ marginRight: '5px' }}>操作</span>
          <SettingOutlined style={{ color: 'var(--text-font-color3)', opacity: '0.45' }} onClick={toOpenEditField} />
        </div>
      </>,
      key: "action",
      fixed: "right",
      width: "88px",
      sort: 12,
      render: (_: any, record: any) => (
        <Space>
          <Space>
            {(state.permissions.findIndex((item: string) => item === '*:*:*') >= 0 || state.permissions.findIndex((item: string) => item === USER_KEY.USER_DETAIL) >= 0) && <Button
              type="link"
              onClick={() => gotoDetail(_.oneId)}
            >详情</Button>}
          </Space>
          {/* <Dropdown
            menu={{ items: getTableOperationButtonList(record) }}
            trigger={["click"]}
          >
            <Space>
              <Button type="link" >
                更多
                <DownOutlined />
              </Button >
            </Space>
          </Dropdown> */}
        </Space>
      ),
    },
  ])
  //tabel 列表字段
  // const columns: TableProps<UserListVO>["columns"] = tabelFinshDataNew as any;
  const [columns, setColumns] = useState(tabelFinshData as any)
  //编辑字段确定
  const refirmTabel = (data: any) => {
    var arr = [] as any
    if (data && data.length > 0) {
      data.map((item: any) => {
        columnsData.map((itm: any) => {
          if (itm.key == item) {
            arr.push(itm)
          }
        })
      })
    }

    let newArr = [...tabelFinshData];
    newArr.splice(newArr.length - 1, 0, ...arr);
    setColumns(newArr)

  }
  const getTableOperationButtonList = (item: any) => {
    const store = [
      {
        key: "empower",
        label: (
          <a
            type="link"
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              changeScoreItem(1)
            }}
          >
            积分变更
          </a>
        ),
      },
      {
        key: "edit",
        label: (
          <a
            type="link"
            style={{ color: item.roleId === 1 ? 'var(--text-font-color10)' : 'var(--text-font-color9)', cursor: item.roleId === 1 ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              changeScoreItem(2)
            }}
          >
            等级变更
          </a>
        ),
      },
      {
        key: "status",
        label: (
          <a
            style={{ color: item.roleId === 1 ? 'var(--text-font-color10)' : 'var(--text-font-color9)', cursor: item.roleId === 1 ? 'not-allowed' : 'pointer' }}
            onClick={(e) => {
              changeScoreItem(3)
            }}
          >
            成长值变更
          </a>
        ),
      },
    ];
    return store;
  };

  // 排序
  // const [sortValue, setSortValue] = useState({
  //   orderByColumn: "updateTime",
  //   isAsc: "desc",
  // });
  // 表格change事件
  let newPage: any = {
    pageNum: 1,
    pageSize: 10
  }
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    let sortStore: any = {};
    if (!sorter.order) {
      sortStore = { orderByColumn: 'updateTime', isAsc: 'desc' };
    } else {
      sortStore = { orderByColumn: sorter.columnKey, isAsc: sorter.order === 'ascend' ? 'asc' : 'desc' };
    }
    // console.log(pagination, 'pagination');
    newPage.pageNum = pagination.current;
    newPage.pageSize = pagination.pageSize;
    console.log(newPage, 'newPage');

    setPageQuery({
      pageNum: pagination.current,
      pageSize: pagination.pageSize,
      total: pageQuery.total
    })

    getUserDetailList({ pageSize: pagination.pageSize, pageNum: pagination.current }, searcParam);
  };

  // 获取客户列表
  const getUserDetailList = async (page: { pageSize: number; pageNum: number }, data?: any,) => {
    console.log(page, 'pageQuery');
    const res = await getUserQueryPageApi({
      // const res = await getUserInsightListApi({
      ...data,
      ...page
    }) as any;
    if (res.data) {
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: res.data.total,
      });
      operatePageNum = page.pageNum
      operatepageSize = page.pageSize
      preParams = data
      if (res.data.rows && res.data.rows.length > 0) {
        res.data.rows.map((item: any, index: any) => {
          item.sort = (page.pageNum - 1) * page.pageSize + (index + 1)
        })
      }
      localStorage.setItem('userInsightDocument', '')
      setScrollY(getTableScroll())
      setListData(res.data.rows)
    }
  };
  //获取表头
  const getTableHead = async () => {
    const res = await getCustomerListTitle();
    if (res) {
      var title: any = []
      //判断 表头数据 不为对象 不为空数组
      if (res.data.configTitle && res.data.configTitle !== "{}" && !(Object.keys(res.data.configTitle).length === 0)) {
        if (res.data.configTitle != "[]") {
          title = JSON.parse(res.data.configTitle)
        }
      }
      setCheckFieldList(title.length > 0 ? title : ['oneId', 'mobile', 'memberCardName', 'channelType'])
      refirmTabel(title)
    }

  };
  // 获取所属渠道
  const getChannelList = async () => {
    const res = await getChannelListApi();
    if (res) {
      setChanneldData(res.data)
      // 获取所属渠道
      var arr: any = res.data;
      channelIconMapList.map((item: any) => {
        arr.map((itm: any, indx: number) => {
          if (item.level == itm.channelType) {
            itm.img = item.img
            itm.level = item.level
            itm.name = item.name
            itm.imgBlue = item.imgBlue
            itm.isLight = item.isLight
          }
        })
      })
      channeldIconData = arr
    }

  };
  //获取店铺信息
  const getShopListData = async (data?: any) => {
    const param = {
      channelType: data
    }
    let res = await getShopList(param);
    //下单数据
    if (res) {
      shopData = res.data
      // setShoplistData(res.data)
    }
  };
  // 获取客户身份列表
  const getUserIdentity = async () => {
    const res = await getIdentityApi();
    if (res) {
      res.data.map((item: any) => {
        item.label = item.identityName;
        item.value = item.identity;
        switch (item.identityName) {
          case '存量会员':
            item.avatar = <CrownOutlined />
            break;
          case '渠道会员':
            item.avatar = <DeploymentUnitOutlined />
            break;
          case '普通客户':
            item.avatar = <TeamOutlined />
            break;
          case '员工':
            item.avatar = <IdcardOutlined />
            break;
          default:
            break;
        }
      })
      setIdentityList(res.data)
      // console.log(identityList, 'identityList');

    }
  }
  // 获取会员卡配置
  const getMemberCardList = async () => {
    const res = await getMemberCardListApi();
    if (res) {
      setMemberCardData(res.data)
    }
  };
  // 获取会员等级
  const getGradeProfileList = async (data: any) => {
    const res = await getMemberLevelList({ memberCardId: data });
    if (res) {
      const list: any = res.data
      list.map((item: any) => {
        item.label = item.gradeName;
        // item.label = 'VIP' + item.gradeCode;
        item.value = item.gradeCode;
      });

      setGradeProfileData(list)
    }
  };
  // 获取会员等级  最新
  const getGradeProfileNewList = async () => {
    const res = await getMemberLevelNewList();
    if (res.data) {
      if (res.data.length > 0) {
        res.data.map((item: any) => {
          item.numCard = item.memberCardId + item.gradeCode
        })
        gradeData = res.data
      }
    }
  };
  interface Params {
    exportFieldList: any[];
    taskLocation: string;
    taskName: string;
    encryptType?: number;
  }
  // 按筛选条件导出
  const checkExport = async (encryptType?: number) => {
    var params: Params = {
      exportFieldList: [] as any,
      ...searcParam,
      taskLocation: "洞察/个体画像洞察/个体画像洞察", //页面位置
      taskName: "洞察-个体画像洞察", //页面位置
    }
    if (encryptType) {
      params = { ...params, encryptType }
    }
    columns.map((item: any) => {
      if ((!(item.title instanceof Object) && item.key != 'sort') || (item.key == 'tradeCount')) {
        if (item.key == 'tradeCount') {
          params.exportFieldList.push(
            {
              field: 'tradeCount',
              name: '累计下单次数',
            }
          )
        } else {
          params.exportFieldList.push(
            {
              field: item.key,
              name: item.title,
            }
          )
        }
      }
    })
    const res = await exportData(params);
    if (res?.code === 200) {
      message.success(DOWNLOAD_SUCCESS_TEXT);
    } else {
      message.error("导出失败");
    }

  };
  const getHeight = (e: string) => {
    console.log(e);
    setScrollY(e)
  }
  useEffect(() => {
    console.log(state.permissions);

    getMemberCardList()//会员卡
    getChannelList()//所属渠道
    getShopListData()//所属店铺
    getGradeProfileNewList()//获取会员等级
    getUserIdentity()//获取客户身份列表
    getTableHead()//获取表头s
    operatePageNum = localStorage.getItem('pageNum') ? Number(localStorage.getItem('pageNum')) : null
    operatepageSize = localStorage.getItem('pageSize') ? Number(localStorage.getItem('pageSize')) : null
    preParams = localStorage.getItem('searchListData') ? JSON.parse(localStorage.getItem('searchListData') as string) : {}
    //筛选项 下拉选择 手机号 订单号 oneid
    phoneorIdData = localStorage.getItem('phoneorId') ? localStorage.getItem('phoneorId') : 'mobile'
    setPhoneorId(phoneorIdData)
    localStorage.removeItem('pageNum')
    localStorage.removeItem('pageSize')
    localStorage.removeItem('searchListData')
    localStorage.removeItem('phoneorId')

    setSearcParam(preParams)
    if (operatePageNum || operatepageSize || Object.keys(preParams).length != 0) {
      getUserDetailList({ pageSize: operatepageSize, pageNum: operatePageNum }, preParams);
    } else {
      getUserDetailList({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum });
    }

    return () => {

    };
  }, []);

  return (
    <div>
      <div className='headBox'>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="客户列表" />
          {/* <Tooltip placement="top" title="入会的客户和下单的客户会被整合在客户列表中">
            <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
          </Tooltip> */}
        </div>
        <div className={styles.searchBox}>
          <Tooltip placement="bottom" title={'过滤器'}>
            <Button
              onClick={() => filterViewRef.current?.showFilterView()}
              icon={
                <FilterOutlined
                  style={{
                    color: iconColor,
                    fontSize: 'var(--text-font7)',
                  }}
                />
              }
              type="text"
              className="click-element"
              style={{ marginRight: '10px' }}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}></Button>
          </Tooltip>
          <span
            style={{
              width: "0",
              height: "16px",
              border: "var(--border3)",
              marginRight: 16,
            }}
          ></span>
          {checkPermissions(USER_KEY.USER_EXPORT) && <Button
            onClick={toOpenExportRefirm}
          >按筛选条件导出</Button>}
          {/* <Button
            style={{ marginLeft: '10px' }}
            type="primary"
            onClick={toOpenAddStaff}
          // permissions={USER_KEY.USER_EXPORT}
          >添加员工</Button> */}
        </div>
      </div>
      <div className='containerBox'>
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={filterFinish}
          getHeight={getHeight}
          handleChange={handleChangeFilter}
        ></FilterView>
        {checkPermissions(USER_KEY.USER_LIST) && <div className={styles.tableBox}>
          <Table
            rowKey={(record) => record.sort}
            columns={columns}
            // scroll={{ x: 1500, y: 700 }}
            scroll={{ x: 1500, y: scrollY }}
            dataSource={listData}
            pagination={{
              current: pageQuery.pageNum,
              total: pageQuery.total,
              pageSize: pageQuery.pageSize,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
              showTotal: (total) => `共 ${total} 条`,
            }}
            onChange={handleStandardTableChange}
          />
        </div>}
        {/* <ChangeScore
          openScoreChange={openScoreChange}
          closeScoreChange={closeScoreChange}
          popType={popType}
        /> */}
        <AddStaff
          openAddStaff={openAddStaff}
          closeOpenAddStaff={closeOpenAddStaff}
          popType={popType}
        />
        <ExportRefirm
          openExportRefirm={openExportRefirm}
          closeExportRefirm={closeExportRefirm}
          checkExport={checkExport}
        />
        <EditField
          openEditField={openEditField}
          closeOpenEditField={closeOpenEditField}
          refirmTabel={refirmTabel}
          checkFieldList={checkFieldList}
          popType={popType}
        />
      </div>
    </div>
  );
};

export default UserInsightUserSerch;
