import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FilterOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Drawer, Space, Steps, Modal, Popconfirm, message, Tooltip, Dropdown } from 'antd';
import type { TableProps, DrawerProps, TabsProps } from 'antd';
import ModuleTitle from '../../../../component/ModuleTitle';
import SearchIconInput from '../../../../component/Input/SearchIconInput/searchIconInput';
import FilterView from '../../../../component/FilterView/filterView';
import FilterIcon from '../../../../component/FilterIcon/filterIcon';
import { GROUP_KEY } from '../../../../router/Permissions';
import { checkPermissions } from '@/utils/utils';
import styles from './index.module.scss';
import { queryHistoryReportForm, downloadReportForm } from '../../../../api/UserInsight/UserInsight';
import BackList from '../../../../component/BackList/backList';
const GroupUserInsightHistory = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const filterViewRef: any = useRef(null);

  // 抽屉开关
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [listData, setListData] = useState([]);
  const searchList: searchItem[] = [
    {
      searchType: 'string',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '状态',
      key: 'calculateStatus',
      options: [
        {
          value: '',
          label: '全部',
        },
        {
          value: '0',
          label: '计算中',
        },
        {
          value: '1',
          label: '成功',
        },
        {
          value: '2',
          label: '失败',
        },
      ],
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '计算完成时间',
      key: 'calculateBeginDate-calculateEndDate',
    },
  ];

  const searchParamInt: any = {
    searchValue: '',
    createWay: '',
    calculateBeginDate: '',
    calculateEndDate: '',
  };
  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 换页
  const handleTableChange = (e: any) => {
    getListData({ pageSize: e.pageSize, pageNum: e.current }, searchParam);
  };

  const columns: TableProps['columns'] = [
    {
      title: '序号',
      dataIndex: 'sort',
      key: 'sort',
      width: '100px',
      render: (_: any, record: any, i: number) => <span>{(pageQuery.pageNum - 1) * pageQuery.pageSize + (i + 1)}</span>,
    },
    {
      title: '报告名称',
      dataIndex: 'reportName',
      key: 'reportName',
    },
    {
      title: '更新方式',
      dataIndex: 'updateModel',
      key: 'updateModel',
      render: (_, record) => (
        // 0 定时  3手动
        <div>{_ === '0' ? <span>定时更新</span> : <span>手动更新</span>}</div>
      ),
    },
    {
      title: '状态',
      dataIndex: 'calculateStatus',
      key: 'calculateStatus',
      render: (_, record) => (
        // 0 计算中  1 成功  2失败
        <div>{_ === 0 ? <Tag color="processing">计算中</Tag> : _ === 1 ? <Tag color="success">成功</Tag> : <Tag color="error">失败</Tag>}</div>
      ),
    },
    {
      title: '计算完成时间',
      dataIndex: 'finishDate',
      key: 'finishDate',
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '操作',
      key: 'action',
      width: '88px',
      align: 'center',
      render: (_, record: any) => (
        <div>
          {checkPermissions(GROUP_KEY.GROUP_DOWNLOAD) ? (
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                DownLoadReport(record);
              }}>
              下载
            </Button>
          ) : (
            <div>--</div>
          )}
        </div>
      ),
    },
  ];
  // 下载
  const DownLoadReport = async (item: any) => {
    const res = await downloadReportForm({
      reportId: item.reportId,
      reportChildId: item.reportChildId,
      modelName: '洞察-群体画像洞察',
      taskLocation: '洞察/群体画像洞察/群体画像洞察',
    });
    if (res) {
      message.success(DOWNLOAD_SUCCESS_TEXT);
    }
  };
  const [searchParam, setSearchParam] = useState({ ...searchParamInt });

  const filterFinish = (data: any) => {
    setSearchParam(data);
    getListData({ pageSize: 10, pageNum: 1 }, data);
  };
  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };
  const getListData = async (page: { pageSize: number; pageNum: number }, data: any) => {
    const res = await queryHistoryReportForm({
      ...page,
      ...data,
      reportId: location.state.record.reportId,
    });
    if (res) {
      const { total, rows } = res;
      setListData(rows);
      setPageQuery({
        pageSize: page.pageSize,
        pageNum: page.pageNum,
        total: total,
      });
    }
  };
  useEffect(() => {
    getListData({ pageSize: 10, pageNum: 1 }, {});
  }, []);

  return (
    <div className={styles.groupUserInsight}>
      <div className={styles.titleAndOperate}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BackList></BackList>
          <ModuleTitle mark="large" title={location.state.record.reportName} />
        </div>
        <div className={styles.buttonBox}>
          <FilterIcon handleFilterClick={handleFilterClick} filterTitle={'过滤器'} />
        </div>
      </div>
      <FilterView ref={filterViewRef} searchList={searchList} finish={filterFinish}></FilterView>
      <div style={{ marginTop: '24px' }}>
        <Table
          columns={columns}
          dataSource={listData}
          rowKey={record => record.id}
          pagination={{
            total: pageQuery.total,
            pageSize: pageQuery.pageSize,
            showSizeChanger: true,
            showTotal: total => `共 ${total} 条`,
          }}
          onChange={e => handleTableChange(e)}
        />
      </div>
    </div>
  );
};

export default GroupUserInsightHistory;
