/**
 * 行业看板-总览
 */
// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import TaskList from "./../../Industry/component/TaskList";
import OverviewItem from "./component/OverviewItem";
import BlockModuleTitle from "./component/BlockModuleTitle";
import ttlPng from "@/assets/images/ttl.png";
import membersPng from "@/assets/images/Members.png";
import membersOverviewPng from "@/assets/images/MembersOverview.png";
import { useGetMetricList } from "@/crm/assets/public/industryBoard";
import dayjs from "dayjs";
import Header from "../component/Header";
import { checkPermissions } from "@/utils/utils";
import { INDUSTRYOVERVIEW_KEY } from "@/router/Permissions";
import { getGeneralChat, downLoadOverview } from "@/crm/api/industryBoardApi";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";
import { message } from "antd";
const Overview: React.FC = () => {
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";

  const initTTLDataSource = [
    {
      id: "0",
      titleEn: "Sales",
      titleZh: "销售额",
      tip: "统计期内所有客户已支付的销售额",
      value: "",
      digit: 2,
      mom: "",
      yoy: "",
      color: "blue",
      miniChatData: [],
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "1",
      titleEn: "Purchaser",
      titleZh: "购买人数",
      digit: 0,
      tip: "统计期内购买的总客户人数",
      value: "",
      mom: "",
      yoy: "",
      color: "blue",
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "2",
      titleEn: "ATV",
      titleZh: "笔单价",
      digit: 2,
      tip: "统计期内，平均每笔订单销售额。销售额/订单数",
      value: "",
      mom: "",
      yoy: "",
      color: "blue",
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "3",
      titleEn: "FREQ",
      titleZh: "购买频次",
      digit: 2,
      tip: "统计期内，平均每个客户购买的订单数",
      value: "",
      mom: "",
      yoy: "",
      color: "blue",
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "4",
      titleEn: "SPD",
      titleZh: "客单价",
      digit: 2,
      tip: "统计期内，平均每个客户购买的金额。销售额/购买人数",
      value: "",
      mom: "",
      yoy: "",
      color: "blue",
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "5",
      titleEn: "UPT",
      titleZh: "笔件数",
      digit: 2,
      tip: "统计期内，平均每笔订单中购买的产品件数。sku数/订单数",
      value: "",
      mom: "",
      yoy: "",
      color: "blue",
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "6",
      titleEn: "Retention Rate",
      titleZh: "老客回购率",
      percent: true,
      digit: 2,
      tip: "统计期前12个月有购买的客户在本期的回购比例。",
      value: "",
      mom: "",
      yoy: "",
      color: "blue",
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "7",
      titleEn: "New Purchaser W%",
      titleZh: "新客购买人数占比",
      digit: 2,
      percent: true,
      tip: "统计期内新客的购买人数/统计期内购买总人数",
      value: "",
      mom: "",
      yoy: "",
      color: "blue",
      metricId: "",
      frameWorkType: 0,
    },
  ];
  const [TTLDataSource, setTTLDataSource] = useState(initTTLDataSource);
  const initMembersDataSource = [
    {
      id: "8",
      titleEn: "Members Sales",
      titleZh: "会员销售额",
      digit: 2,
      tip: "统计期内会员身份的销售额",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      miniChatData: [],
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "9",
      titleEn: "Members Purchaser",
      titleZh: "会员购买人数",
      digit: 0,
      tip: "统计期内会员的购买人数",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      miniChatData: [],
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "10",
      titleEn: "ATV",
      titleZh: "会员笔单价",
      digit: 2,
      tip: "统计期内，由会员产生的订单，平均每笔订单金额。会员销售额/会员订单数",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      miniChatData: [],
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "11",
      titleEn: "FREQ",
      titleZh: "会员购买频次",
      digit: 2,
      tip: "统计期内，平均每个有效购买订单的会员购买的订单数。会员订单数/会员购买人数",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      miniChatData: [],
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "12",
      titleEn: "SPD",
      titleZh: "会员客单价",
      digit: 2,
      tip: "统计期内，平均每个有效购买订单的会员的销售额。会员销售额/会员购买人数",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      miniChatData: [],
      metricId: "",
      frameWorkType: 0,
    },
    {
      id: "13",
      titleEn: "UPT",
      titleZh: "会员笔件数",
      digit: 2,
      percent: false,
      tip: "统计期内，由会员产生的订单，平均每笔订单包含的产品sku数。会员购买件数/会员有效购买订单数",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      miniChatData: [],
      metricId: "",
      frameWorkType: 0,
    },
  ];
  const [MembersDataSource, setMembersDataSource] = useState(
    initMembersDataSource
  );
  const initMembersOverviewDataSource = [
    {
      id: "14",
      titleEn: "TTL Members",
      titleZh: "累计注册会员数",
      tip: "截止到统计期存量会员，所有已注册的会员人数(不含解绑)",
      value: "",
      digit: 0,
      mom: "",
      yoy: "",
      color: "lakeBlue",
      metricId: "",
      miniChatData: [],
      frameWorkType: 0,
    },
    {
      id: "15",
      titleEn: "Members Sales W%",
      titleZh: "会员销售额占比",
      percent: true,
      digit: 2,
      tip: "统计期内会员销售额占比所有客户销售额。会员销售额/销售额",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      metricId: "",
      miniChatData: [],
      frameWorkType: 0,
    },
    {
      id: "16",
      titleEn: "Members Purchaser W%",
      titleZh: "会员购买人数占比",
      percent: true,
      digit: 2,
      tip: "统计期内会员的购买人数占比所有购买客户数。会员购买人数/购买人数",
      value: "",
      mom: "",
      yoy: "",
      color: "lakeBlue",
      metricId: "",
      miniChatData: [],
      frameWorkType: 0,
    },
  ];
  const [MembersOverviewDataSource, setMembersOverviewDataSource] = useState(
    initMembersOverviewDataSource
  );
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const { metricAllList } = useGetMetricList();
  const [searchForm, setSearchForm] = useState<any>({});
  const [taskListVisible, setTaskListVisible] = useState(false);
  const [getMetricIdEnd, setGetMetricIdEnd] = useState(false);
  const [TTLDataSourceFinal, setTTLDataSourceFinal] = useState<any>([]);
  const [MembersDataSourceFinal, setMembersDataSourceFinal] = useState<any>([]);
  const [MembersOverviewDataSourceFinal, setMembersOverviewDataSourceFinal] =
    useState<any>([]);
  const [TTLmetricIds, setTTLmetricIds] = useState<any>([]);
  const [MembersmetricIds, setMembersmetricIds] = useState<any>([]);
  const [MembersOverviewmetricIds, setMembersOverviewmetricIds] = useState<any>(
    []
  );
  const [finalSearchForm, setFinalSearchForm] = useState<any>({});

  useEffect(() => {
    if (getMetricIdEnd && channelAllList.length > 0) {
      let finalShopId = [];
      if (searchForm?.platformType?.includes("all")) {
        finalShopId = shopListBySeveralChannel
          .filter((item) => item.value !== "all")
          .map((item) => item.value);
      } else {
        if (searchForm?.shopId?.includes("all")) {
          finalShopId = searchForm?.allShopIds;
        } else {
          finalShopId = searchForm?.shopId?.split(",");
        }
      }
      const searchParams = {
        isShopAll: searchForm?.isShopAll,
        isPlatformAll: searchForm?.isPlatformAll,
        startDate: searchForm?.startDate + starthms,
        endDate: searchForm?.endDate + endhms,
        dateType: searchForm?.dateType,
        channels: searchForm?.platformType?.includes("all")
          ? channelAllList
              .filter((item) => item.value !== "all")
              .map((item) => item.value)
          : searchForm?.platformType?.split(","),
        shopIds: finalShopId,
      };
      setFinalSearchForm(searchParams);
      setTTLDataSourceFinal([]);
      setMembersDataSourceFinal([]);
      setMembersOverviewDataSourceFinal([]);
      // ttl
      const groupedTTLDataSource = groupArrByFrameWorkType(TTLDataSource);
      const groupedNumTTLDataKeys = Object.keys(
        groupDataToNumProp(groupedTTLDataSource).num
      );
      const groupedNumTTLDataValues = Object.values(
        groupDataToNumProp(groupedTTLDataSource).num
      );
      const groupedPropTTLDataKeys = Object.keys(
        groupDataToNumProp(groupedTTLDataSource).prop
      );
      const groupedPropTTLDataValues = Object.values(
        groupDataToNumProp(groupedTTLDataSource).prop
      );
      // Members
      const groupedMembersDataSource =
        groupArrByFrameWorkType(MembersDataSource);
      const groupedMembersDataKeys = Object.keys(groupedMembersDataSource);
      const groupedMembersDataValues = Object.values(groupedMembersDataSource);
      // MembersOverview
      const groupedMembersOverviewDataSource = groupArrByFrameWorkType(
        MembersOverviewDataSource
      );
      const groupedNumMembersOverviewDataKeys = Object.keys(
        groupDataToNumProp(groupedMembersOverviewDataSource).num
      );
      const groupedNumMembersOverviewDataValues = Object.values(
        groupDataToNumProp(groupedMembersOverviewDataSource).num
      );
      const groupedPropMembersOverviewDataKeys = Object.keys(
        groupDataToNumProp(groupedMembersOverviewDataSource).prop
      );
      const groupedPropMembersOverviewDataValues = Object.values(
        groupDataToNumProp(groupedMembersOverviewDataSource).prop
      );
      // 有几种类别的frameWorkType就循环几次，最后合并3种类型的数据
      groupedNumTTLDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedNumTTLDataValues[index].map((i) => i.metricId),
          "ttl",
          "num"
        );
      });
      groupedPropTTLDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedPropTTLDataValues[index].map((i) => i.metricId),
          "ttl",
          "prop"
        );
      });
      groupedMembersDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedMembersDataValues[index].map((i) => i.metricId),
          "members",
          "num"
        );
      });
      groupedNumMembersOverviewDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedNumMembersOverviewDataValues[index].map((i) => i.metricId),
          "membersOverview",
          "num"
        );
      });
      groupedPropMembersOverviewDataKeys.forEach((item, index) => {
        getChartData(
          searchParams,
          groupedPropMembersOverviewDataValues[index].map((i) => i.metricId),
          "membersOverview",
          "prop"
        );
      });
    }
  }, [TTLDataSource, channelAllList]);

  // allDataSource根据字段frameWorkType分组
  const groupArrByFrameWorkType = (arr: any) => {
    const data = arr.reduce(
      (acc: { [x: string]: any[] }, obj: { frameWorkType: any }) => {
        const key = obj.frameWorkType;
        if (!acc[key]) {
          acc[key] = []; // 如果还没有这个分组，初始化为空数组
        }
        acc[key].push(obj); // 将对象加入对应的分组
        return acc;
      },
      {}
    ); // 初始值为空对象
    return data;
  };

  // allDataSource根据字段将占比类别分组
  const groupDataToNumProp = (data) => {
    const result = {
      num: {},
      prop: {},
    };
    // 遍历原始数据
    for (const [key, values] of Object.entries(data)) {
      // 创建新的数组存储分类后的数据
      const numItems = [];
      const propItems = [];
      // 遍历当前类别的所有项
      values.forEach((item) => {
        if (item.titleZh.includes("占比") || item.titleZh.includes("率")) {
          propItems.push(item);
        } else {
          numItems.push(item);
        }
      });
      // 根据分类填充到结果中
      if (numItems.length > 0) {
        result.num[key] = numItems;
      }
      if (propItems.length > 0) {
        result.prop[key] = propItems;
      }
    }
    return result;
  };

  useEffect(() => {
    // 给指标赋值id
    if (metricAllList.length > 0) {
      setMetricId();
    }
  }, [searchForm, metricAllList]);

  const setMetricId = () => {
    const copyTTLDataSource = [];
    // 渠道不为all&&店铺为all，则指标名后面拼接 “分渠道”
    // 店铺不为all不管渠道是什么，则指标名后面拼接 “分店铺”
    let str = "";
    if (!searchForm?.shopId?.includes("all")) {
      str += "分店铺";
    } else if (
      searchForm?.shopId?.includes("all") &&
      !searchForm?.platformType?.includes("all")
    ) {
      str += "分渠道";
    } else if (
      searchForm?.shopId?.includes("all") &&
      searchForm?.platformType?.includes("all")
    ) {
      str = "";
    }
    TTLDataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return (
          i.metricName ===
          (item.titleZh === "销售额" ? "购买金额" : item.titleZh) + str
        );
      });
      if (findItem.length > 0) {
        copyTTLDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setTTLDataSource(copyTTLDataSource);
    setTTLmetricIds(copyTTLDataSource.map((item) => item.metricId));
    const copyMembersDataSource = [];
    MembersDataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return (
          i.metricName ===
          (item.titleZh === "销售额" ? "购买金额" : item.titleZh) + str
        );
      });
      if (findItem.length > 0) {
        copyMembersDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setMembersDataSource(copyMembersDataSource);
    setMembersmetricIds(copyMembersDataSource.map((item) => item.metricId));
    const copyMembersOverviewDataSource = [];
    MembersOverviewDataSource.forEach((item) => {
      const findItem = metricAllList.filter((i: any) => {
        return (
          i.metricName ===
          (item.titleZh === "销售额" ? "购买金额" : item.titleZh) + str
        );
      });
      if (findItem.length > 0) {
        copyMembersOverviewDataSource.push({
          ...item,
          metricId: findItem[0].metricId,
          frameWorkType: findItem[0].frameWorkType,
        });
      }
    });
    setMembersOverviewDataSource(copyMembersOverviewDataSource);
    setMembersOverviewmetricIds(
      copyMembersOverviewDataSource.map((item) => item.metricId)
    );
    setGetMetricIdEnd(true);
  };
  // 获得折线图列表
  // indexIdsArr循环的当次的frameWorkType
  const getChartData = async (
    searchParams: any,
    indexIdsArr: any,
    type: string,
    dataType: string
  ) => {
    const copyTTl = [...TTLDataSource];
    const copyMembers = [...MembersDataSource];
    const copyMembersOverview = [...MembersOverviewDataSource];
    const params = {
      ...searchParams,
      indexIds: indexIdsArr,
      // num数值类型指标  yoy同比指标 mom环比指标 prop占比 dis折线图 yoy_dis同比折线图
      // 这里取num或prop
      indexType: dataType,
    };
    const res = await getGeneralChat(params);
    if (res?.code === 200) {
      if (res?.data) {
        if (type === "ttl") {
          Object.keys(res?.data).forEach((item) => {
            copyTTl.filter((i) => i.metricId === item)[0].value =
              res?.data?.[item];
          });
        } else if (type === "members") {
          Object.keys(res?.data).forEach((item) => {
            copyMembers.filter((i) => i.metricId === item)[0].value =
              res?.data?.[item];
          });
        } else if (type === "membersOverview") {
          Object.keys(res?.data).forEach((item) => {
            copyMembersOverview.filter((i) => i.metricId === item)[0].value =
              res?.data?.[item];
          });
        }
      }
      // yoy
      const paramsYoy = {
        ...searchParams,
        indexIds: indexIdsArr,
        indexType: "yoy",
      };
      const resYoy = await getGeneralChat(paramsYoy);
      if (resYoy?.code === 200) {
        if (type === "ttl") {
          Object.keys(resYoy?.data).forEach((item) => {
            copyTTl.filter((i) => i.metricId === item)[0].yoy =
              resYoy?.data?.[item];
          });
        } else if (type === "members") {
          Object.keys(resYoy?.data).forEach((item) => {
            copyMembers.filter((i) => i.metricId === item)[0].yoy =
              resYoy?.data?.[item];
          });
        } else if (type === "membersOverview") {
          Object.keys(resYoy?.data).forEach((item) => {
            copyMembersOverview.filter((i) => i.metricId === item)[0].yoy =
              resYoy?.data?.[item];
          });
        }
        // mom
        const paramsMom = {
          ...searchParams,
          indexIds: indexIdsArr,
          indexType: "mom",
        };
        const resMom = await getGeneralChat(paramsMom);
        if (resMom?.code === 200) {
          if (resMom?.data) {
            if (type === "ttl") {
              Object.keys(resMom?.data).forEach((item) => {
                copyTTl.filter((i) => i.metricId === item)[0].mom =
                  resMom?.data?.[item];
              });
            } else if (type === "members") {
              Object.keys(resMom?.data).forEach((item) => {
                copyMembers.filter((i) => i.metricId === item)[0].mom =
                  resMom?.data?.[item];
              });
            } else if (type === "membersOverview") {
              Object.keys(resMom?.data).forEach((item) => {
                copyMembersOverview.filter((i) => i.metricId === item)[0].mom =
                  resMom?.data?.[item];
              });
            }
          }

          // dis折线图
          const paramsDis = {
            ...searchParams,
            // 迷你折线图：选择少于7天/周/月/年，但参数要传近7天/周/月/年，参数用paramStartDate和paramEndDate
            startDate: searchForm?.paramStartDate + starthms,
            endDate: searchForm?.paramEndDate + endhms,
            isSupplyDate: searchForm?.isSupplyDate,
            indexIds: indexIdsArr,
            indexType: "dis",
          };
          const resDis = await getGeneralChat(paramsDis);
          if (resDis?.code === 200) {
            if (resDis?.data) {
              if (type === "ttl") {
                Object.keys(resDis?.data).forEach((item) => {
                  copyTTl.filter((i) => i.metricId === item)[0].miniChatData =
                    resDis?.data?.[item];
                });
                setTTLDataSourceFinal([...copyTTl]);
              } else if (type === "members") {
                Object.keys(resDis?.data).forEach((item) => {
                  copyMembers.filter(
                    (i) => i.metricId === item
                  )[0].miniChatData = resDis?.data?.[item];
                });
                setMembersDataSourceFinal([...copyMembers]);
              } else if (type === "membersOverview") {
                Object.keys(resDis?.data).forEach((item) => {
                  copyMembersOverview.filter(
                    (i) => i.metricId === item
                  )[0].miniChatData = resDis?.data?.[item];
                });
                setMembersDataSourceFinal([...copyMembersOverview]);
              }
            } else {
            }
          }
        }
      }
    } else {
      message.error(res?.msg);
    }
  };

  //抽屉  true  导出确认框
  const [openExportRefirm, setOpenExportRefirm] = useState(false);
  // 导出
  const download = async () => {
    let finalShopId = [];
    if (searchForm?.platformType?.includes("all")) {
      finalShopId = shopListBySeveralChannel
        .filter((item) => item.value !== "all")
        .map((item) => item.value);
    } else {
      if (searchForm?.shopId?.includes("all")) {
        finalShopId = searchForm?.allShopIds;
      } else {
        finalShopId = searchForm?.shopId?.split(",");
      }
    }
    const params = {
      moduleName: `行业看板-总览-${dayjs().format("YYYYMMDDHHmmss")}`,
      taskLocation: "行业看板/总览",
      startDate: searchForm?.startDate + starthms,
      endDate: searchForm?.endDate + endhms,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.includes("all")
        ? channelAllList
            .filter((item) => item.value !== "all")
            .map((item) => item.value)
        : searchForm?.platformType?.split(","),
      shopIds: finalShopId,
      isShopAll: searchForm?.isShopAll,
      isPlatformAll: searchForm?.isPlatformAll,
    };
    const res = await downLoadOverview(params);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    } else {
      message.success("导出失败");
    }
  };

  return (
    <div>
      {checkPermissions(INDUSTRYOVERVIEW_KEY.OVERVIEW_LIST) && (
        <>
          <Header
            title={"总览"}
            searchForm={searchForm}
            toExport={download}
            hasExport={checkPermissions(INDUSTRYOVERVIEW_KEY.OVERVIEW_EXPORT)}
            setSearchForm={(v: any) => {
              setSearchForm(v);
            }}
          />
          <div className={styles.container}>
            <div className={styles.searchForm}>
              <div className={styles.ttl}>
                <BlockModuleTitle icon={ttlPng} title={"TTL"} />
                <OverviewItem
                  dataSource={TTLDataSource}
                  searchForm={searchForm}
                />
              </div>
              <div className={styles.members}>
                <BlockModuleTitle icon={membersPng} title={"Members"} />
                <OverviewItem
                  dataSource={MembersDataSource}
                  searchForm={searchForm}
                />
              </div>
              <div className={styles.members}>
                <BlockModuleTitle
                  icon={membersOverviewPng}
                  title={"Members Overview"}
                />
                <OverviewItem
                  dataSource={MembersOverviewDataSource}
                  searchForm={searchForm}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
