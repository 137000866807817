import React, { useState, useEffect, useRef } from 'react';
import { Checkbox, Col, Divider, Tooltip, message, Empty } from 'antd';
import styles from './index.module.scss';
import ModuleTitle from '../../../../component/ModuleTitle';
import { PieChart } from './components/PieChart';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { getPlatFormInfoApi, getUserPropertyListApi } from '../../../../api/userIntersection/userIntersection';
import { getChannelListApi } from '../../../../api/UserInsight/UserInsight'
import type { CheckboxProps, GetProp } from 'antd';
import dayjs from 'dayjs';
import jd_blue from '../../../../crm/assets/images/jd_blue.png';
import tm_blue from '../../../../crm/assets/images/tm_blue.png';
import dy_blue from '../../../../crm/assets/images/dy_blue.png';
import wx_blue from '../../../../crm/assets/images/wx_blue.png';
import offlineShop_blue from '../../../../crm/assets/images/offlineShop_blue.png';
import yz_blue from '../../../../crm/assets/images/yz_blue.png';
import jd from '../../../../crm/assets/images/jd.png';
import tm from '../../../../crm/assets/images/tm.png';
import dy from '../../../../crm/assets/images/dy.png';
import yz from '../../../../crm/assets/images/yz.png';
import wx from '../../../../crm/assets/images/wx.png';
import offlineShop from '../../../../crm/assets/images/offlineShop.png';
import { getNumberWithComma } from '@/utils/utils';
import _ from 'lodash';

type CheckboxValueType = GetProp<typeof Checkbox.Group, 'value'>[number];
// const platFormInfo = [
//   {
//     label: '京东',
//     value: 'jd',
//     item: 'JD',
//     count: 0,
//     percent: 0,
//     gmv: '',
//     payCustomer: 0,
//     payMember: 0,
//     customerPrice: '',
//     orderNum: 0,
//     color: '#3BA0FF',
//     memberNum: 0,
//   },
//   {
//     label: '天猫',
//     value: 'tm',
//     item: 'TM',
//     count: 0,
//     percent: 0,
//     gmv: '',
//     payCustomer: 0,
//     payMember: 0,
//     customerPrice: '',
//     orderNum: 0,
//     color: '#36CBCB',
//     memberNum: 0,
//   },
//   {
//     label: '抖音',
//     value: 'dy',
//     item: 'DY',
//     count: 0,
//     percent: 0,
//     gmv: '',
//     payCustomer: 0,
//     payMember: 0,
//     customerPrice: '',
//     orderNum: 0,
//     color: '#4DCB73',
//     memberNum: 0,
//   },
//   {
//     label: '有赞',
//     value: 'yz',
//     item: 'YZ',
//     count: 0,
//     percent: 0,
//     gmv: '',
//     payCustomer: 0,
//     payMember: 0,
//     customerPrice: '',
//     orderNum: 0,
//     color: '#FADB14',
//     memberNum: 0,
//   },
//   {
//     label: '微信',
//     value: 'wx',
//     item: 'WX',
//     count: 0,
//     percent: 0,
//     gmv: '',
//     payCustomer: 0,
//     payMember: 0,
//     customerPrice: '',
//     orderNum: 0,
//     color: '#364bcb',
//     memberNum: 0,
//   },
//   {
//     label: '线下门店',
//     value: 'xx',
//     item: 'MD',
//     count: 0,
//     percent: 0,
//     gmv: '',
//     payCustomer: 0,
//     payMember: 0,
//     customerPrice: '',
//     orderNum: 0,
//     color: '#A0D911',
//     memberNum: 0,
//   },
// ];
// 全渠道
const platFromList = [
  {
    label: '全渠道',
    value: null,
    item: '全渠道',
    count: 0,
    percent: 0,
    gmv: '',
    payCustomer: 0,
    payMember: 0,
    customerPrice: '',
    orderNum: 0,
    memberNum: 0,
  },
  {
    label: '京东',
    value: 'jd',
    item: 'JD',
    count: 0,
    percent: 0,
    gmv: '',
    payCustomer: 0,
    payMember: 0,
    customerPrice: '',
    orderNum: 0,
    color: '#3BA0FF',
    memberNum: 0,
  },
  {
    label: '天猫',
    value: 'tm',
    item: 'TM',
    count: 0,
    percent: 0,
    gmv: '',
    payCustomer: 0,
    payMember: 0,
    customerPrice: '',
    orderNum: 0,
    color: '#36CBCB',
    memberNum: 0,
  },
  {
    label: '抖音',
    value: 'dy',
    item: 'DY',
    count: 0,
    percent: 0,
    gmv: '',
    payCustomer: 0,
    payMember: 0,
    customerPrice: '',
    orderNum: 0,
    color: '#4DCB73',
    memberNum: 0,
  },
  {
    label: '有赞',
    value: 'yz',
    item: 'YZ',
    count: 0,
    percent: 0,
    gmv: '',
    payCustomer: 0,
    payMember: 0,
    customerPrice: '',
    orderNum: 0,
    color: '#FADB14',
    memberNum: 0,
  },
  {
    label: '微信',
    value: 'wx',
    item: 'WX',
    count: 0,
    percent: 0,
    gmv: '',
    payCustomer: 0,
    payMember: 0,
    customerPrice: '',
    orderNum: 0,
    color: '#FADB14',
    memberNum: 0,
  },
  {
    label: '线下门店',
    value: 'xx',
    item: 'MD',
    count: 0,
    percent: 0,
    gmv: '',
    payCustomer: 0,
    payMember: 0,
    customerPrice: '',
    orderNum: 0,
    color: '#A0D911',
    memberNum: 0,
  },
];
const UserChart = () => {
  const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
  const [chooseInfo, setChooseInfo] = useState<any[]>([]);
  const [chooseList, setChooseList] = useState<any[]>([]);
  const defaultCheckedList = ['jd', 'tm', 'dy', 'yz', 'wx', 'xx'];
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

  const [platFormInfo, setPlatFormInfo] = useState([] as any)

  const getPlatFormInfo = async (data: any) => {
    const res = await getPlatFormInfoApi(data);
    if (res?.data) {
      console.log(platFormInfo);

      platFormInfo.forEach((channel: any) => {
        res.data.forEach((item: any) => {
          if (channel.value === item.platformType) {
            channel.percent = Number(item.gmv) / Number(item.gmv);
            channel.gmv = item.gmv;
            channel.payCustomer = item.payCustomer;
            channel.payMember = item.payMember;
            channel.customerPrice = item.customerPrice;
            channel.orderNum = item.orderNum;
            channel.orderPercent = (Number(item?.orderNum) / Number(item?.orderNum)) * 100;
            channel.customerpercent = (Number(item?.customerPrice) / Number(item?.customerPrice)) * 100;
            channel.memberNum = item.memberNum;
          }
        });
      });
      setChooseInfo(platFormInfo);
    }
  };
  const getUserPropertyList = async (data: any) => {
    const res = await getUserPropertyListApi(data);
    if (res?.data) {
      if (res.data.length > 0) {
        platFromList.forEach((channel: any) => {
          channel.percent = 0;
        });

        let a = [] as any;
        // const arr = res.data.splice(1)
        const arr = _.filter(res.data, function (o: any) {
          return o.platformType !== null;
        });
        const totalAge = arr.reduce((sum: any, item: any) => sum + Number(item.customerPrice), 0);
        // totalAge -= res.data[0].
        platFromList.forEach((channel: any) => {
          res.data.forEach((item: any) => {
            if (channel.value === item.platformType) {
              channel.gmv = item.gmv;
              channel.payCustomer = item.payCustomer;
              channel.payMember = item.payMember;
              channel.customerPrice = item.customerPrice;
              channel.orderNum = item.orderNum;
              channel.percent = Number(item?.gmv) / Number(platFromList[0].gmv);
              channel.orderPercent = (Number(item?.orderNum) / Number(platFromList[0].orderNum)) * 100;
              channel.customerpercent = (Number(item?.customerPrice) / totalAge) * 100;
              channel.memberNum = item.memberNum;
              switch (channel.value) {
                case 'jd':
                  channel.label = '京东';
                  channel.item = '京东';
                  break;
                case 'tm':
                  channel.label = '天猫';
                  channel.item = '天猫';
                  break;
                case 'dy':
                  channel.label = '抖音';
                  channel.item = '抖音';
                  break;
                case 'yz':
                  channel.label = '有赞';
                  channel.item = '有赞';
                  break;
                case 'wx':
                  channel.label = '微信';
                  channel.item = '微信';
                  break;
                case 'xx':
                  channel.label = '线下门店';
                  channel.item = '线下门店';
                  break;
                default:
                  break;
              }
              a.push(channel);
            }
          });
        });
        // const filteredArray = platFromList.filter(item => item.percent !== 0);
        setChooseList(a);
      } else {
        setChooseList([]);
      }
      const matchedValues: any = [];
      checkedList.forEach(item => {
        const matchedItem = platFormInfo.find((info: any) => info.value === item);
        if (matchedItem) {
          matchedValues.push(matchedItem);
        }
      });
      setChooseInfo(matchedValues);
    }
  };


  const getChannelList = async () => {
    const { data } = await getChannelListApi()
    setChooseInfo(data)
    let a = [] as any
    data.map((item: any) => {
      item.value = item.channelType.toLowerCase()
      item.label = item.channelName
      item.item = item.channelType
      a.push(item.value)
    })
    setPlatFormInfo(data)
    getPlatFormInfo(a);
    setCheckedList(a)
    console.log(data)
  }
  useEffect(() => {
    getChannelList()
    // getPlatFormInfo();
  }, []);
  useEffect(() => {
    if (checkedList.length > 0) {
      getUserPropertyList(checkedList);
    }
  }, [checkedList]);

  const progressColorMap = new Map([
    ['jd', '#3BA0FF 0%, rgba(59,160,255,0.25) 100%'],
    ['tm', '#36CBCB 0%, rgba(19,194,194,0.25) 100%'],
    ['dy', '#4DCB73 0%, rgba(77,203,115,0.25) 100%'],
    ['yz', '#FADB14 0%, rgba(250,219,20,0) 100%'],
    ['wx', '#364bcb 0%, rgba(250,219,20,0) 100%'],
    ['xx', '#A0D911 0%, rgba(160,217,17,0) 100%'],
  ]);
  const blueIconMap = new Map([
    ['jd', jd_blue],
    ['tm', tm_blue],
    ['dy', dy_blue],
    ['yz', yz_blue],
    ['wx', wx_blue],
    ['xx', offlineShop_blue],
  ]);
  const iconMap = new Map([
    ['jd', jd],
    ['tm', tm],
    ['dy', dy],
    ['yz', yz],
    ['wx', wx],
    ['xx', offlineShop],
  ]);
  const PieChartArea = ({ chartId, chartData }: { chartId: string; chartData?: any }) => {
    const list = [...chartData];
    if (list.length > 1) {
      list.shift();
    }

    return (
      <Col span={7} className={styles.borderStyle}>
        <div className={styles.meanwhileText}>GMV</div>
        <div className={styles.sum}>¥ {getNumberWithComma(chartData[0].gmv, 2)}</div>
        {list.length > 1 && chartData[0].gmv > 0 && <PieChart chartId={chartId} chartData={list} />}
        {list.length > 1 && chartData[0].gmv <= 0 && <Empty style={{ marginTop: '90px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </Col>
    );
  };
  const UserArea = ({ chartData, chartId }: { chartData?: any; chartId?: any }) => {
    return (
      <Col span={4} className={styles.borderStyle}>
        {chartId === 'all' && (
          <>
            <div className={styles.meanwhileText}>交集会员人数</div>
            <div className={styles.sum} style={{ color: 'var(--text-font-color7)', marginBottom: 24 }}>
              {chartData[0].memberNum ? getNumberWithComma(chartData[0].memberNum) : 0}
            </div>
          </>
        )}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ width: '50%' }}>
            <div className={styles.meanwhileText}>{chartId === 'all' && <text>(交集)</text>}已购会员</div>
            <div className={styles.sum}>{getNumberWithComma(chartData[0].payMember)}</div>
          </div>
          <div style={{ width: '50%' }}>
            <div className={styles.meanwhileText}>{chartId === 'all' && <text>(交集)</text>}未购会员</div>
            <div className={styles.sum}>{chartData[0].memberNum ? getNumberWithComma(chartData[0].memberNum - chartData[0].payMember) : 0}</div>{' '}
          </div>
        </div>
        {/* <Divider /> */}
        {/* <div className={styles.meanwhileText} style={{ marginTop: 24 }}>
          已购客户
        </div>
        <div className={styles.sum}>{getNumberWithComma(chartData[0].payCustomer)}</div> */}
      </Col>
    );
  };
  const ProgressBar = ({
    name,
    value,
    customerPrice,
    orderNum,
    width,
    type,
  }: {
    name: string;
    value: string;
    customerPrice?: string;
    orderNum?: string;
    width: number;
    type: string;
  }) => {
    console.log(type);
    const progressColor = progressColorMap.get(value);
    const backgroundStyle = {
      width: `${width}%`,
      background: `linear-gradient(-90deg, ${progressColor}), var(--background1)`,
    };
    return (
      <div style={{ marginBottom: '12px' }}>
        <div className={styles['progress-text']}>
          <div>
            <img src={iconMap.get(value)} alt="" style={{ width: '16px', height: '16px', transform: 'translateY(-2px)' }} />
            <span style={{ marginLeft: '2px' }}>{name}</span>
          </div>

          <div className={styles['num']}>{customerPrice ? getNumberWithComma(Number(customerPrice), 2) : getNumberWithComma(Number(orderNum))}</div>
        </div>
        <div className={styles['progress']}>
          <div className={styles['rate']} style={backgroundStyle} />
          <div
            className={styles['dot-top']}
            style={{ left: `calc( ${width}% - 1px)`, background: `linear-gradient(-90deg, ${progressColor}), var(--background1)` }}
          />
          <div
            className={styles['dot-bottom']}
            style={{ left: `calc( ${width}% - 1px)`, background: `linear-gradient(-90deg, ${progressColor}), var(--background1)` }}
          />
        </div>
      </div>
    );
  };

  const PerCustomerArea = ({ chartData }: { chartData?: any }) => {
    const list = [...chartData];
    if (chartData.length > 1) {
      list.shift();
    }
    return (
      <Col span={6} className={styles.borderStyle}>
        <div className={styles.meanwhileText}>客单价</div>
        <div className={styles.sum}>¥ {getNumberWithComma(chartData[0].customerPrice, 2)}</div>

        {/* 白色卡片 */}
        {list.length > 1 && (
          <div className={styles['card']}>
            {list.map((item: any) => (
              <ProgressBar
                name={item.label}
                value={item.value}
                key={item}
                type={item.item}
                customerPrice={item.customerPrice}
                width={item.customerpercent}
              />
            ))}
          </div>
        )}
      </Col>
    );
  };

  const OrderArea = ({ chartData }: { chartData?: any }) => {
    const list = [...chartData];
    if (list.length > 1) {
      list.shift();
    }
    return (
      <Col span={6} className={styles.borderStyle}>
        <div className={styles.meanwhileText}>订单数</div>
        <div className={styles.sum}>{getNumberWithComma(chartData[0].orderNum)}</div>
        {list.length > 1 && (
          <div className={styles['card']}>
            {list.map((item: any) => (
              <ProgressBar name={item.label} value={item.value} key={item} type={item.item} orderNum={item.orderNum} width={item.orderPercent} />
            ))}
          </div>
        )}
      </Col>
    );
  };

  const showChart = (list: CheckboxValueType[]) => {
    if (list.length === 0) {
      return message.warning('至少选择一个渠道');
    }
    setCheckedList(list);
    const selectedValues: any = platFormInfo.filter((option: any) => list.includes(option.value));
    setChooseInfo(selectedValues);
  };
  return (
    <div>
      <div className={styles.headBox}>
        <div className={styles.titleBox}>
          <ModuleTitle mark="large" title="会员交集一览" />
          <Tooltip
            title={
              <text style={{ whiteSpace: 'pre-wrap' }}>
                1、数据统计截止时间：累计至计至T+1
                <br />
                2、进入统计的会员定义：同时在多个渠道注册会员的用户
                <br />
                3、GMV：统计下单时间为历史累计至昨日且有支付时间的交集会员订单销售额
                <br />
                4、（交集）已购会员：同时在多个渠道注册会员的用户数
                <br />
                5、客单价：GMV/已购会员人数
                <br />
                6、订单数：统计历史累计至昨日交集会员创建的有效订单的父订单数
                <br />
              </text>
            }>
            <QuestionCircleOutlined
              style={{
                color: 'var(--text-font-color4)',
                fontSize: 'var(--text-font5)',
                marginLeft: '6px',
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className={styles.UserChart}>
        <p style={{ paddingLeft: 10 }}>数据统计截止时间：{yesterday}</p>
        <div className={styles.checkboxBgc}>
          <Checkbox.Group options={platFormInfo} value={checkedList} onChange={showChart} />
        </div>
        <div>
          {chooseInfo.length > 1 && (
            <>
              <div className={styles.titleBox} style={{ marginBottom: 24, fontSize: 'var(--text-font7)' }}>
                {chooseInfo.length > 1 && chooseInfo.length !== platFormInfo.length && <ModuleTitle mark="large" title="多渠道" />}
                {chooseInfo.length > 1 && chooseInfo.length === platFormInfo.length && <ModuleTitle mark="large" title="全域" />}
              </div>
              <div className={styles['card-item-bgd']}>
                {chooseInfo.map((item: any, index: number) => (
                  <>
                    <span key={item.value} className={styles.channalName}>
                      <img src={blueIconMap.get(item.value)} alt="" style={{ width: '24px', height: '24px', marginRight: '6px' }} />
                      {item.label}
                    </span>
                    <>
                      {index != chooseInfo.length - 1 && (
                        <span className={styles.meanwhileText} style={{ marginRight: '8px', fontWeight: 'var(--text-font-weight2)' }}>
                          ∩
                        </span>
                      )}
                    </>
                  </>
                ))}

                <div className={styles.meanwhileText} style={{ marginTop: 10 }}>
                  同时在{chooseInfo.length}个平台注册或下单的客户
                </div>
                {chooseList.length <= 0 ? (
                  <Col span={24} className={styles.borderStyle}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </Col>
                ) : (
                  <div className={styles.rowChartList} style={{ minHeight: '390px' }}>
                    <PieChartArea chartId={'all'} chartData={chooseList} />
                    <UserArea chartData={chooseList} chartId={'all'} />
                    <PerCustomerArea chartData={chooseList} />
                    <OrderArea chartData={chooseList} />
                  </div>
                )}
              </div>
            </>
          )}
          {chooseInfo.length >= 1 && (
            <div>
              <div className={styles.titleBox} style={{ marginBottom: 16, fontSize: 'var(--text-font7)' }}>
                <ModuleTitle mark="large" title="单渠道" />
              </div>
              <div style={{ paddingLeft: 10 }}>
                {chooseInfo.map((item: any) => (
                  <div className={styles['card-item-bgd']}>
                    <span className={styles.channalName}>
                      <img src={blueIconMap.get(item.value)} alt="" style={{ width: '24px', height: '24px', marginRight: '6px' }} />
                      {item.label}
                    </span>
                    {!item.gmv ? (
                      <Col span={24} className={styles.borderStyle}>
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </Col>
                    ) : (
                      <div key={item.value} className={styles.rowChartList}>
                        <PieChartArea chartId={item.value} chartData={[{ ...item }]} />
                        <UserArea chartData={[{ ...item }]} />
                        <PerCustomerArea chartData={[{ ...item }]} />
                        <OrderArea chartData={[{ ...item }]} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserChart;
