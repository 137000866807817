// 标签权限map
export const LABEL_KEY = {
  ADD_CATEGORY: 'cdp:label:addCategory',
  DEL_CATEGORY: 'cdp:label:delCategory',
  UPDATE_CATEGORY: 'cdp:label:updateCategory',
  SHOW_LABEL: 'cdp:label:showLabel',
  ADD_LABEL: 'cdp:label:addLabel',
  DEL_LABEL: 'cdp:label:delLabel',
  UPDATE_LABEL: 'cdp:label:updateLabel',
  COPY: 'cdp:label:copyLabel',
  DOWNLOAD: 'cdp:label:downLoadLabel',
};
// 分群权限map
export const CROWD_KEY = {
  GET: 'label:crowd:get',
  ADD: 'label:crowd:add',
  DEL: 'label:crowd:del',
  UPDATA: 'label:crowd:update',
  COPY: 'label:crowd:copy',
  DOWNLOAD: 'label:crowd:upload',
};
// 配置权限map
export const SYS_KEY = {
  //账号管理
  USER_ADD: 'system:user:add',
  USER_REMOVE: 'system:user:remove',
  USER_EDIT: 'system:user:edit',
  USER_QUERY: 'system:user:query',
  USER_PWD: 'system:user:resetPwd',
  //用户组管理
  GROUP_ADD: 'system:userGroup:add',
  GROUP_DEL: 'system:userGroup:delete',
  GROUP_EDIT: 'system:userGroup:edit',
  GROUP_LIST: 'system:userGroup:list',
  GROUP_ADDUSER: 'system:userGroup:addUser',
  //角色管理
  ROLE_ADD: 'system:role:add',
  ROLE_REMOVE: 'system:role:remove',
  ROLE_EDIT: 'system:role:edit',
  ROLE_QUERY: 'system:role:query',
  ROLE_SAVE: 'system:role:save',
};
// 忠诚度权限map
export const LOYALTY_KEY = {
  // 奖励事件
  EVENT_ADD: 'crm:reward:event:add',
  EVENT_DELETE: 'crm:reward:event:delete',
  EVENT_UPDATE: 'crm:reward:event:update',
  EVENT_DETAIL: 'crm:reward:event:detail',
  EVENT_PAGE: 'crm:reward:event:page',
  EVENT_CONFIG_UPDATE: 'crm:reward:config:update',
  EVENT_STOP: 'crm:reward:event:stop',
  // 批量发放积分
  PLAN_SAVE: 'crm:send-point-plan:save',
  PLAN_REVOKE: 'crm:send-point-plan:revoke',
  PLAN_DETAILPAGE: 'crm:send-point-plan:detailPage',
};

// 忠诚度存量会员管理map
export const STOCKMEMBER_KEY = {
  // 下载
  EVENT_DOWNLOAD: 'system:hisMember:stockDownload',
  // 查看
  EVENT_VIEW: 'system:hisMember:stockList',
};
// 客户洞察权限map
export const USER_KEY = {
  //客户列表
  USER_LIST: 'system:customer:list',
  USER_DETAIL: 'system:customer:list',
  USER_POINTS: 'system:customer:changePoints',
  USER_GRADE: 'system:customer:changeGrade',
  USER_GROWTH: 'system:customer:changeGrowth',
  USER_EXPORT: 'system:customer:exportData',
};
// 数据看板map
export const BOARD_KEY = {
  //rfm
  EVENT_ADD: 'cdp:board:rfm:add',
  EVENT_DELETE: 'cdp:board:rfm:delete',
  EVENT_UPDATE: 'cdp:board:rfm:update',
  EVENT_DETAIL: 'cdp:board:rfm:get',
  EVENT_PAGE: 'cdp:board:rfm:list',
  EVENT_RECALCULATE: 'cdp:board:rfm:recalculate',
};
export const INDUSTRY_PRODUCT_KEY = {
  // 行业看板-商品细分
  EVENT_LIST: 'cdpdata:goodsreport:list',
  EVENT_ADD: 'cdpdata:goodsreport:save',
  EVENT_DELETE: 'cdpdata:goodsreport:del',
  EVENT_VIEW: 'cdpdata:goodsreport:view',
  EVENT_EXPORT: 'cdpdata:goodsreport:export',
};
// 群体洞察权限map
export const GROUP_KEY = {
  GROUP_LIST: 'label:portrait:get',
  GROUP_DELETE: 'label:portrait:del',
  GROUP_ADD: 'label:portrait:add',
  GROUP_UPDATE: 'label:portrait:update',
  GROUP_DOWNLOAD: 'label:portrait:upload',
};

// 维度模型导入权限
export const DIMENSION_KEY = {
  DATA_IMPORT: 'user:goodsCollect:updateGoodsByExcel',
  TABLE_EDIT: 'system:spu:edit',
};

// 商品管理权限
export const GOODSMANAGE_KEY = {
  SPU_ADD: 'system:spu:add',
  SPU_EDIT: 'system:spu:edit',
  SPU_DELETE: 'system:spu:delete',
  SPU_LIST: 'user:dimension:list',
};

// 行业看板-总览权限
export const INDUSTRYOVERVIEW_KEY = {
  // 查看
  OVERVIEW_LIST: 'cdpdata:tradeboard:overview',
  // 导出
  OVERVIEW_EXPORT: 'cdpdata:tradeboard:overviewexport',
};

// 行业看板-人群细分权限
export const INDUSTRYCROWD_KEY = {
  // 查看
  OVERVIEW_LIST: 'cdpdata:crowdSubdivs:list',
  // 根据类型导出
  BYCUSTOM_EXPORT: 'cdpdata:tradeboard:customerexport',
  // 根据会员四象限追踪导出
  BYFOURQUADRANTS_EXPORT: 'cdpdata:tradeboard:memberexport',
  // 根据recency回购导出
  BYRECENCY_EXPORT: 'cdpdata:tradeboard:oldcustomerexport',
  // 根据客质拆分导出
  BYQUALITY_EXPORT: 'cdpdata:crowdSubdivs:export',
  // 客质拆分饼图抽屉信息新增
  CROWDSUB_ADD: 'cdpdata:crowdSubdivs:add',
};

// 行业看板-商品销售追踪权限
export const INDUSTRYPRODUCTSALES_KEY = {
  // 查看
  OVERVIEW_LIST: 'cdpdata:salesDetails:list',
  // 销售明细导出
  SALESDETAIL_EXPORT: 'cdpdata:salesDetails:export',
};
