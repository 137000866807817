import React, { useState, useEffect, useRef } from 'react';
import styles from '../DimensionalModel/index.module.scss';
import currentStyles from './index.module.scss';
import ModuleTitle from '@/component/ModuleTitle';
import SearchIconInput from '@/component/Input/SearchIconInput/searchIconInput';
import FilterView from '@/component/FilterView/filterView';
import { LeftOutlined, FilterOutlined, EllipsisOutlined } from '@ant-design/icons';
import { Table, Dropdown, Button, Drawer, message, Form, Space, Upload, Popconfirm, Tooltip } from 'antd';
import { skuGoodsExportApi, updateGoodsByExcelApi } from '@/api/dimensionModel/dimensionModel';
import { getAllCategoryListApi, getDeleteApi, getSpuListApi, getSkuListApi } from '@/api/goodsManage/goodsManage';
import { getProductLineListApi } from '@/api/productLines/productLines';
import type { UploadProps, TableColumnsType } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { InboxOutlined, DownloadOutlined } from '@ant-design/icons';
import CreadGoods from './components/CreadGoods/index';
import { GOODSMANAGE_KEY, DIMENSION_KEY } from '@/router/Permissions';
import SystemButton from '../../../component/Button/SystemButton';
import checkPermission from '../../../crm/assets/public/checkPermission';
const { Dragger } = Upload;
function GoodsManage() {
  let searchParam: any;
  const navigate = useNavigate();
  const location = useLocation();
  // const { checkInfo, detailTableBack } = props;
  const [accountForm] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false); //导入商品抽屉开关
  const [creadOpen, setCreadOpen] = useState(false); //商品详情抽屉开关
  const [drawerStatus, setDrawerStatus] = useState(''); //创建还是编辑
  const [spuIdOne, setSpuIdOne] = useState<any>();
  // 抽屉开关
  const drawerSwitch = () => {
    setCreadOpen(!creadOpen);
    if (creadOpen) {
      getSpuList({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searcParam);
    }
  };
  // 返回方法
  const comeBack = () => {
    // if (detailTableBack) {
    //   detailTableBack();
    // }
    navigate('/files/dataIntegration/dimensionalModel');
  };
  // 返回按钮
  const BackBtn = () => {
    return (
      <div className={styles['BackList_title']} onClick={comeBack}>
        <LeftOutlined />
        <span className={styles['BackList_text']}>返回</span>
      </div>
    );
  };
  // 导入商品上传方法
  const checkUpload = async () => {
    // console.log(files, 'files');
    if (!files) {
      message.error('请上传.xlsx .xls文件');
      return;
    }
    let fd = new FormData();
    fd.append('file', files);
    const res = await updateGoodsByExcelApi(fd);
    if (res) {
      message.success('上传成功');
      setIsModalOpen(false);
    }
  };
  const cancleUpload = () => {
    accountForm.resetFields();
    setIsModalOpen(false);
    // console.log(uploadForm.getFieldValue('fileName'), '=======================================fileName');
  };
  // 上传
  let files: any;
  const uploadProps: UploadProps = {
    name: 'file',
    multiple: true,
    // customRequest: () => {return},
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    onChange(info) {
      // console.log(info, '============================onChange');
    },
    beforeUpload(info: any) {
      // console.log(info, '============================beforeUpload');
      if (info.name.indexOf('.xlsx') < 0 && info.name.indexOf('.xls') < 0) {
        message.error('只能上传.xlsx .xls文件!');
        return;
      }
      const isLt20M = info.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        message.error('文件大小不能超过20MB!');
        return;
      }
      files = info;

      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(file: any) {
      files = null;
      // console.log(file, '====================================remove');
    },
    onDrop(e) {
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };

  // 下载数据
  const downloadData = async () => {
    const params = {
      // taskLocation: location.pathname,
      taskLocation: '档案/数据整合/维度模型',
      moduleName: 'sku商品表',
    };
    const res = await skuGoodsExportApi(params);
    if (res.code == 200) {
      message.success(DOWNLOAD_SUCCESS_TEXT);
    }
  };

  // 获取产品类目
  const [categoryData, setCategoryData] = useState([]);
  const getAllCategoryList = async () => {
    const res = await getAllCategoryListApi();
    res.data.forEach((v: any, i: number) => {
      v.value = v.categoryId;
      v.label = v.name;
      if (v.children) {
        v.children.forEach((a: any, b: number) => {
          a.value = a.categoryId;
          a.label = a.name;
          if (a.children) {
            a.children.forEach((s: any, k: number) => {
              s.value = s.categoryId;
              s.label = s.name;
            });
          }
        });
      }
    });
    setCategoryData(res.data);
  };

  // 获取产品线
  const [productLineList, setProductLineList] = useState([]);
  const getProductLineList = async () => {
    const res: any = await getProductLineListApi();
    res.data.forEach((v: any, i: number) => {
      v.value = v.productLineId;
      v.label = v.productLineName;
    });
    setProductLineList(res.data);
  };

  const filterViewRef: any = useRef(null);

  const searchList: searchItem[] = [
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入商品编码',
      title: 'SPU编码',
      key: 'spuCode',
    },
    {
      searchType: 'text',
      searchComponentType: 'input',
      placeHolder: '请输入商品名称',
      title: '商品名称',
      key: 'spuName',
    },
    {
      searchType: 'text',
      searchComponentType: 'treeSelect',
      placeHolder: '请选择',
      title: '商品类目',
      key: 'category',
      options: categoryData,
    },
    {
      searchType: 'text',
      mode: 'multiple',
      searchComponentType: 'select',
      placeHolder: '请选择',
      title: '产品线',
      key: 'productLine',
      options: productLineList,
    },
    {
      searchType: 'text',
      searchComponentType: 'dateRange',
      placeHolder: '',
      title: '更新时间',
      key: 'startTime-endTime',
    },
  ];

  interface SearcParamType {
    spuNameOrId: string;
    spuCode: string;
    spuName: string;
    category: any;
    productLine: any;
    startTime: string;
    endTime: string;
  }

  const searcParamInt: SearcParamType = {
    spuNameOrId: '',
    spuCode: '',
    spuName: '',
    category: [],
    productLine: [],
    startTime: '',
    endTime: '',
  };

  const [searcParam, setSearcParam] = useState({ ...searcParamInt });

  const filterFinish = (data: any) => {
    // console.log(data);
    setSearcParam(data);
    getSpuList({ pageSize: 10, pageNum: 1 }, data);
  };

  const [scrollY, setScrollY] = useState('');
  const getHeight = (e: string) => {
    // console.log(e);
    setScrollY(e);
  };

  //过滤器
  const [iconColor, setIconColor] = useState('var(--text-font-color4)');
  const handleMouseEnter = () => {
    setIconColor('var(--text-font-color9)');
  };
  const handleMouseLeave = () => {
    setIconColor('var(--text-font-color4)');
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const [tableData, setTableData] = useState([]);

  // 页码信息
  const [pageQuery, setPageQuery] = useState({
    pageSize: 10,
    pageNum: 1,
    total: 0,
  });

  // 表格change事件
  const handleStandardTableChange = (pagination: any, _: any, sorter: any) => {
    getSpuList({ pageSize: pagination.pageSize, pageNum: pagination.current }, searcParam);
  };

  // 获取spu列表数据
  const getSpuList = async (pageQuery: { pageSize: number; pageNum: number }, data: any) => {
    const params: any = {
      ...data,
      category: data.category && JSON.stringify(data.category) != '[]' ? [data.category] : [],
      ...pageQuery,
    };
    const res = await getSpuListApi(params);
    res.data.rows.forEach((v: any, i: number) => {
      v.key = v.spuIdOne;
    });
    setTableData(res.data.rows);
    setExpandedRowKeys([]);
    setPageQuery({
      pageSize: pageQuery.pageSize,
      pageNum: pageQuery.pageNum,
      total: res.data.total,
    });
  };

  // 获取sku列表数据
  const getSkuList = async (id: string, record: any) => {
    const key: any = expandedRowKeys;
    const expandedRowKeysSet: any = new Set(key);
    if (expandedRowKeysSet.has(record.key)) {
      // 收起
      expandedRowKeysSet.delete(record.key);
      setExpandedRowKeys(Array.from(expandedRowKeysSet));
    } else {
      // 展开
      expandedRowKeysSet.add(record.key);
      const params: any = {
        spuIdOne: id,
      };
      const res = await getSkuListApi(params);
      res.data.forEach((s: any, k: number) => {
        s.key = s.skuIdOne;
      });
      const store: any = tableData;
      store.forEach((a: any, b: number) => {
        if (a.spuIdOne === id) {
          a.skuList = res.data;
        }
      });
      setTableData(store);
      setTimeout(() => {
        setExpandedRowKeys(Array.from(expandedRowKeysSet));
      }, 500);
    }
  };

  // spu参数定义
  interface DataType {
    key: React.Key;
    spuIdOne: string;
    spuCode: string;
    brand: string;
    productLine: number;
    updateTime: string;
  }

  // 展开sku参数定义
  interface ExpandedDataType {
    key: React.Key;
    skuPic: string;
    skuIdOne: string;
    name: string;
    color: string;
    size: string;
    barCode: string;
    salePrice: string;
    costPrice: string;
  }

  const toggleRow = (record: any) => {
    getSkuList(record.spuIdOne, record);
  };

  // 关闭提示弹窗
  const hidden = () => {
    setResetConfirm(false);
  };

  const getTableOperationButtonList = (item: any, flag: string) => {
    const store = [];
    if (flag === 'mainRow' && checkPermission(GOODSMANAGE_KEY.SPU_EDIT)) {
      store.push({
        key: 'edit',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={() => {
              setSpuIdOne(item.spuIdOne);
              // console.log(item.spuIdOne);
              setDrawerStatus('edit');
              setCreadOpen(true);
            }}>
            编辑
          </a>
        ),
      });
    }
    if (checkPermission(GOODSMANAGE_KEY.SPU_DELETE)) {
      store.push({
        key: 'delete',
        label: (
          <a
            style={{ color: 'var( --text-font-color9)' }}
            onClick={e => {
              setSelectedTableKey(flag === 'mainRow' ? item.spuIdOne : item.skuIdOne);
              setTableConfirmText();
              setResetConfirm(true);
              e.nativeEvent.stopPropagation();
            }}>
            删除
          </a>
        ),
      });
    }

    return store;
  };

  const [tableConfig, setTableConfig] = useState({} as any);
  const [selectedTableKey, setSelectedTableKey] = useState(null as unknown as number);
  const [resetConfirm, setResetConfirm] = useState(false);

  //设置标特操作提示框文案
  const setTableConfirmText = () => {
    setTableConfig({
      title: '删除该商品，将影响下游数据统计应用，确认删除吗？',
      okText: '确认',
      cancelText: '取消',
      description: '',
    });
  };

  // 确认删除
  const handleConfirmClick = async (item: any, flag: string) => {
    const param = {
      type: flag === 'mainRow' ? '1' : '0',
      deleteId: flag === 'mainRow' ? item.spuIdOne : item.skuIdOne,
    };
    const res: any = await getDeleteApi(param);
    if (res) {
      message.success('删除成功');
      hidden();
      getSpuList({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searcParam);
    } else {
      message.error(res.msg);
    }
  };

  const handleCancelClick = (item: any, type: string) => {
    setResetConfirm(false);
  };

  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i: any) => {
    setCheckUserId(i.id);
    setIconColorPointBack('var(--text-font-color35)');
  };
  const handleMouseLeavePoint = () => {
    setCheckUserId('');
    setIconColorPointBack('');
  };

  // 数字千分位
  const formatNumber = (num: number) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      return num;
    }
  };

  const tabelRow: any = (v: any, i: number) => {
    return (
      <>
        <div className={currentStyles.firstLine}>
          <div style={{ width: '90%', display: 'flex', fontSize: '14px', fontWeight: '600' }}>
            <div className={currentStyles.normalItem}>
              <div style={{ marginBottom: 4 }}>SPU_id_one</div>
              <div>{v.spuIdOne}</div>
            </div>
            <div className={currentStyles.normalItem}>
              <div style={{ marginBottom: 4 }}>SPU编码</div>
              <div>{v.spuCode}</div>
            </div>
            <div className={currentStyles.normalItem}>
              <div style={{ marginBottom: 4 }}>类目</div>
              <div>{v.category || '--'}</div>
            </div>
            <div className={currentStyles.normalItem}>
              <div style={{ marginBottom: 4 }}>品牌</div>
              <div>{v.brand || '--'}</div>
            </div>
            <div className={currentStyles.normalItem}>
              <div style={{ marginBottom: 4 }}>产品线</div>
              <div>{v.productLine || '--'}</div>
            </div>
            <div className={currentStyles.normalItem} style={{ borderRight: 'none' }}>
              <div style={{ marginBottom: 4 }}>更新时间</div>
              <div>{v.updateTime}</div>
            </div>
          </div>
          {(checkPermission(GOODSMANAGE_KEY.SPU_EDIT) || checkPermission(GOODSMANAGE_KEY.SPU_DELETE)) && (
            <div style={{ width: '10%', textAlign: 'center', lineHeight: '48px', borderLeft: 'var(--border3)',fontWeight: '600' }}>操作</div>
          )}
        </div>
        <div style={{ display: 'flex', padding: '16px 0', borderBottom: i === tableData.length - 1 ? 'var(--border3)' : 'unset' }}>
          <div style={{ display: 'flex', width: '90%' }}>
            <div style={{ width: '50%', display: 'flex' }}>
              <div className={currentStyles.picUrl}>
                <img
                  width="64px"
                  height="64px"
                  src={
                    v.picUrl ||
                    'https://rcomnicrmprd.blob.core.chinacloudapi.cn/statictest/statictest/2024/07/31/c2a66ca379754cd18eb5de4b5c115c4f.png'
                  }
                />
              </div>
              <div style={{ flex: 1 }}>
                <div className={currentStyles.spuName}>
                  <div style={{ marginTop: 10, fontSize: '12px', color: 'var(--text-font-color6)', fontWeight: '400', lineHeight: '20px' }}>
                    商品名称：
                  </div>

                  <Tooltip title={v.spuName} placement="topLeft">
                    <span style={{ cursor: 'pointer' }} onClick={() => toggleRow(v)}>
                      {v.spuName}
                    </span>
                  </Tooltip>
                </div>
                <div style={{ display: 'flex', flex: 1, fontSize: '12px', color: 'var(--text-font-color6)', fontWeight: '400', lineHeight: '22px' }}>
                  <div style={{ display: 'flex', flex: 1, paddingRight: 24 }}>
                    <div>场景类目：</div>
                    <Tooltip title={v.scenarioCategory || '--'} placement="topLeft">
                      <div className={currentStyles.itemStyle}>{v.scenarioCategory || '--'}</div>
                    </Tooltip>
                  </div>
                  <div style={{ display: 'flex', flex: 1, paddingRight: 24 }}>
                    <div>适用性别：</div>
                    <Tooltip title={v.gender || '--'} placement="topLeft">
                      <div className={currentStyles.itemStyle}>{v.gender || '--'}</div>
                    </Tooltip>
                  </div>
                  <div style={{ display: 'flex', flex: 1, paddingRight: 24 }}>
                    <div>适用年龄：</div>
                    <Tooltip title={v.age || '--'} placement="topLeft">
                      <div className={currentStyles.itemStyle}>{v.age || '--'}</div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: '50%', paddingLeft: '16px' }}>
              <div style={{ marginTop: 10, fontSize: '12px', color: 'var(--text-font-color6)', fontWeight: '400', lineHeight: '20px' }}>参考价：</div>
              <div className={currentStyles.spuName} style={{ marginTop: 0 }}>
                {formatNumber(v.price) ? `￥${formatNumber(v.price)}` : '--'}
              </div>
            </div>
          </div>
          {(checkPermission(GOODSMANAGE_KEY.SPU_EDIT) || checkPermission(GOODSMANAGE_KEY.SPU_DELETE)) && (
            <div style={{ width: '10%', textAlign: 'center', lineHeight: '64px' }}>
              <Space>
                <Dropdown menu={{ items: getTableOperationButtonList(v, 'mainRow') }} trigger={['click']}>
                  <Popconfirm
                    placement="right"
                    className={styles.popconfirmBox}
                    title={tableConfig.title}
                    okText={tableConfig.okText}
                    cancelText={tableConfig.cancelText}
                    description={tableConfig.description}
                    onConfirm={() => handleConfirmClick(v, 'mainRow')}
                    onCancel={() => handleCancelClick(v, tableConfig.type)}
                    open={selectedTableKey === v.spuIdOne && resetConfirm}
                    onPopupClick={e => {
                      e.stopPropagation();
                    }}
                    okButtonProps={{ loading: false }}>
                    <div
                      onMouseLeave={handleMouseLeavePoint}
                      onMouseEnter={() => handleMouseEnterPoint(v)}
                      className={styles[`line-three-point`]}
                      style={{ backgroundColor: checkUserId == v.spuIdOne ? iconColorPointBack : '' }}>
                      <EllipsisOutlined
                        style={{
                          color: checkUserId == v.spuIdOne ? 'var(--text-font-color33)' : 'var(--text-font-color34)',
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  </Popconfirm>
                </Dropdown>
              </Space>
            </div>
          )}
        </div>
      </>
    );
  };

  // 列表定义
  const columns: TableColumnsType<DataType> = [
    {
      title: '',
      dataIndex: 'spuIdOne',
      key: 'spuIdOne',
      render: (text, record, index) => tabelRow(record, index),
      onCell: (_, index) => ({ colSpan: 6 }),
    },
    {
      title: '',
      dataIndex: 'spuCode',
      key: 'spuCode',
      render: (text, record, index) => tabelRow(record, index),
      onCell: (_, index) => ({ colSpan: 0 }),
    },
    { title: '', dataIndex: 'brand', key: 'brand', render: (text, record, index) => tabelRow(record, index), onCell: (_, index) => ({ colSpan: 0 }) },
    {
      title: '',
      dataIndex: 'productLine',
      key: 'productLine',
      render: (text, record, index) => tabelRow(record, index),
      onCell: (_, index) => ({ colSpan: 0 }),
    },
    {
      title: '',
      dataIndex: 'updateTime',
      key: 'updateTime',
      render: (text, record, index) => tabelRow(record, index),
      onCell: (_, index) => ({ colSpan: 0 }),
    },
    { title: '', dataIndex: '', key: 'x', render: (text, record, index) => tabelRow(record, index), onCell: (_, index) => ({ colSpan: 0 }) },
  ];

  const expandedRowRender = (val: any) => {
    let columns: TableColumnsType<ExpandedDataType> = [
      {
        title: 'SKU图片',
        dataIndex: 'skuPic',
        width: '120px',
        key: 'skuPic',
        render: (_: any) => (
          <div className={currentStyles.picUrl}>
            <img
              width="100%"
              height="100%"
              src={_ || 'https://rcomnicrmprd.blob.core.chinacloudapi.cn/statictest/statictest/2024/07/31/c2a66ca379754cd18eb5de4b5c115c4f.png'}
            />
          </div>
        ),
      },
      { title: 'SKU_ID_ONE', dataIndex: 'skuIdOne', width: '200px', key: 'skuIdOne' },
      {
        title: 'SKU名称',
        dataIndex: 'name',
        width: '400px',
        key: 'name',
        render: (_: any) => (
          <div className={currentStyles.skuName} style={{ width: '370px' }}>
            <Tooltip title={_} placement="topLeft">
              <span style={{ cursor: 'pointer' }}>{_}</span>
            </Tooltip>
          </div>
        ),
      },
      { title: '颜色', dataIndex: 'color', key: 'color' },
      { title: '尺码', dataIndex: 'size', key: 'size' },
      { title: '条形码', dataIndex: 'barCode', key: 'barCode' },
      { title: '销售价', dataIndex: 'salePrice', key: 'salePrice' },
      { title: '成本价', dataIndex: 'costPrice', key: 'costPrice' },
      {
        title: '操作',
        key: 'operation',
        width: '10%',
        align: 'center',
        render: (_: any, record: any) => (
          <Space>
            <Dropdown menu={{ items: getTableOperationButtonList(record, 'expandRow') }} trigger={['click']}>
              <Popconfirm
                placement="right"
                className={styles.popconfirmBox}
                title={tableConfig.title}
                okText={tableConfig.okText}
                cancelText={tableConfig.cancelText}
                description={tableConfig.description}
                onConfirm={() => handleConfirmClick(_, 'expandRow')}
                onCancel={() => handleCancelClick(_, tableConfig.type)}
                open={selectedTableKey === record.skuIdOne && resetConfirm}
                onPopupClick={e => {
                  e.stopPropagation();
                }}
                okButtonProps={{ loading: false }}>
                <div
                  onMouseLeave={handleMouseLeavePoint}
                  onMouseEnter={() => handleMouseEnterPoint(val)}
                  className={styles[`line-three-point`]}
                  style={{ backgroundColor: checkUserId == record.skuIdOne ? iconColorPointBack : '' }}>
                  <EllipsisOutlined
                    style={{
                      color: checkUserId == record.skuIdOne ? 'var(--text-font-color33)' : 'var(--text-font-color34)',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              </Popconfirm>
            </Dropdown>
          </Space>
        ),
      },
    ];
    if (!checkPermission(GOODSMANAGE_KEY.SPU_DELETE)) {
      columns = columns.filter(column => column.key !== 'operation');
    }
    return (
      <div className={currentStyles.expandTable}>
        <Table columns={columns} dataSource={val.skuList} pagination={false} />
        <div style={{ width: '100%', height: '1px', background: '#fff' }}></div>
      </div>
    );
  };

  useEffect(() => {
    getAllCategoryList();
    getProductLineList();
    getSpuList({ pageSize: pageQuery.pageSize, pageNum: pageQuery.pageNum }, searcParam);
    document.addEventListener('click', hidden);

    return () => {
      document.removeEventListener('click', hidden);
    };
  }, []);
  return (
    <div style={{ paddingBottom: 30 }}>
      {/* 商品管理页 */}
      <div className="headBox">
        <div style={{ display: 'flex' }}>
          <BackBtn />
          <div>
            <ModuleTitle mark="large" title={'商品管理'} />
            <div style={{ color: 'var(--text-font-color4)' }}>描述：全渠道商品整合</div>
          </div>
        </div>
        <div className={styles.searchBox}>
          <SearchIconInput
            placeholder="请输入商品名称/商品编码"
            onSearch={(e: string) => {
              searchParam = e;
              const store: SearcParamType = {
                ...searcParamInt,
                spuNameOrId: e,
              };
              filterViewRef.current?.resetData();
              setSearcParam(store);
              getSpuList({ pageSize: 10, pageNum: 1 }, store);
            }}></SearchIconInput>
          <Tooltip placement="bottom" title={'过滤器'}>
            <Button
              onClick={() => filterViewRef.current?.showFilterView()}
              icon={
                <FilterOutlined
                  style={{
                    color: iconColor,
                    fontSize: 'var(--text-font7)',
                  }}
                />
              }
              type="text"
              className="click-element"
              style={{ marginRight: '10px' }}
              onMouseLeave={handleMouseLeave}
              onMouseEnter={handleMouseEnter}></Button>
          </Tooltip>
          {checkPermission(DIMENSION_KEY.DATA_IMPORT) ||
            (checkPermission(GOODSMANAGE_KEY.SPU_ADD) && (
              <span
                style={{
                  width: '0',
                  height: '16px',
                  border: 'var(--border3)',
                  marginRight: 16,
                }}></span>
            ))}
          <SystemButton
            permissions={DIMENSION_KEY.DATA_IMPORT}
            type="primary"
            onClick={() => {
              setIsModalOpen(true);
              accountForm.resetFields();
            }}
            style={{ marginRight: 16 }}
            title={'导入商品'}></SystemButton>

          <SystemButton
            permissions={GOODSMANAGE_KEY.SPU_ADD}
            title="新建商品"
            type="primary"
            onClick={() => {
              drawerSwitch();
              setDrawerStatus('add');
            }}></SystemButton>
        </div>
        {/* 导入商品抽屉 */}
        <Drawer
          className={styles.uploadModal}
          onClose={cancleUpload}
          open={isModalOpen}
          width={400}
          extra={
            <Space>
              <Button onClick={cancleUpload}>取消</Button>
              <Button type="primary" onClick={checkUpload}>
                确定
              </Button>
            </Space>
          }>
          <Form layout="vertical" form={accountForm}>
            <Form.Item label="批量更新" name="fileName" style={{ position: 'relative' }} rules={[{ required: true, message: '' }]}>
              <Button className={styles.downLoad_button} type="link" onClick={downloadData} icon={<DownloadOutlined />}>
                下载模板
              </Button>
              <Dragger {...uploadProps} style={{ height: '168px' }} accept=".xlsx,.xls" maxCount={1}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                <p className="ant-upload-hint">支持扩展名：.xlsx .xls</p>
                <p className="ant-upload-hint">最大支持20MB</p>
              </Dragger>
              <div style={{ marginTop: '10px', textAlign: 'center' }}>上传的商品为覆盖上传，请上传完整商品列表</div>
            </Form.Item>
          </Form>
          {
            // <Form
            // className={styles.uploadForm}
            // labelCol={{ span: 8 }}
            // wrapperCol={{ span: 16 }}
            // initialValues={{ remember: true }}
            // autoComplete="off">
            //   <Form.Item<any>
            //     label="批量更新"
            //     name="username"
            //     rules={[{ required: true, message: 'Please input your username!' }]}
            //   >
            //   <div style={{display: 'flex', justifyContent: 'space-between'}}>
            //       <Dragger {...uploadProps} accept=".xlsx,.xls">
            //         <p className="ant-upload-drag-icon">
            //           <InboxOutlined />
            //         </p>
            //         <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
            //         <p className="ant-upload-text">
            //           支持扩展域名：.xlsx .xls
            //         </p>
            //         <p className="ant-upload-text">
            //           最大支持50MB
            //         </p>
            //       </Dragger>
            //       <Button type="link" size='large' onClick={downloadData}>下载数据</Button>
            //   </div>
            //   <div>上传的商品为覆盖上传，请上传完整商品列表</div>
            //   </Form.Item>
            // </Form>
          }
        </Drawer>
      </div>
      <div style={{ padding: '0 24px' }}>
        <FilterView ref={filterViewRef} searchList={searchList} finish={filterFinish} getHeight={getHeight}></FilterView>
      </div>
      <div className={currentStyles.tableBox} style={{ padding: '0 24px' }}>
        <Table
          columns={columns}
          dataSource={tableData}
          showHeader={false}
          expandedRowKeys={expandedRowKeys}
          expandIcon={() => null}
          expandable={{ expandedRowRender }}
          size="middle"
          // scroll={pageQuery.pageSize > 10 ? {y: scrollY } : {y: scrollY }}
          pagination={{
            pageSize: pageQuery.pageSize,
            total: pageQuery.total,
            showTotal: total => `共${total}条`,
            showSizeChanger: true,
            size: 'default',
          }}
          onChange={handleStandardTableChange}
        />
      </div>
      {/* 商品详情抽屉 */}
      <CreadGoods open={creadOpen} drawer={drawerSwitch} drawerStatus={drawerStatus} spuIdOne={spuIdOne}></CreadGoods>
    </div>
  );
}

export default GoodsManage;
