/**
 * 分组设置抽屉
 */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import {
  Checkbox,
  Drawer,
  Space,
  Button,
  Select,
  InputNumber,
  Popconfirm,
  message,
  Input,
  Form,
} from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  getAutoConfig,
  getCustomConfig,
  addAutoConfig,
  addCustomConfig,
  getNewGroup,
  getMinAndMax,
} from "@/crm/api/industryBoardApi";
import { checkPermissions } from "@/utils/utils";
import { INDUSTRYCROWD_KEY } from "@/router/Permissions";

function SplitDrawer(props: {
  splitDialogVisible: any;
  onCloseSplitDialog: any;
  onConfirmDialog: any;
  typeName: string;
  searchForm: object;
  dataForShowThisTime: any;
  setDataForShowThisTime: any;
}) {
  const { Option } = Select;
  const {
    splitDialogVisible,
    onCloseSplitDialog,
    onConfirmDialog,
    typeName,
    searchForm,
    dataForShowThisTime,
    setDataForShowThisTime,
  } = props;
  const [typeNameValue, setTypeNameValue] = useState("");
  // typeName与给后端的字段对应map
  const typeNameMap = new Map([
    ["笔单价", "bdj"],
    ["笔件数", "bjs"],
    ["购买频次", "gmpc"],
    ["复购周期", "fgzq"],
  ]);

  useEffect(() => {
      setAutoData(groupingNum);
      if (dataForShowThisTime.length === 0) {
        queryNewGrouping();
      } else {
        setGroupingMethod(dataForShowThisTime.type === "auto" ? 0 : 1);
        if (dataForShowThisTime.type === "auto") {
          setAutoData(dataForShowThisTime.autoDTO);
          setKeepStatusAuto(dataForShowThisTime.saveStatus);
        }
        if (dataForShowThisTime.type === "custom") {
          setCustomData(dataForShowThisTime.groupNum);
          setKeepStatusCustom(dataForShowThisTime.saveStatus);
        }
      }
  }, [dataForShowThisTime, searchForm]);

  useEffect(() => {
    setTypeNameValue(typeNameMap.get(typeName));
  }, [typeName]);
  const [keepStatusAuto, setKeepStatusAuto] = useState(false); // 是否保留本次更改-自动
  const [keepStatusCustom, setKeepStatusCustom] = useState(false); // 是否保留本次更改-自定义
  const [groupingMethod, setGroupingMethod] = useState(0); // 所选分组方式
  // 自动参数
  const initGroupsNum = 5; // 初始化分组数
  const [groupingNum, setGroupingNum] = useState(initGroupsNum); // 所选分组组数
  const [maxSplit, setMaxSplit] = useState(null); // 最大分组数
  const [minSplit, setMinSplit] = useState(null); // 最小分组数
  const [warningText, setWarningText] = useState(""); // 提示信息
  // 自定义参数
  const [otherGroupChecked, setOtherGroupChecked] = useState(false); // 是否勾选未分组类型
  const maxGroupsNum = 10; // 最大分组数（如果勾选了未分组类型则包含未分组类型）
  const [noGroupToName, setNoGroupToName] = useState("其他"); // 未分组类型名称
  const [groupingList, setGroupingList] = useState(
    Array.from({ length: 5 }, (_, index) => ({
      value: [null, null],
      valueType: ["lessEqual", "lessEqual"],
      label: "",
      errorMsg: ["", "", ""],
    }))
  ); // 分组列表,默认5个

  const inputSelect = (
    <Select defaultValue="lessEqual" style={{ width: 60 }}>
      <Option value="lessEqual">≤</Option>
      <Option value="less">{`<`}</Option>
    </Select>
  );

  const groupingOption = [
    { label: "自动", value: 0 },
    { label: "自定义", value: 1 },
  ];
  const groupingNumOption = Array.from({ length: 10 }, (_, index) => ({
    value: index + 1,
    label: index + 1,
  }));

  // 恢复默认数据
  const toDefault = () => {
    setKeepStatusAuto(false);
    setKeepStatusCustom(false);
    setGroupingNum(5);
    setMinSplit(null);
    setMaxSplit(null);
  };

  // 选择分组方式
  const onChangeGroupingMethod = (value: number) => {
    setGroupingMethod(value);
  };
  // 选择分组组数
  const onChangeGroupingNum = (value: number) => {
    setGroupingNum(value);
  };

  // 添加分组
  const addSplit = () => {
    const newGroupingList = [
      ...groupingList,
      {
        value: [null, null],
        valueType: ["lessEqual", "lessEqual"],
        label: "",
        errorMsg: ["", "", ""],
      },
    ];
    setGroupingList(newGroupingList);
  };
  // 删除分组
  const deleteGroupListByIndex = (index: number) => {
    const copyGroupingList = [...groupingList];
    copyGroupingList.splice(index, 1);
    setGroupingList([...copyGroupingList]);
  };

  // 确认
  const confirm = () => {
    if (!groupingNum) {
      message.warning("请选择分组组数");
      return;
    }
    // 自动分组
    if (groupingMethod === 0) {
      // 判断是否勾选保留本次更改，如果未保留，则暂时传此次数据给父组件，如果勾选了，则保存当前数据
      if (keepStatusAuto) {
        toAddAutoConfig({ type: typeNameValue, groupNum: groupingNum });
      }
      setDataForShowThisTime({
        type: "auto",
        autoDTO: { minNum: minSplit, maxNum: maxSplit, groupNum: groupingNum },
        saveStatus: keepStatusAuto,
      });
      // 传饼图的参数
      onConfirmDialog("auto", {
        numberOfGroups: groupingNum,
        min: minSplit,
        max: maxSplit,
      });
    }
    // 自定义分组
    else if (groupingMethod === 1) {
      // 校验groupingList的value和label 是否存在空
      const checkDataFlag = groupingList.some((item) => {
        return (
          item.value.some((valueItem) => {
            return valueItem === null;
          }) || item.label === ""
        );
      });
      // 校验groupingList的errorMsg 是否存在空
      const checkErrMsgFlag = groupingList.some((item) => {
        return item.errorMsg.some((valueItem) => {
          return valueItem !== "";
        });
      });
      // console.log(checkDataFlag, checkErrMsgFlag, '==')
      if (checkDataFlag || checkErrMsgFlag) {
        message.error("请完善分组信息");
        return false;
      }
      const newGroupingList = groupingList.map((item, index) => {
        return {
          type: typeNameValue,
          name: item.label,
          startNum: item.value[0],
          endNum: item.value[1],
          startSymbol: item.valueType[0],
          endSymbol: item.valueType[1],
          ascCode: index,
        };
      });
      // 其他
      if (otherGroupChecked) {
        const otherObj = {
          type: typeNameValue,
          name: noGroupToName ? noGroupToName : "其他",
          startNum: "other",
          endNum: "",
          startSymbol: "",
          endSymbol: "",
          ascCode: newGroupingList.length,
        };
        newGroupingList.push(otherObj);
      }
      // 判断是否勾选保留本次更改
      toAddCustomConfig(newGroupingList);
      // 传饼图的参数
      setDataForShowThisTime({
        type: "custom",
        groupNum: newGroupingList,
        saveStatus: keepStatusCustom,
      });
      onConfirmDialog("custom", newGroupingList);
    }
  };

  const checkCrossGrouping = () => {
    const newValue1 = [...groupingList];
    const newValue2 = [...groupingList];
    const finalList = [...groupingList];

    newValue1.forEach((item, index) => {
      const currentData = item.value;
      if (currentData[0] && currentData[1]) {
        // 请保证前面的数值≤后面的数值
        if (currentData[0] > currentData[1]) {
          finalList[index].errorMsg[1] = "请保证前面的数值≤后面的数值";
        } else if (
          currentData[0] &&
          currentData[1] &&
          currentData[0] === currentData[1] &&
          item.valueType[0] === "less" &&
          item.valueType[1] === "less"
        ) {
          finalList[index].errorMsg[1] = "前后值相等，请将操作符更改为“≤”";
        } else {
          // 对比第一个值是否在其他组之间
          const item0ErrorCross = newValue2
            .filter((item2, index2) => index2 !== index)
            .some((itemChild, indexChild) => {
              const compareData = itemChild.value;
              return (
                currentData[0] > compareData[0] &&
                currentData[0] < compareData[1]
              );
            });

          finalList[index].errorMsg[1] = item0ErrorCross
            ? "数值范围重复，请重新输入"
            : "";

          // 对比第二个值是否在其他组之间
          const item1ErrorCross = newValue2
            .filter((item2, index2) => index2 !== index)
            .some((itemChild, indexChild) => {
              const compareData = itemChild.value;
              return (
                currentData[1] > compareData[0] &&
                currentData[1] < compareData[1]
              );
            });

          finalList[index].errorMsg[2] = item1ErrorCross
            ? "数值范围重复，请重新输入"
            : "";
        }
      }
    });

    setGroupingList(finalList);
  };

  // blur事件
  const numberOnBlur = (item: any, dataIndex, index: number) => {
    checkCrossGrouping();
  };

  // 新增自动分组
  const toAddAutoConfig = async (params) => {
    let res = await addAutoConfig(params);
    if (res?.code === 200) {
      // message.success(res.msg);
    }
  };

  // 新增自定义分组
  const toAddCustomConfig = async (params) => {
    let res = await addCustomConfig(params);
    if (res?.code === 200) {
    }
  };

  // 查询自动分组数据
  const queryAutoGrouping = async () => {
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType.split(","),
      shopIds: searchForm?.shopId.split(","),
      indexId: typeNameMap.get(typeName),
    };
    let res = await getAutoConfig(params);
    if (res?.code === 200) {
      setAutoData(res.data);
      // console.log("查询自动分组数据结果", res);
    }
  };
  // 查询自定义分组数据
  const queryCustomConfig = async () => {
    const params = {
      type: typeNameMap.get(typeName),
      config: null,
    };
    let res = await getCustomConfig(params);
    if (res?.code === 200) {
      if (res.data.length > 0) {
        setCustomData(res.data);
      }
    }
  };

  const setAutoData = async (data) => {
    setGroupingNum(data?.groupNum);
    setWarningText("");
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType.split(","),
      shopIds: searchForm?.shopId.split(","),
      indexId: typeNameMap.get(typeName),
    };
    let res = await getMinAndMax(params);
    if (res?.code === 200) {
      setMaxSplit(res.data?.maxNum);
      setMinSplit(res.data?.minNum);
    }
  };
  const setCustomData = (data) => {
    // 成组的数据
    const groupData = data.filter((item) => item.startNum !== "other");
    const arr = groupData.map((item) => {
      return {
        label: item.name,
        value: [item.startNum, item.endNum],
        valueType: [item.startSymbol, item.endSymbol],
        errorMsg: ["", "", ""],
      };
    });
    // 其他
    const otherItem = data.find((item) => item.startNum === "other");
    if (otherItem) {
      setOtherGroupChecked(true);
      setNoGroupToName(otherItem.name);
    }
    setGroupingList(arr);
  };

  const queryNewGrouping = async () => {
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.split(","),
      shopIds: searchForm?.shopId?.split(","),
      indexId: typeNameMap.get(typeName),
    };
    let res = await getNewGroup(params);
    if (res?.code === 200) {
      setGroupingMethod(res.data?.type === "auto" ? 0 : 1);
      if (res.data?.type === "auto") {
        setKeepStatusAuto(true);
        // 根据当前查询条件、客质拆分类型、查询上次是否存在保留的区间分组设置
        queryAutoGrouping();
      } else {
        setKeepStatusCustom(true);
        queryCustomConfig();
      }
    }
  };

  return (
    <Drawer
      width={groupingMethod === 0 ? 400 : 640}
      title="区间分组设置"
      onClose={onCloseSplitDialog}
      open={splitDialogVisible}
      extra={
        <Space>
          <Button onClick={onCloseSplitDialog}>取消</Button>
            <Button
              type="primary"
              onClick={() => {
                confirm();
              }}
              disabled={groupingMethod === 0 && warningText}
            >
              确认
            </Button>
        </Space>
      }
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            marginBottom: 12,
          }}
        >
          <span>
            {groupingMethod === 0 && (
              <Checkbox
                checked={keepStatusAuto}
                onChange={() => {
                  setKeepStatusAuto(!keepStatusAuto);
                }}
                style={{ marginRight: 4 }}
              />
            )}
            {groupingMethod === 1 && (
              <Checkbox
                checked={keepStatusCustom}
                onChange={() => {
                  setKeepStatusCustom(!keepStatusCustom);
                }}
                style={{ marginRight: 4 }}
              />
            )}
            保留本次更改至下次打开
          </span>
          <Button
            style={{
              visibility: `${groupingMethod === 0 ? "visible" : "hidden"}`,
            }}
            onClick={toDefault}
          >
            恢复默认
          </Button>
        </div>
        <div
          style={{
            display: `${groupingMethod === 1 ? "flex" : "block"}`,
            lineHeight: "32px",
          }}
        >
          <div style={{ marginBottom: 12 }}>分组方式选择：</div>
          <Select
            options={groupingOption}
            value={groupingMethod}
            style={{ width: 352, marginBottom: 24 }}
            onChange={onChangeGroupingMethod}
          />
        </div>
        {groupingMethod === 0 && (
          <>
            <div style={{ marginBottom: 12 }}>分组组数</div>
            <Select
              options={groupingNumOption}
              value={groupingNum}
              style={{ width: 352, marginBottom: 24 }}
              onChange={onChangeGroupingNum}
            />
            <div style={{ marginBottom: 12 }}>
              区间最大值：{maxSplit ?? "-"}
            </div>
            <div style={{ marginBottom: 12 }}>
              区间最小值：{minSplit ?? "-"}
            </div>
          </>
        )}
        {groupingMethod === 1 && (
          <>
            <div
              style={{
                color: "rgba(0, 0, 0, 0.88)",
                fontSize: 16,
                fontWeight: 500,
                marginBottom: 24,
              }}
            >
              区间分组设置
            </div>
            <Button
              onClick={addSplit}
              style={{ marginBottom: 24 }}
              disabled={
                (otherGroupChecked && groupingList.length >= 9) ||
                groupingList.length >= 10
              }
            >
              <PlusOutlined />
              添加分组
            </Button>
            {groupingList.map((item, index) => {
              return (
                <div>
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Input
                      placeholder="请输入分组名称"
                      maxLength={30}
                      showCount
                      style={{ width: 130, marginRight: 6 }}
                      value={item.label}
                      onChange={(e) => {
                        const newValue = [...groupingList];
                        newValue[index].label = e.target.value;
                        setGroupingList(newValue);
                      }}
                      onBlur={() => {
                        if (item.label === "") {
                          const newValue = [...groupingList];
                          newValue[index].errorMsg[0] = "分组名称不能为空";
                          setGroupingList(newValue);
                        } else {
                          const newValue = [...groupingList];
                          newValue[index].errorMsg[0] = "";
                          setGroupingList(newValue);
                        }
                      }}
                    />
                    ，
                    <InputNumber
                      placeholder="请输入数值"
                      controls={false}
                      value={item?.value[0]}
                      onChange={(v) => {
                        const newValue = [...groupingList];
                        newValue[index].value[0] = Number(v);
                        numberOnBlur(newValue[index], index, 0);
                      }}
                      onBlur={() => {
                        numberOnBlur(item, index, 0);
                      }}
                      addonAfter={
                        <Select
                          defaultValue="lessEqual"
                          style={{ width: 60 }}
                          value={item.valueType[0]}
                          onChange={(v) => {
                            const newValue = [...groupingList];
                            newValue[index].valueType[0] = v;
                            // setGroupingList(newValue);
                            numberOnBlur(newValue[index], index, 0);
                          }}
                        >
                          <Option value="lessEqual">≤</Option>
                          <Option value="less">{`<`}</Option>
                        </Select>
                      }
                      style={{ width: 163, margin: "0 6px" }}
                    />
                    {typeName}
                    <InputNumber
                      placeholder="请输入数值"
                      controls={false}
                      value={item?.value[1]}
                      onChange={(v) => {
                        const newValue = [...groupingList];
                        newValue[index].value[1] = Number(v);
                        numberOnBlur(newValue[index], index, 1);
                      }}
                      onBlur={() => {
                        numberOnBlur(item, index, 1);
                      }}
                      addonBefore={
                        <Select
                          defaultValue="lessEqual"
                          style={{ width: 60 }}
                          value={item.valueType[1]}
                          onChange={(v) => {
                            const newValue = [...groupingList];
                            newValue[index].valueType[1] = v;
                            numberOnBlur(newValue[index], index, 1);
                          }}
                        >
                          <Option value="lessEqual">≤</Option>
                          <Option value="less">{`<`}</Option>
                        </Select>
                      }
                      style={{ width: 163, margin: "0 6px" }}
                    />
                    <DeleteOutlined
                      onClick={() => {
                        groupingList.length !== 1 &&
                          deleteGroupListByIndex(index);
                      }}
                      style={{
                        color: `${
                          groupingList.length === 1
                            ? "rgba(0, 0, 0, 0.58)"
                            : "rgba(0, 0, 0, 0.88)"
                        }`,
                        cursor: `${
                          groupingList.length !== 1 ? "pointer" : "auto"
                        }`,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: "red",
                      height: 24,
                      padding: "4px 0",
                      display: "flex",
                    }}
                  >
                    <div style={{ width: 170 }}>{item.errorMsg[0]}</div>
                    <div style={{ width: 234 }}>{item.errorMsg[1]}</div>
                    <div style={{ width: 170 }}>{item.errorMsg[2]}</div>
                  </div>
                </div>
              );
            })}
            <span>
              <Checkbox
                checked={otherGroupChecked}
                onChange={() => {
                  setOtherGroupChecked(!otherGroupChecked);
                }}
                style={{ marginRight: 4 }}
                disabled={groupingList.length >= 10}
              />
              未分组的值分组到
              <Input
                value={noGroupToName}
                maxLength={10}
                showCount
                onChange={(e) => setNoGroupToName(e.target.value)}
                style={{ width: 180, margin: "24px 0 0 16px" }}
              />
            </span>
          </>
        )}
      </div>
    </Drawer>
  );
}

export default SplitDrawer;
