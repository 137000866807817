import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Form, Input, Row, Col, Select, Space, Button, Flex, Table, Tag, Dropdown, Modal } from 'antd';
import { Table, Tag, Dropdown, Modal, message } from 'antd';
import type { TableProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
// import SearchIconInput from '@/component/Input/SearchIconInput/searchIconInput';
// import Filter from '@/crm/component/Filter';
import ModuleTitle from '@/component/ModuleTitle';
import SystemButton from '@/component/Button/SystemButton';
import { INDUSTRY_PRODUCT_KEY } from '@/router/Permissions';
import checkPermission from '@/crm/assets/public/checkPermission';
import { getToolTipText } from '@/crm/utils';
import IndustryApi from '@/crm/api/dataBoardApi/industry';
import dayjs from 'dayjs';
import styles from './index.module.scss';

const { confirm } = Modal;

// type FieldType = {
//   analysisName: string;
//   status: string[];
// };

const statusOptions = [
  { label: '计算中', value: '计算中', color: 'processing' },
  { label: '计算成功', value: '计算成功', color: 'success' },
  { label: '计算失败', value: '计算失败', color: 'error' },
];

// const defaultSearchInfo: FieldType = {
//   analysisName: '',
//   status: ['计算中', '计算成功', '计算失败'],
// };

type TableData = {
  key: string;
  id: string;
  analysisName: string;
  status: string;
  createTime: string;
  updateTime: string;
};

const ProductSegmentation: React.FC = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  // const [form] = Form.useForm();
  // const [selectedKeys, setSelectedKeys] = useState(['0', '1']);
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i: any) => {
    setCheckUserId(i.id)
    setIconColorPointBack('var(--text-font-color35)');
  };
  const handleMouseLeavePoint = () => {
    setCheckUserId('')
    setIconColorPointBack('');
  };
  const columns: TableProps<TableData>['columns'] = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80,
      // 根据 pageInfo.current 和 pageInfo.size 计算序号
      render: (value, record, index) => (pageInfo.current - 1) * pageInfo.size + index + 1,
    },
    {
      title: '报告名称',
      dataIndex: 'analysisName',
      key: 'analysisName',
      width: 300,
      render: (text, record) => {
        if (!checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_VIEW)) {
          return <span
            style={{ color: record.status !== '计算成功' ? 'rgba(0, 0, 0, 0.25)' : '#1677ff' }}
          >
            {getToolTipText(text)}
          </span>
        }
        return (
          <span
            style={{ cursor: (record.status !== '计算成功') ? 'not-allowed' : 'pointer', color: record.status !== '计算成功' ? 'rgba(0, 0, 0, 0.25)' : '#1677ff' }}
            onClick={(e) => {
              if (record.status !== '计算成功') {
                e.stopPropagation();
                return;
              }
              navigate('/DataBoard/Industry/ProductSegmentation/details', {
                state: {
                  id: record.id,
                  createTime: record.createTime,
                },
              });
            }}
          >
            {getToolTipText(text)}
          </span>
        );
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: text => (
        <Tag color={statusOptions.find(item => item.value === text)?.color}>
          {statusOptions.find(item => item.value === text)?.label}
        </Tag>
      ),
    },
    {
      title: '创建人',
      dataIndex: 'createBy',
      key: 'createBy',
      width: 160,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 200,
    },
    {
      title: '报告最近更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 200,
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      width: '88px',
      render: (_, record) => {
        return <Dropdown
          menu={{
            items: [
              {
                key: 'download',
                disabled: record.status !== '计算成功' || !checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_EXPORT),
                label: (
                  <span onClick={() => onDownload(record)}>
                    导出
                  </span>
                ),
              },
              {
                key: 'delete',
                disabled: !checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_DELETE),
                label: (
                  <span style={{ width: '100%' }} onClick={() => onDelete(record)}>
                    删除
                  </span>
                ),
              },
            ],
          }}
          trigger={['click']}
        >
          <div
            onMouseLeave={handleMouseLeavePoint}
            onMouseEnter={() => handleMouseEnterPoint(record)}
            className={styles[`line-three-point`]}
            style={{ backgroundColor: checkUserId === record.id ? iconColorPointBack : '' }}
          >
            <EllipsisOutlined
              style={{
                color: checkUserId === record.id ? 'var(--text-font-color33)' : 'var(--text-font-color34)',
                cursor: 'pointer'
              }}
            />
          </div>
        </Dropdown>
      },
    },
  ];
  if (!checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_DELETE) && !checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_EXPORT)) {
    columns.pop();
  }
  // const [listParams, setListParams] = useState(defaultSearchInfo);
  const [dataSource, setDataSource] = useState<TableData[]>([]);
  const [pageInfo, setPageInfo] = useState({
    current: 1,
    size: 10,
    total: 0,
  });

  const getProductSegmentationList = async (params: { pageSize: number; pageNum: number }) => {
    IndustryApi.getProductSegmentationList(params).then((res: any) => {
      console.log(res);
      setDataSource(res.data.records);
      setPageInfo({
        current: res.data.current,
        size: res.data.size,
        total: res.data.total,
      });
    });
  };

  const onDownload = (record: TableData) => {
    if (record.status !== '计算成功' || !checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_EXPORT)) {
      return;
    }
    IndustryApi.exportProductSegmentationExcel({
      id: record.id,
      moduleName: `行业看板-商品细分-${record.analysisName}-${dayjs().format("YYYYMMDDHHmmss")}`, //模块名称
      taskLocation: "行业看板/商品细分", //页面位置
    }).then((res: any) => {
      if (res.code === 200) {
        messageApi.success("导出中，请到下载中心查看进度");
      }
    });
  };
  const onDelete = (record: TableData) => {
    if (!checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_DELETE)) {
      return;
    }
    confirm({
      title: '确定要删除该分析报告吗？',
      okText: '确认删除',
      onOk() {
        IndustryApi.deleteProductSegmentation({ id: record.id }).then(() => {
          console.log(pageInfo);

          getProductSegmentationList({
            pageSize: pageInfo.size,
            pageNum: 1,
          });
        });
      },
    });
  };

  const pageChange: TableProps<TableData>['onChange'] = (page) => {
    getProductSegmentationList({
      pageSize: page.pageSize || 10,
      pageNum: page.current || 1,
    });
  };
  useEffect(() => {
    if (!checkPermission(INDUSTRY_PRODUCT_KEY.EVENT_LIST)) {
      return;
    }
    getProductSegmentationList({
      pageSize: pageInfo.size,
      pageNum: pageInfo.current,
    });
  }, []);
  return (
    <div className={styles.ProductSegmentation}>
      {contextHolder}
      <div className={styles.header}>
        <div className={styles.firstRow}>
          <div className={styles.titleText}>
            <ModuleTitle mark="large" title="商品细分" />
          </div>
          <div className={styles.searchBox}>
            {/* <SearchIconInput
              placeholder="请输入分析报告名称"
              onSearch={(e: string) => {
                setListParams({
                  ...listParams,
                  analysisName: e,
                });
                // getInsightAnalysis({
                //   analysisName: e,
                // });
              }}
            ></SearchIconInput>
            <Filter hoverTitle="过滤器" nodeID="filter-main" style={{ marginRight: 10 }}>
              <Form
                form={form}
                onFinish={() => {
                  // getDimensionData();
                }}
                initialValues={defaultSearchInfo}
              >
                <Row gutter={16} wrap style={{ marginTop: 24 }}>
                  <Col span={12}>
                    <Form.Item<FieldType> name="analysisName" label="报告名称">
                      <Input placeholder="请输入分析报告名称" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item<FieldType> style={{ flex: 1 }} name="status" label="报表状态">
                      <Select placeholder="请选择报表分析状态" options={statusOptions} mode="multiple" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Flex justify="flex-end">
                      <Form.Item>
                        <Space size="small">
                          <Button htmlType="reset">重置</Button>
                          <Button type="primary" htmlType="submit">
                            查询
                          </Button>
                        </Space>
                      </Form.Item>
                    </Flex>
                  </Col>
                </Row>
              </Form>
            </Filter>
            <span
              style={{
                width: '0',
                height: '16px',
                border: 'var(--border3)',
                marginRight: 16,
              }}
            ></span> */}
            <SystemButton
              title="新建分析"
              type="primary"
              onClick={() => navigate('/DataBoard/Industry/ProductSegmentation/create', {})}
              permissions={INDUSTRY_PRODUCT_KEY.EVENT_ADD}
            />
          </div>
        </div>
        {/* <div id="filter-main"></div> */}
      </div>
      <div className={styles.main}>
        <Table
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            total: pageInfo.total,
            showTotal: total => `共${total}条`,
            pageSize: pageInfo.size,
            showSizeChanger: true,
          }}
          onChange={pageChange}
        />
      </div>
    </div>
  );
};

export default ProductSegmentation;
