import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  Space,
  Modal,
  message,
  Dropdown,
  Popconfirm,
  Tooltip,
} from "antd";
import type { TableProps } from "antd";
import dayjs from "dayjs";
import { EllipsisOutlined } from "@ant-design/icons";
import FilterView from "@/crm/component/FilterView";
import FilterIcon from "@/component/FilterIcon/filterIcon";
import ModuleTitle from "@/component/ModuleTitle";
import SearchIconInput from "@/component/Input/SearchIconInput/searchIconInput";
import {
  getInsightAnalysisList,
  deleteInsightAnalysis,
  recalculateRFM,
  reportExport
} from "@/crm/api/dataBoardApi";
import SystemButton from "@/component/Button/SystemButton";
import { BOARD_KEY } from "@/router/Permissions";
import { checkPermissions } from "@/utils/utils";

function RfmAnalysis() {
  const { confirm } = Modal;
  const navigate = useNavigate();
  const statusOptions = [
    // 状态查询下拉项
    // { label: "全部", value: "" },
    { label: "计算失败", value: "2" },
    { label: "计算中", value: "0" },
    { label: "计算成功", value: "1" },
  ];
  const [tableData, setTableData] = useState<DataType[]>([]);
  const filterViewRef = useRef<any>(null);
  const [pageInfo, setPageInfo] = useState({
    current: 1,
    size: 10,
    total: 0,
  });
  const defaultSearchInfo = {
    // 查询参数
    analysisName: "",
    analysisType: "0", // 分析类型 0全部,1RFM分析
    analysisStatus: ["0", "1", "2"], // 分析状态 0计算中，1计算成功，2计算失败
    createMethod: "0", // 创建方式 0全部,1指定条件创建
    createdAt: "",
    updatedAt: "",
    subReportUpdateTime: "",
    endTime: "",
    orderByCreateTime: "2", // 创建时间排序方式 1 正序(ASC),2 倒序(DESC)
    orderByUpdateTime: "", // 最近更新时间排序方式 1 正序(ASC),2 倒序(DESC)
    orderBySubUpdateTime: "", // 子报告最后更新时间排序 1 正序(ASC),2 倒序(DESC)
  };
  const [listParams, setListParams] = useState(defaultSearchInfo);
  const [tableConfig, setTableConfig] = useState({} as any);
  const [selectedTableKey, setSelectedTableKey] = useState(
    null as unknown as number
  );
  // 搜索
  const searchList: searchItem[] = [
    {
      searchType: "string",
      searchComponentType: "input",
      placeHolder: "请输入分析报告名称",
      title: "分析报告名称",
      key: "analysisName",
    },
    {
      searchType: "string",
      searchComponentType: "select",
      placeHolder: "请选择状态",
      title: "状态",
      key: "analysisStatus",
      options: statusOptions,
      mode: "multiple",
      defaultValue: ["0", "1", "2"],
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      title: "创建时间",
      key: "createdAt",
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      title: "最近编辑时间",
      key: "updatedAt",
    },
    {
      searchType: "text",
      searchComponentType: "dateRange",
      title: "最近更新时间",
      key: "subReportUpdateTime",
    },
  ];
  const sortValue: { orderByColumn: string; isAsc: string } = {
    orderByColumn: "",
    isAsc: "",
  };

  interface DataType {
    id: any;
    key: string;
    analysisName: string;
    analysisStatus: string;
    createdAt: string;
    updatedAt: string;
    subReportUpdateTime: string;
  }
  const columns: TableProps<DataType>["columns"] = [
    {
      title: "序号",
      key: "index",
      width: "88px",
      render: (value, record, index) => (
        <span>{(pageInfo.current - 1) * pageInfo.size + index + 1}</span>
      ),
    },
    {
      title: "分析报告名称",
      dataIndex: "analysisName",
      key: "analysisName",
      render: (value, record, index) => {
        return (
          <Button
            type="link"
            disabled={record.analysisStatus !== "1"}
            onClick={() =>
              navigate("/DataBoard/Basis/RFMAnalysis/details", {
                state: {
                  id: record.id,
                },
              })
            }
          >
            {value.length > 40 ? (
              <Tooltip overlayInnerStyle={{ width: "400px" }} title={value}>
                {value.substring(0, 40)}
                {value.length > 40 && <span>......</span>}
              </Tooltip>
            ) : (
              value
            )}
          </Button>
        );
      },
    },
    {
      title: "状态",
      dataIndex: "analysisStatus",
      key: "analysisStatus",
      width: "200px",
      render: (value, record, index) => (
        <div
          className={`${
            value === "0"
              ? styles.pending
              : value === "1"
              ? styles.complete
              : styles.fail
          }`}
        >
          <span>
            {statusOptions.filter((item) => item.value === value)[0].label}
          </span>
        </div>
      ),
    },
    {
      title: "创建时间",
      dataIndex: "createdAt",
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: true,
      width: "200px",
    },
    {
      title: "最近编辑时间",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      width: "200px",
    },
    {
      title: "报告最后更新时间",
      dataIndex: "subReportUpdateTime",
      key: "subReportUpdateTime",
      sorter: true,
      width: "200px",
      render: (value, record, index) => <span>{value ? value : "-"}</span>,
    },
    {
      title: "操作",
      key: "action",
      align: "center",
      fixed: "right",
      width: "88px",
      render: (_, record) => (
        <Space>
          <Dropdown
            menu={{ items: getTableOperationButtonList(record) }}
            trigger={["click"]}
          >
            <Popconfirm
              placement="right"
              title={tableConfig.title}
              okText={tableConfig.okText}
              cancelText={tableConfig.cancelText}
              description={tableConfig.description}
              onConfirm={() => handleConfirmClick(record, tableConfig.type)}
              onCancel={() => handleCancelClick(record, tableConfig.type)}
              open={selectedTableKey === 1}
              onPopupClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div 
                    onMouseLeave={handleMouseLeavePoint}
                    onMouseEnter={()=>handleMouseEnterPoint(record)}
                    className={styles[`line-three-point`]}
                    style={{backgroundColor:checkUserId == record.id ? iconColorPointBack:''}}
                >
                    <EllipsisOutlined 
                        style={{
                            color: checkUserId == record.id ?'var(--text-font-color33)':'var(--text-font-color34)',
                            cursor:'pointer'
                        }}
                    />
              </div>
            </Popconfirm>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const handleConfirmClick = (item: DataType, type: string) => {};

  const handleCancelClick = (item: DataType, type: string) => {};

  const getTableOperationButtonList = (item: DataType) => {
    const store = [];
    // 除了计算失败都可以展示下载按钮
    if(item.analysisStatus !== "2"){
      store.push({
        key: "upload",
        label: (
          <a
            type="link"
            className={`${
              item.analysisStatus === "0"
                ? styles.diasabledHref
                : styles.normalHref
            }`}
            onClick={() => toUpload(item)}
          >
            下载
          </a>
        ),
      });
    }
    
    if (checkPermissions(BOARD_KEY.EVENT_UPDATE)) {
      store.push({
        key: "edit",
        label: (
          <a
            type="link"
            className={`${
              item.analysisStatus === "0"
                ? styles.diasabledHref
                : styles.normalHref
            }`}
            onClick={() => editAnalysis(item)}
          >
            编辑
          </a>
        ),
      });
    }

    if (checkPermissions(BOARD_KEY.EVENT_DELETE)) {
      store.push({
        key: "delete",
        label: (
          <a
            type="link"
            className={`${
              item.analysisStatus === "0"
                ? styles.diasabledHref
                : styles.normalHref
            }`}
            onClick={() => toDeleteInsightAnalysis(item)}
          >
            删除
          </a>
        ),
      });
    }

    if (checkPermissions(BOARD_KEY.EVENT_RECALCULATE) && item.analysisStatus === "2") {
      store.push({
        key: "recalculate",
        label: (
          <a
            type="link"
            style={{ color: "var( --text-font-color9)" }}
            onClick={() => toRecalculateRFM(item.id)}
          >
            重新计算
          </a>
        ),
      });
    }
    return store;
  };
  const editAnalysis = (record: Record<string, any>) => {
    if (record.analysisStatus === "0") {
      return false;
    }
    navigate("/DataBoard/Basis/RFMAnalysis/create", {
      state: {
        id: record.id,
      },
    });
  };

  const toUpload = async (record: Record<string, any>)=> {
    const params={ 
      taskLocation: "看板/基础看板/RFM分析",
      moduleName: "RFM分析报告",
      analysisId: record.id
    }
    await reportExport(params).then((res) => {
      if (res?.code === 200) {
        message.success(DOWNLOAD_SUCCESS_TEXT);
      }
    });
  };

  const toRecalculateRFM = async (id: string) => {
    await recalculateRFM(id).then((res) => {
      if (res?.code === 200) {
        message.success("重新计算成功");
        getInsightAnalysis(pageInfo);
      }
    });
  };

  // 删除分析报告
  const toDeleteInsightAnalysis = (item: any) => {
    if (item.analysisStatus === "0") {
      return false;
    }
    confirm({
      title: "确定要删除该分析报告吗？",
      okText: "确认删除",
      onOk() {
        confirmDeleteInsightAnalysis(item.id);
      },
    });
  };

  // 确认删除分析报告
  const confirmDeleteInsightAnalysis = async (id: string) => {
    await deleteInsightAnalysis(id).then((res) => {
      if (res?.code === 200) {
        message.success("删除成功");
        getInsightAnalysis(pageInfo);
      }
    });
  };

  // 页面跳转或切换显示条数
  const pageChange = (page: any, size: any, sorter: any) => {
    let sorterSearchFiled = {};
    if (!sorter.order) {
      sortValue.orderByColumn = "";
      sortValue.isAsc = "";

      sorterSearchFiled = {
        orderByCreateTime: "",
        orderByUpdateTime: "",
        orderBySubUpdateTime: "",
      };
    } else {
      sortValue.orderByColumn = sorter.columnKey;
      sortValue.isAsc = sorter.order === "ascend" ? "asc" : "desc";

      if (sorter.columnKey === "createdAt") {
        sorterSearchFiled = {
          orderByCreateTime: sorter.order === "ascend" ? "1" : "2",
          orderByUpdateTime: "",
          orderBySubUpdateTime: "",
        };
      }
      if (sorter.columnKey === "updatedAt") {
        sorterSearchFiled = {
          orderByUpdateTime: sorter.order === "ascend" ? "1" : "2",
          orderByCreateTime: "",
          orderBySubUpdateTime: "",
        };
      }
      if (sorter.columnKey === "subReportUpdateTime") {
        sorterSearchFiled = {
          orderBySubUpdateTime: sorter.order === "ascend" ? "1" : "2",
          orderByCreateTime: "",
          orderByUpdateTime: "",
        };
      }
    }
    getInsightAnalysis({
      current: page.current,
      size: page.pageSize,
      ...listParams,
      ...sorterSearchFiled,
    });
  };

  const onFinish = (data: any) => {
    if (data.analysisStatus.length === 0) {
      data.analysisStatus = statusOptions.map((item) => item.value);
    }
    const obj:any = {
      ...data,
      createdAt: Array.isArray(data.createdAt)
      ? {
          start: dayjs(data.createdAt[0]).format("YYYY-MM-DD"),
          end: dayjs(data.createdAt[1]).format("YYYY-MM-DD"),
        }
      : "",
    updatedAt: Array.isArray(data.updatedAt)
      ? {
          start: dayjs(data.updatedAt[0]).format("YYYY-MM-DD"),
          end: dayjs(data.updatedAt[1]).format("YYYY-MM-DD"),
        }
      : "",
    subReportUpdateTime: Array.isArray(data.subReportUpdateTime)
      ? {
          start: dayjs(data.subReportUpdateTime[0]).format("YYYY-MM-DD"),
          end: dayjs(data.subReportUpdateTime[1]).format("YYYY-MM-DD"),
        }
      : "",
    }
    setListParams(obj)
    getInsightAnalysis(obj);
  };

  // 获取洞察分析列表
  const getInsightAnalysis = async (v: object) => {
    console.log(v)
    let params: any = {
      ...listParams,
      ...pageInfo,
      ...v,
    };
    if (params.createdAt === "") {
      delete params.createdAt;
    }
    if (params.updatedAt === "") {
      delete params.updatedAt;
    }
    if (params.subReportUpdateTime === "") {
      delete params.subReportUpdateTime;
    }
    await getInsightAnalysisList(params).then((res) => {
      if (res?.code === 200) {
        setTableData(res.data.records);
        setPageInfo({
          current: res.data.current,
          size: res.data.size,
          total: res.data.total,
        });
      }
    });
  };
  //操作中三个点
  const [iconColorPointBack, setIconColorPointBack] = useState('');
  const [checkUserId, setCheckUserId] = useState('' as any);
  const handleMouseEnterPoint = (i:any) => {
      setCheckUserId(i.id)
      setIconColorPointBack('var(--text-font-color35)');
  };
  const handleMouseLeavePoint = () => {
      setCheckUserId('')
      setIconColorPointBack('');
  };
  useEffect(() => {
    getInsightAnalysis(pageInfo);
  }, []);

  const handleFilterClick = () => {
    filterViewRef.current?.showFilterView();
  };

  return (
    <div className={styles.container}>
      <div className={styles["TableSearch-bgd"]}>
        <div className={styles.headBox}>
          <div className={styles.titleText}>
            <ModuleTitle mark="large" title="RFM分析报告" />
          </div>
          <div className={styles.searchBox}>
            <SearchIconInput
              placeholder="请输入分析报告名称"
              onSearch={(e: string) => {
                setListParams({
                  ...listParams,
                  analysisName: e,
                });
                getInsightAnalysis({
                  analysisName: e,
                });
              }}
            ></SearchIconInput>
            <FilterIcon
              handleFilterClick={handleFilterClick}
              filterTitle={"过滤器"}
            />
            <span
              style={{
                width: "0",
                height: "16px",
                border: "var(--border3)",
                marginRight: 16,
              }}
            ></span>
            <SystemButton
              title="新建分析"
              type="primary"
              onClick={() =>
                navigate("/DataBoard/Basis/RFMAnalysis/create", {})
              }
              permissions={BOARD_KEY.EVENT_ADD}
            />
          </div>
        </div>
        <FilterView
          ref={filterViewRef}
          searchList={searchList}
          finish={onFinish}
          defaultFormValue={defaultSearchInfo}
        />

        <Table
          style={{ marginTop: 38 }}
          columns={columns}
          dataSource={tableData}
          pagination={{
            total: pageInfo.total,
            showTotal: (total) => `共${total}条`,
            pageSize: pageInfo.size,
            showSizeChanger: true,
          }}
          onChange={(page, pageSize, sort) => pageChange(page, pageSize, sort)}
        />
      </div>
    </div>
  );
}

export default RfmAnalysis;
