/**
 * 客质拆分
 */
// @ts-nocheck
import React, { useEffect, useState, useRef } from "react";
import styles from "./index.module.scss";
import { Button, message } from "antd";
import { downLoadCustomerSplit, getNewGroup } from "@/crm/api/industryBoardApi";
import dayjs from "dayjs";
import CustomQualitySplitByBuyFrequency from "./compnent/CustomQualitySplitByBuyFrequency"; // 复购周期分布
import CustomQualitySplitByPrice from "./compnent/CustomQualitySplitByPrice"; // 笔单价分布
import CustomQualitySplitByQuantity from "./compnent/CustomQualitySplitByQuantity"; // 笔件数分布
import CustomQualitySplitByRecencyCycle from "./compnent/CustomQualitySplitByRecencyCycle"; // 购买频次分布
import { checkPermissions } from "@/utils/utils";
import { INDUSTRYCROWD_KEY } from "@/router/Permissions";
import {
  useGetChannelList,
  useGetShoplListBySeveralChannel,
} from "@/crm/assets/public/industryBoard";

function CustomQualitySplit(props: any) {
  const starthms = " 00:00:00";
  const endhms = " 23:59:59";
  const { channelAllList } = useGetChannelList("customer");
  const { shopListBySeveralChannel } =
    useGetShoplListBySeveralChannel("customer");
  const { searchForm } = props;
  const recencyCycleRef = useRef<any>(null);
  // 购买频次分布点击联动，复购周期分布所需参数
  const [linkage, setLinkage] = useState<boolean>(false);
  const [recencyValue, setRecencyValue] = useState<string>("");
  const [linkageType, setLinkageType] = useState<string>("");

  const [recencyName, setRecencyName] = useState<string>("");

  // 下载所需参数-保存
  const [typeForDownLoadBdj, setTypeForDownLoadBdj] = useState<any>();
  const [dtoForDownLoadBdj, setDtoForDownLoadBdj] = useState<any>();
  const [typeForDownLoadBjs, setTypeForDownLoadBjs] = useState<any>();
  const [dtoForDownLoadBjs, setDtoForDownLoadBjs] = useState<any>();
  const [typeForDownLoadGmpc, setTypeForDownLoadGmpc] = useState<any>();
  const [dtoForDownLoadGmpc, setDtoForDownLoadGmpc] = useState<any>();
  const [typeForDownLoadFgzq, setTypeForDownLoadFgzq] = useState<any>();
  const [dtoForDownLoadFgzq, setDtoForDownLoadFgzq] = useState<any>();

  // 下载所需参数-接口查询
  const [typeSearchForDownLoadBdj, setSearchTypeForDownLoadBdj] =
    useState<any>();
  const [dtoSearchForDownLoadBdj, setDtoSearchForDownLoadBdj] = useState<any>();
  const [typeSearchForDownLoadBjs, setSearchTypeForDownLoadBjs] =
    useState<any>();
  const [dtoSearchForDownLoadBjs, setDtoSearchForDownLoadBjs] = useState<any>();
  const [typeSearchForDownLoadGmpc, setSearchTypeForDownLoadGmpc] =
    useState<any>();
  const [dtoSearchForDownLoadGmpc, setDtoSearchForDownLoadGmpc] =
    useState<any>();
  const [typeSearchForDownLoadFgzq, setSearchTypeForDownLoadFgzq] =
    useState<any>();
  const [dtoSearchForDownLoadFgzq, setDtoSearchForDownLoadFgzq] =
    useState<any>();

  // 没有正在查询，则查询最后一次保存的
  const queryNewGrouping = async (type) => {
    const params = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.split(","),
      shopIds: searchForm?.shopId?.split(","),
      indexId: type,
    };
    let res = await getNewGroup(params);
    if (res?.code === 200) {
      if (type === "bdj") {
        setSearchTypeForDownLoadBdj(res.data?.type);
        setDtoSearchForDownLoadBdj(
          res.data?.type === "auto"
            ? {
                numberOfGroups: res.data?.autoVo?.groupNum,
                min: res.data?.autoVo?.minNum,
                max: res.data?.autoVo?.maxNum,
              }
            : res.data?.customList
        );
      } else if (type === "bjs") {
        setSearchTypeForDownLoadBjs(res.data?.type);
        setDtoSearchForDownLoadBjs(
          res.data?.type === "auto"
            ? {
                numberOfGroups: res.data?.autoVo?.groupNum,
                min: res.data?.autoVo?.minNum,
                max: res.data?.autoVo?.maxNum,
              }
            : res.data?.customList
        );
      } else if (type === "gmpc") {
        setSearchTypeForDownLoadGmpc(res.data?.type);
        setDtoSearchForDownLoadGmpc(
          res.data?.type === "auto"
            ? {
                numberOfGroups: res.data?.autoVo?.groupNum,
                min: res.data?.autoVo?.minNum,
                max: res.data?.autoVo?.maxNum,
              }
            : res.data?.customList
        );
      } else if (type === "fgzq") {
        setSearchTypeForDownLoadFgzq(res.data?.type);
        setDtoSearchForDownLoadFgzq(
          res.data?.type === "auto"
            ? {
                numberOfGroups: res.data?.autoVo?.groupNum,
                min: res.data?.autoVo?.minNum,
                max: res.data?.autoVo?.maxNum,
              }
            : res.data?.customList
        );
      }
    }
  };

  useEffect(() => {
    if (channelAllList.length > 0) {
      queryNewGrouping("bdj");
      queryNewGrouping("bjs");
      queryNewGrouping("gmpc");
      queryNewGrouping("fgzq");
    }
  }, [searchForm, channelAllList]);
  const download = async () => {
    const baseParams = {
      startDate: searchForm?.startDate,
      endDate: searchForm?.endDate,
      dateType: searchForm?.dateType,
      channels: searchForm?.platformType?.includes("all")
        ? ["all"]
        : searchForm?.platformType?.split(","),
      shopIds: searchForm?.shopId?.includes("all")
        ? ["all"]
        : searchForm?.shopId?.split(","),
    };
    // 笔单价
    let bdjParams = {
      configType: "auto",
      autoConfigDTO: { numberOfGroups: 5, min: null, max: null },
    };
    if (typeForDownLoadBdj) {
      if (typeForDownLoadBdj === "auto") {
        bdjParams.configType = "auto";
        bdjParams.autoConfigDTO = dtoForDownLoadBdj;
      }
      if (typeForDownLoadBdj === "custom") {
        bdjParams.configType = "custom";
        bdjParams.customConfigList = dtoForDownLoadBdj;
      }
    } else {
      bdjParams.configType = typeSearchForDownLoadBdj;
      if (typeSearchForDownLoadBdj === "auto") {
        bdjParams.autoConfigDTO = dtoSearchForDownLoadBdj;
      } else {
        bdjParams.customConfigList = dtoSearchForDownLoadBdj;
      }
    }

    // 笔件数
    let bjsParams = {
      configType: "auto",
      autoConfigDTO: { numberOfGroups: 5, min: null, max: null },
    };
    if (typeForDownLoadBjs) {
      if (typeForDownLoadBjs === "auto") {
        bjsParams.configType = "auto";
        bjsParams.autoConfigDTO = dtoForDownLoadBjs;
      }
      if (typeForDownLoadBjs === "custom") {
        bjsParams.configType = "custom";
        bjsParams.customConfigList = dtoForDownLoadBjs;
      }
    } else {
      bjsParams.configType = typeSearchForDownLoadBjs;
      if (typeSearchForDownLoadBjs === "auto") {
        bjsParams.autoConfigDTO = dtoSearchForDownLoadBjs;
      } else {
        bjsParams.customConfigList = dtoSearchForDownLoadBjs;
      }
    }

    // 购买频次
    let gmpcParams = {
      configType: "auto",
      autoConfigDTO: { numberOfGroups: 5, min: null, max: null },
    };
    if (typeForDownLoadGmpc) {
      if (typeForDownLoadGmpc === "auto") {
        gmpcParams.configType = "auto";
        gmpcParams.autoConfigDTO = dtoForDownLoadGmpc;
      }
      if (typeForDownLoadGmpc === "custom") {
        gmpcParams.configType = "custom";
        gmpcParams.customConfigList = dtoForDownLoadGmpc;
      }
    } else {
      gmpcParams.configType = typeSearchForDownLoadGmpc;
      if (typeSearchForDownLoadGmpc === "auto") {
        gmpcParams.autoConfigDTO = dtoSearchForDownLoadGmpc;
      } else {
        gmpcParams.customConfigList = dtoSearchForDownLoadGmpc;
      }
    }

    // 复购周期
    let fgzqParams = {
      configType: "auto",
      autoConfigDTO: { numberOfGroups: 5, min: null, max: null },
      linkType: linkageType ? linkageType : null,
      groupList: recencyValue ? recencyValue: null,
    };
    const flag = linkage ? typeSearchForDownLoadGmpc : typeForDownLoadFgzq;
    if (flag) {
      if (flag === "auto") {
        fgzqParams.configType = "auto";
        fgzqParams.autoConfigDTO = dtoForDownLoadFgzq;
      }
      if (flag === "custom") {
        fgzqParams.configType = "custom";
        fgzqParams.customConfigList = dtoForDownLoadFgzq;
      }
    } else {
      fgzqParams.configType = typeSearchForDownLoadFgzq;
      if (typeSearchForDownLoadFgzq === "auto") {
        fgzqParams.autoConfigDTO = dtoSearchForDownLoadFgzq;
      } else {
        fgzqParams.customConfigList = dtoSearchForDownLoadFgzq;
      }
    }
    const params = {
      file: {
        moduleName: `行业看板-人群细分-客质拆分-${dayjs().format(
          "YYYYMMDDHHmmss"
        )}`,
        taskLocation: "行业看板/人群细分/客质拆分",
        name: "全渠道客户交易明细数据", //文件名
      },
      bdj: {
        ...baseParams,
        ...bdjParams,
        indexId: "bdj",
        pieIndexTypes: ["num", "yoy", "rate"],
      },
      bjs: {
        ...baseParams,
        ...bjsParams,
        indexId: "bjs",
        pieIndexTypes: ["num", "yoy", "rate"],
      },
      gmpc: {
        ...baseParams,
        ...gmpcParams,
        indexId: "gmpc",
        pieIndexTypes: ["num", "yoy", "rate"],
      },
      fgzq: {
        ...baseParams,
        ...fgzqParams,
        indexId: "fgzq",
        pieIndexTypes: ["num", "yoy", "rate"],
      },
    };
    const res = await downLoadCustomerSplit(params);
    if (res?.code === 200) {
      message.success("导出中，请到下载中心查看进度");
    } else {
      message.success("导出失败");
    }
  };

  // 切换联动按钮
  const switchLinkage = (v: boolean) => {
    setLinkage(v);
  };

  // 联动数据
  const setLinkageData = (value: string, name: string) => {
    setRecencyValue(value);
    setRecencyName(name);
  };

  const setParamForDownLoadBdj = (type: string, data: any) => {
    setTypeForDownLoadBdj(type);
    setDtoForDownLoadBdj(data);
  };
  const setParamForDownLoadBjs = (type: string, data: any) => {
    setTypeForDownLoadBjs(type);
    setDtoForDownLoadBjs(data);
  };
  const setParamForDownLoadGmpc = (type: string, data: any) => {
    setTypeForDownLoadGmpc(type);
    setDtoForDownLoadGmpc(data);
  };
  const setParamForDownLoadFgzq = (type: string, data: any) => {
    setTypeForDownLoadFgzq(type);
    setDtoForDownLoadFgzq(data);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>客质拆分</div>
        {checkPermissions(INDUSTRYCROWD_KEY.BYQUALITY_EXPORT) && (
          <Button onClick={download}>导出</Button>
        )}
      </div>
      <div className={styles.wrap}>
        <div className={styles.item}>
          <CustomQualitySplitByPrice
            searchForm={searchForm}
            setParamForDownLoad={(type: string, data: any) => {
              setParamForDownLoadBdj(type, data);
            }}
          />
        </div>
        <div className={styles.item}>
          <CustomQualitySplitByQuantity
            searchForm={searchForm}
            setParamForDownLoad={(type: string, data: any) => {
              setParamForDownLoadBjs(type, data);
            }}
          />
        </div>
      </div>

      <div className={`${linkage ? styles.wrapLinkage : styles.wrap}`}>
        <div className={styles.item}>
          <CustomQualitySplitByRecencyCycle
            searchForm={searchForm}
            ref={recencyCycleRef}
            switchLinkage={(v: boolean) => {
              switchLinkage(v);
            }}
            setLinkageType={(type: string) => {
              setLinkageType(type);
            }}
            setLinkageData={(value: string, name: string) => {
              setLinkageData(value, name);
            }}
            setParamForDownLoad={(type: string, data: any) => {
              setParamForDownLoadGmpc(type, data);
            }}
          />
        </div>
        <div className={styles.item}>
          <CustomQualitySplitByBuyFrequency
            searchForm={searchForm}
            linkage={linkage}
            recencyValue={recencyValue}
            linkageType={linkageType}
            setParamForDownLoad={(type: string, data: any) => {
              setParamForDownLoadFgzq(type, data);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomQualitySplit;
