import { Input, InputNumber, Drawer, Space, Button, Form, TreeSelect, Select, message, Table, Dropdown, Popconfirm, Modal, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { PlusOutlined, PlusCircleFilled, EllipsisOutlined } from '@ant-design/icons';
import type { TableProps, TableColumnsType, GetRef, InputRef } from 'antd';
import ImageUpload from '../../../../SystemSettingManagement/SystemSetting/AppearanceConfiguration/components/ImageUpload';
import SKUDetail from '../SKUDetail/index';
import {
  getAllCategoryListApi,
  getSpuGoodsDetailsApi,
  getSaveSpuDataApi,
  getUpdateSpuDataApi,
  getFindCategoryApi,
  getParentIdApi,
  getAddCategoryApi,
  getAddBrandApi,
  getAddProductLineApi,
  getListByStratumApi,
  getCheckSkuApi,
} from '@/api/goodsManage/goodsManage';
import { getBrandListApi } from '@/api/brand/brand';
import { getProductLineListApi } from '@/api/productLines/productLines';
import styles from './index.module.scss';
import { GOODSMANAGE_KEY } from '@/router/Permissions';
import checkPermission from '../../../../../crm/assets/public/checkPermission';

const defaultItem = [
  {
    spuIdOne: '',
    name: '',
    pic: '',
    spuCode: '',
    referPrice: '',
    categoryId: '',
    brandId: '',
    productlineId: '',
    targetGender: '',
    targetAge: '',
    sceneCategory: '',
    createTime: '',
    updateTime: '',
    deleted: 0,
    isCombined: 0,
    isReleased: null,
  },
];

let CreadGoods = React.forwardRef(
  (
    props: {
      open?: boolean;
      drawer?: Function;
      drawerStatus?: string;
      spuIdOne?: string;
    },
    ref: any,
  ) => {
    // 注册form
    const [goodsForm] = Form.useForm();
    const [loading, setLoading] = useState(false); //防连击
    const [backgroundPic, setBackgroundPic] = useState<string>(''); // 主图链接
    const [skuOpen, setSKUOpen] = useState(false); //sku详情开关
    const [editStatus, setEditStatus] = useState(false); //sku编辑装填
    const [editItem, setEditItem] = useState<any>();
    const [blurStatus, setBlurStatus] = useState(false); //sku失焦状态
    const [creadCateGory, setCreadCategory] = useState(false); //sku失焦状态
    const [creadBrand, setCreadBrand] = useState(false); //sku失焦状态
    const [creadProductLine, setCreadProductLine] = useState(false); //sku失焦状态
    const [skuTableList, setSkuTableList] = useState<any>([]);
    const [brandList, setBrandList] = useState<any>([]); // 所有品牌列表
    const [productLineList, setProductLineList] = useState<any>([]); // 所有产品线列表
    const [productLineId, setProductLineId] = useState<any>();
    const [brandId, setBrandId] = useState<any>();
    const [selectCategoryId, setSelectCategoryId] = useState<any>();
    const [relevanceItem, setRelevanceItem] = useState<any>({});
    const [arrNumber, setArrNumber] = useState<any>(); //第几个数组
    const [isCombined, setIsCombined] = useState<any>();
    const [categoryList, setCategoryList] = useState<any>([]); // 商品类目列表
    const [brandName, setBrandName] = useState<any>();
    const [addBrandStatus, setAddBrandStatus] = useState(false);
    const [categoryName, setCategoryName] = useState<any>();
    const [addCategoryStatus, setAddCategoryStatus] = useState(false);
    const [productLineName, setproductLineName] = useState<any>();
    const [addProductLineStatus, setAddProductLineStatus] = useState(false);
    const [isReleased, setIsReleased] = useState<any>();
    const [editToastOpen, setEditToastOpen] = useState<any>();
    const [editData, setEditData] = useState<any>();
    const [categoryTierList, setCategoryTierList] = useState<any>();
    const [categoryTierId, setCategoryTierId] = useState<any>();
    const [parentIdList, setParentIdList] = useState<any>();
    const [selectParentId, setSelectParentId] = useState<any>();
    const [openCheckAdd, setOpenCheckAdd] = useState<any>(false);
    const [drawerStatus, setDrawerStatus] = useState<any>(props.drawerStatus);
    // 商品详情抽屉开关
    const handleDrawerSwitch = () => {
      if (props.drawer) {
        goodsForm.resetFields();
        props.drawer();
        setEditStatus(false);
        setSkuTableList([]);
        setSelectCategoryId(null);
        setBrandId(null);
        setProductLineId(null);
        setBackgroundPic('');
        setIsCombined(null);
        setIsReleased(null);
        setEditData(null);
      }
    };
    // form表单input change方法
    const fillLoginForm = (e: any, type?: any) => {
      goodsForm.setFieldsValue(e.value);
    };
    // form表单校验方法
    const onFinish = (values: any) => {
      if (!goodsForm.getFieldValue('name') || /^[\s]+$/.test(goodsForm.getFieldValue('name'))) {
        message.error('请输入商品名称');
        return;
      }
      if(goodsForm.getFieldValue('name')){
        goodsForm.setFieldValue('name',goodsForm.getFieldValue('name').trim())
      }
      if (!goodsForm.getFieldValue('spuCode')) {
        message.error('请输入商品编码');
        return;
      }
      if (!/^[a-zA-Z0-9-]+$/.test(goodsForm.getFieldValue('spuCode'))) {
        message.error('商品编码填写错误，请重新填写');
        return;
      }
      if (!goodsForm.getFieldValue('categoryId')) {
        message.error('请选择商品类目');
        return;
      }
      if (!goodsForm.getFieldValue('brandId')) {
        message.error('请选择品牌');
        return;
      }
      if (!goodsForm.getFieldValue('productlineId')) {
        message.error('请选择产品线');
        return;
      }
      // setLoading(false);
      // console.log(123, skuTableList);
      // console.log(goodsForm)
      if (editStatus) {
        return message.error('请先保存编辑项');
      }
      if (loading) {
        return;
      }
      setLoading(true);
      goodsForm
        .validateFields()
        .then(res => {
          // 校验通过，执行相应操作
          // console.log('校验通过，表单数据为:', res);
          let updatedArray = skuTableList.map((obj: any) => {
            return { ...obj, ['isCombined']: isCombined };
          });
          const filterItem = updatedArray.filter((item: any) => item.deleted !== 1);
          const findGoodsItem = filterItem.find((item: any) => !item.goodsMappingVOList || item.goodsMappingVOList.length <= 0);
          const findGroupItem = filterItem.find((item: any) => !item.sysCombinedMappingVOList || item.sysCombinedMappingVOList.length <= 0);
          if (isCombined === 0 && findGoodsItem) {
            setLoading(false);
            return message.error('当前SKU还未进行电商平台关联，请添加关联sku建立关联映射关系。');
          }
          if (isCombined === 1 && findGroupItem) {
            setLoading(false);
            return message.error('当前SKU还未进行电商平台关联，请添加关联sku建立关联映射关系。');
          }
          let param = { ...res, goodsSysSkuVOList: updatedArray };
          if (drawerStatus === 'edit') {
            param = { ...param, spuIdOne: props.spuIdOne };
            setEditToastOpen(true);
            setEditData(param);
          }
          if (drawerStatus === 'add') {
            param = { ...param, spuIdOne: '' };
            getSaveSpuData(param);
          }
          setLoading(false);
        })
        .catch(errorInfo => {
          // 校验失败，可以根据errorInfo进行相应处理
          // message.error( errorInfo.errorFields[0].errors);
          setLoading(false);
          // console.log('校验失败:', errorInfo.errorFields[0].errors);
        });
    };
    // 编辑时的保存
    const handleEditSave = () => {
      // return;
      setEditToastOpen(false);
      getUpdateSpuData(editData);
    };
    // 新建
    const getSaveSpuData = async (param: any) => {
      const res = await getSaveSpuDataApi(param);
      if (res.code === 200) {
        message.success('保存成功');
        setLoading(false);
        handleDrawerSwitch();
      } else {
        message.error(res.msg);
      }
    };
    // 更改
    const getUpdateSpuData = async (param: any) => {
      const res = await getUpdateSpuDataApi(param);
      if (res.code === 200) {
        message.success('保存成功');
        setLoading(false);
        handleDrawerSwitch();
      } else {
        message.error(res.msg);
      }
    };
    // 查询类目层级
    const getFindCategory = async () => {
      const res = await getFindCategoryApi({ pageNum: 1, pageSize: 10, id: 129, enumCodeOrName: '' });
      if (res.code === 200) {
        const arr = res.data.rows?.map((e: any) => ({ value: e.enumCode, label: e.enumName }));
        setCategoryTierList(arr);
      }
    };
    // 查询父级Id
    const getParentId = async () => {
      const res = await getParentIdApi();
      if (res.code === 200) {
        setParentIdList(res.data);
        const arr = res.data?.map((e: any) => ({ value: e, label: e }));
        setParentIdList(arr);
      }
    };

    // 添加类目
    const getAddCategory = async () => {
      if (!categoryTierId) {
        return message.error('请选择类目层级');
      }
      if (categoryName === '') {
        return message.error('请填写类目名称');
      }
      if (categoryTierId && categoryTierId != '1' && !selectParentId) {
        return message.error('请选择父级ID');
      }
      const res = await getAddCategoryApi({
        categoryBos: [
          {
            categoryName: categoryName,
            name: '',
            parentId: selectParentId,
            stratum: categoryTierId,
            createTime: '',
            updateTime: '',
          },
        ],
        categoryIdList: [],
      });
      if (res.code === 200) {
        message.success('添加成功');
        setCreadCategory(false);
        setAddCategoryStatus(true);
      }
    };

    // 添加品牌
    const getAddBrand = async () => {
      if (brandName === '') {
        return message.error('请填写品牌名称');
      }
      const res = await getAddBrandApi([brandName]);
      if (res.code === 200) {
        message.success('添加成功');
        setCreadBrand(false);
        setAddBrandStatus(true);
      }
    };
    // 添加产品线
    const getAddProductLine = async () => {
      if (productLineName === '') {
        return message.error('请填产品线名称');
      }
      const res = await getAddProductLineApi([{ productLineName: productLineName }]);
      if (res.code === 200) {
        message.success('添加成功');
        setCreadProductLine(false);
        setAddProductLineStatus(true);
      }
    };

    const getListByStratum = async (value: any) => {
      if (value === '1') {
        return;
      }
      const res = await getListByStratumApi({ stratum: value });
      if (res.code === 200) {
        const arr = res.data?.map((e: any) => ({ value: e, label: e }));
        setParentIdList(arr);
      }
    };
    // 删除校验
    const getCheckSku = async (value: any, dataIndex: any) => {
      const res = await getCheckSkuApi({ skuIdOne: value + '' });
      if (res.code === 200) {
        skuTableList[dataIndex].deleted = 1;
        setSkuTableList([...skuTableList]);
        message.success('删除成功');
      } else if (res.code === 500 && res.msg === '存在含有skuIdOne订单，商品不能删除') {
        setOpenCheckAdd(true);
      } else {
        message.error(res.msg);
      }
    };

    // 删除
    const getDelete = async (item: any, dataIndex: any) => {
      if (item.skuIdOne) {
        getCheckSku(item.skuIdOne, dataIndex);
      } else {
        let deleteItem = skuTableList.filter((column: any) => column.addKey !== item.addKey);
        setSkuTableList(deleteItem);
        message.success('删除成功');
      }
    };
    // 查找类目层级和id
    const findItemByValue: any = (arr: any, targetValue: any) => {
      for (let item of arr) {
        if (item.value === targetValue) {
          return item;
        }
        if (item.children) {
          const foundItem = findItemByValue(item.children, targetValue);
          if (foundItem) {
            return foundItem;
          }
        }
      }
      return null;
    };

    // 抽屉顶部
    const drawerHeader = () => {
      return (
        <div>
          <span className={styles.topTitle}>{drawerStatus === 'add' ? '新建商品' : '编辑商品'}</span>
          <span className={styles.uploadText}>通过新建商品创建SPU和SKU</span>
        </div>
      );
    };
    // 上传图片显示层
    const UploadImage = ({ index }: { index: number }) => (
      <div>
        {index === 1 && backgroundPic ? (
          <img
            src={backgroundPic}
            style={{
              width: 100,
              height: 100,
            }}
          />
        ) : (
          <button
            style={{
              border: 0,
              background: 'none',
            }}
            type="button">
            <PlusOutlined style={{ color: 'var(--text-font-color28)' }} />
            <div style={{ color: 'var(--text-font-color28)' }}>上传</div>
          </button>
        )}
      </div>
    );
    // 子组件获取链接
    const getImageUrl = (url: string, index: number, name?: string) => {
      // 存进form
      goodsForm.setFieldValue('pic', url);
      setBackgroundPic(url);
    };
    const skuListUrl = (url: string, dataIndex: number, name?: string) => {
      // 存进form
      skuTableList[dataIndex].skuPic = url;
      setSkuTableList([...skuTableList]);
    };

    interface DataType {
      crowdId: string;
    }

    // 关联sku
    const skuSwitch = () => {
      setSKUOpen(!skuOpen);
    };
    // 新增sku
    const handleAddSku = () => {
      if (editStatus) {
        return message.error('请先保存编辑项');
      }
      const updatedSkuTableList = defaultItem.map((item: any, index: any) => ({
        ...item,
        addKey: index + skuTableList ? skuTableList.length : 0,
      }));
      if (skuTableList) {
        setSkuTableList([...skuTableList, ...updatedSkuTableList]);
      } else {
        setSkuTableList([...updatedSkuTableList]);
      }
      editSKU(updatedSkuTableList[0]);
    };
    // 编辑sku
    const editSKU = (item: any) => {
      setEditItem(item);
      setIsReleased(item.isReleased);
      setEditStatus(true);
    };
    // 更新sku对应数据项的值
    const handleInputChange = (value: string, record: any, key: string) => {
      record[key] = value;
    };
    // 编辑时如果点击了id不为edit的标签时,input变回span.没用了,可写了舍不得删,但其实有bug
    // useEffect(() => {
    // if (blurStatus) {
    //   const handleClickOutside = (event: any) => {
    //     console.log(event.target);
    //     if (event.target.id !== 'edit') {
    //       setEditStatus(false);
    //       setTimeout(() => {
    //         setBlurStatus(false);
    //       }, 100);
    //     }
    //   };
    //   document.body.addEventListener('click', handleClickOutside);
    //   return () => {
    //     document.body.removeEventListener('click', handleClickOutside);
    //   };
    // }
    // }, [blurStatus]);
    // 表格
    let columns: TableProps<DataType>['columns'] = [
      {
        title: 'SKU图片',
        dataIndex: 'skuPic',
        key: 'skuPic',
        render: (_: any, record: any, dataIndex: any) =>
          editStatus && editItem === record ? (
            <div className={styles.imageScale}>
              {record.skuPic ? (
                <ImageUpload getImageUrl={skuListUrl} width={100} height={100} isButton={false} imageIndex={dataIndex} isEqual={true}>
                  <img src={record.skuPic} className={styles.skuPic} />
                </ImageUpload>
              ) : (
                <ImageUpload getImageUrl={skuListUrl} width={100} height={100} isButton={false} imageIndex={dataIndex} isEqual={true}>
                  <button
                    style={{
                      border: 0,
                      background: 'none',
                    }}
                    type="button">
                    <PlusOutlined style={{ color: 'var(--text-font-color28)' }} />
                    <div style={{ color: 'var(--text-font-color28)' }}>上传</div>
                  </button>
                </ImageUpload>
              )}
            </div>
          ) : (
            <img
              src={
                record.skuPic ||
                'https://rcomnicrmprd.blob.core.chinacloudapi.cn/statictest/statictest/2024/07/31/c2a66ca379754cd18eb5de4b5c115c4f.png'
              }
              className={styles.skuPic}
            />
          ),
      },
      {
        title: 'SKU名称',
        dataIndex: 'name',
        key: 'name',
        width: '200px',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <Input maxLength={100} defaultValue={_} onChange={e => handleInputChange(e.target.value, record, 'name')} />
          ) : (
            <Tooltip title={_}>
              <div
                className={_}
                onClick={() => {
                  skuSwitch();
                  setRelevanceItem(record);
                }}>
                {_ || '--'}
              </div>
            </Tooltip>
          ),
      },
      {
        title: '条形码',
        dataIndex: 'barCode',
        key: 'barCode',
        width: '200px',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <Input maxLength={50} defaultValue={_} onChange={e => handleInputChange(e.target.value, record, 'barCode')} />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '颜色',
        dataIndex: 'color',
        key: 'color',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <Input maxLength={20} defaultValue={_} onChange={e => handleInputChange(e.target.value, record, 'color')} />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '尺码',
        dataIndex: 'size',
        key: 'size',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <Input maxLength={20} defaultValue={_} onChange={e => handleInputChange(e.target.value, record, 'size')} />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '总重量(KG)',
        dataIndex: 'totalWeight',
        key: 'totalWeight',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <InputNumber maxLength={20} min={0} defaultValue={_} onChange={e => handleInputChange(e + '', record, 'totalWeight')} />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '净含量(KG)',
        dataIndex: 'netContent',
        key: 'netContent',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <InputNumber maxLength={10} min={0} defaultValue={_} onChange={e => handleInputChange(e + '', record, 'netContent')} />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '是否放行',
        dataIndex: 'isReleased',
        key: 'isReleased',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <Select
              placeholder="请选择"
              value={isReleased}
              allowClear
              onChange={e => {
                setIsReleased(e);
                handleInputChange(e ? e : '', record, 'isReleased');
              }}
              options={[
                { value: 1, label: '是' },
                { value: 0, label: '否' },
              ]}
            />
          ) : (
            <span>{record.isReleased === 0 ? '否' : record.isReleased === 1 ? '是' : '--'}</span>
          ),
      },

      {
        title: '销售价',
        dataIndex: 'salePrice',
        key: 'salePrice',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <InputNumber
              maxLength={10}
              prefix="￥"
              min={0}
              defaultValue={_}
              onChange={e => handleInputChange(e + '', record, 'salePrice')}
            />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
      {
        title: '成本价',
        dataIndex: 'costPrice',
        key: 'costPrice',
        render: (_: any, record: any) =>
          editStatus && editItem === record ? (
            <InputNumber
              maxLength={10}
              prefix="￥"
              min={0}
              defaultValue={_}
              onChange={e => handleInputChange(e + '', record, 'costPrice')}
            />
          ) : (
            <span>{_ || '--'}</span>
          ),
      },
    ];
    if (editStatus) {
      columns = columns.filter(column => column.key !== 'action');
      columns = [
        ...columns,
        {
          title: '操作',
          key: 'save',
          align: 'center',
          fixed: 'right',
          width: '88px',
          render: (_: any, record: any) =>
            editStatus && editItem === record ? (
              <Tooltip title="数据已缓存，请点击右上角保存信息">
                <Button
                  type="link"
                  onClick={() => {
                    if (!record.barCode) {
                      return message.error('请先输入条形码再保存');
                    }
                    setEditStatus(false);
                  }}>
                  确定
                </Button>
              </Tooltip>
            ) : (
              <Button type="link" disabled>
                确定
              </Button>
            ),
        },
      ];
    } else {
      columns = columns.filter(column => column.key !== 'save');
      columns = [
        ...columns,
        {
          title: '操作',
          key: 'action',
          align: 'center',
          fixed: 'right',
          width: '88px',
          render: (_: any, record: any, dataIndex: number) => (
            <div>
              {/* <Dropdown menu={{ items: getTableOperationButtonList(record) }} trigger={['click']}></Dropdown> */}
              <Dropdown menu={{ items: getTableOperationButtonList(record, dataIndex) }} trigger={['click']}>
                {/* <a onClick={e => e.preventDefault()}> */}
                <EllipsisOutlined
                  style={{
                    color: true ? 'var(--text-font-color33)' : 'var(--text-font-color34)',
                    cursor: 'pointer',
                  }}
                />
              </Dropdown>
            </div>
          ),
        },
      ];
    }
    // 操作下拉栏显示数据
    const getTableOperationButtonList = (item: any, dataIndex: any) => {
      const store = [];
      if (true) {
        //创建方式为规则创建
        store.push({
          key: 'log',
          label: (
            <Popconfirm
              title="编辑"
              description="当前修改影响上层应用统计数据结果，确认修改吗？"
              onConfirm={() => {
                editSKU(item);
              }}>
              <a style={{ color: 'var( --text-font-color9)' }}>编辑</a>
            </Popconfirm>
          ),
        });
      }

      if (true) {
        //创建方式为规则创建
        store.push({
          key: 'copy',
          label: (
            <a
              style={{ color: 'var( --text-font-color9)' }}
              onClick={() => {
                skuSwitch();
                setRelevanceItem(item);
                setArrNumber(dataIndex);
                setIsCombined(goodsForm.getFieldValue('isCombined'));
              }}>
              关联SKU
            </a>
          ),
        });
      }
      if (checkPermission(GOODSMANAGE_KEY.SPU_DELETE)) {
        store.push({
          key: 'del',
          label: (
            <Popconfirm
              title="删除"
              description="当前修改影响上层应用统计数据结果，确认删除吗？"
              onConfirm={() => {
                getDelete(item, dataIndex);
              }}>
              <a style={{ color: 'var( --text-font-color9)' }}>删除</a>
            </Popconfirm>
          ),
        });
      }
      return store;
    };
    // 所有类目
    const getAllCategoryList = async () => {
      const res = await getAllCategoryListApi();
      if (res.code === 200) {
        setCategoryList(res.data);
      }
    };

    // 获取商品详情
    const getSpuGoodsDetails = async () => {
      const res = await getSpuGoodsDetailsApi({ spuIdOne: props.spuIdOne });
      if (res.code === 200) {
        setSkuTableList(res.data.goodsSysSkuVOList);
        setProductLineId(Number(res.data.productlineId) || null);
        setBrandId(Number(res.data.brandId) || null);
        setSelectCategoryId(Number(res.data.categoryId) || null);
        setIsCombined(res.data.isCombined || 0);
        setBackgroundPic(res.data.pic);
        goodsForm.setFieldsValue({
          name: res.data.name,
          pic: res.data.pic,
          spuCode: res.data.spuCode,
          referPrice: res.data.referPrice,
          brandId: Number(res.data.brandId) || null,
          categoryId: Number(res.data.categoryId) || null,
          productlineId: Number(res.data.productlineId) || null,
          sceneCategory: res.data.sceneCategory,
          targetGender: res.data.targetGender,
          targetAge: res.data.targetAge,
          isCombined: res.data.isCombined || 0,
        });
      }
    };
    // 品牌数据
    const getBrandList = async () => {
      const res = await getBrandListApi();
      if (res.code === 200) {
        const arr = res.data?.map((e: any) => ({ value: e.brandId, label: e.brand }));
        setBrandList(arr);
      }
    };
    // 产品线数据
    const getProductLineList = async () => {
      const res = await getProductLineListApi();
      if (res.code === 200) {
        const arr = res.data?.map((e: any) => ({ value: e.productLineId, label: e.productLineName }));
        setProductLineList(arr);
      }
    };

    useEffect(() => {
      // 打开并且不是编辑时才调用
      if (props.open) {
        setDrawerStatus(props.drawerStatus);
        if (props.drawerStatus === 'edit') {
          getSpuGoodsDetails();
        }
        if (props.drawerStatus === 'add') {
          // 测试用,整段代码将删除
          // getSpuGoodsDetails();
          goodsForm.setFieldValue('isCombined', 0);
          setIsCombined(0);
        }
        getAllCategoryList();
        getBrandList();
        getProductLineList();
        getFindCategory();
        getParentId();
      }
    }, [props.open]);
    const handleRelevanceItemChange = (newRelevanceItem: any, number: any) => {
      // 取到的数组,放回
      // console.log(newRelevanceItem);
      setRelevanceItem(newRelevanceItem);
      skuTableList[number] = newRelevanceItem;
      setSkuTableList([...skuTableList]);
    };
    return (
      <div>
        <Drawer
          width={960}
          title={drawerHeader()}
          onClose={handleDrawerSwitch}
          open={props.open}
          extra={
            <Space>
              <Button onClick={handleDrawerSwitch}>取消</Button>
              <Button type="primary" onClick={onFinish} htmlType="submit">
                保存
              </Button>
            </Space>
          }>
          <Form form={goodsForm} layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item
              label="商品名称"
              name="name"
              required
              >
              <Input style={{ width: 480 }} autoComplete="off" placeholder="请输入商品名称" showCount maxLength={100} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="商品主图" name="pic">
              <div className={styles.uploadPic}>
                <div style={{ width: 100, height: 100 }}>
                  <ImageUpload getImageUrl={getImageUrl} width={100} height={100} isButton={false} imageIndex={1} isEqual={true}>
                    <UploadImage index={1} />
                  </ImageUpload>
                </div>
                <div className={styles.uploadText}>
                  <div>支持.jpg、.png格式</div>
                  <div>大小: 100 * 100 </div>
                </div>
              </div>
            </Form.Item>
            <Form.Item
              label="商品编码"
              name="spuCode"
              required>
              <Input style={{ width: 480 }} autoComplete="off" placeholder="请输入商品编码" showCount maxLength={50} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="参考价格" name="referPrice" required>
              <InputNumber
                prefix="￥"
                min={0}
                style={{ width: 480 }}
                autoComplete="off"
                placeholder="请输入参考价格"
                maxLength={20}
                onChange={fillLoginForm}
              />
            </Form.Item>
            <Form.Item label="商品类目" name="categoryId" required>
              <div>
                <TreeSelect
                  style={{ width: 480 }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  placeholder="请选择商品类目"
                  allowClear
                  treeDefaultExpandAll
                  value={selectCategoryId ? selectCategoryId : null}
                  onChange={(value: any) => {
                    // console.log(value);
                    setSelectCategoryId(value);
                    goodsForm.setFieldValue('categoryId', value ? value + '' : value);
                  }}
                  treeData={categoryList}
                  onClick={() => {
                    if (addCategoryStatus) {
                      getAllCategoryList();
                    }
                  }}
                />
                <Button
                  type="link"
                  onClick={() => {
                    setCreadCategory(true);
                    setCategoryName('');
                    setCategoryTierId(null);
                    setSelectParentId(null);
                  }}>
                  <PlusOutlined className={styles.fontSize12} />
                  新建类目
                </Button>
              </div>
            </Form.Item>
            <Form.Item label="品牌" name="brandId" required>
              <div>
                <Select
                  style={{ width: 480 }}
                  placeholder="请选择品牌"
                  value={brandId ? brandId : null}
                  allowClear
                  onChange={(value: any) => {
                    setBrandId(value);
                    goodsForm.setFieldValue('brandId', value ? value + '' : value);
                  }}
                  onClick={() => {
                    if (addBrandStatus) {
                      getBrandList();
                    }
                  }}
                  options={brandList}
                 ></Select>
                <Button
                  type="link"
                  onClick={() => {
                    setCreadBrand(true);
                    setBrandName('');
                  }}>
                  <PlusOutlined className={styles.fontSize12} />
                  新建品牌
                </Button>
              </div>
            </Form.Item>
            <Form.Item label="产品线" name="productlineId" required>
              <div>
                <Select
                  style={{ width: 480 }}
                  placeholder="请选择产品线"
                  value={productLineId ? productLineId : null}
                  allowClear
                  onChange={(value: any) => {
                    setProductLineId(value);
                    goodsForm.setFieldValue('productlineId', value ? value + '' : value);
                    goodsForm.validateFields(['productlineId']);
                  }}
                  onClick={() => {
                    if (addProductLineStatus) {
                      getProductLineList();
                    }
                  }}
                  options={productLineList}
                 ></Select>
                <Button
                  type="link"
                  onClick={() => {
                    setCreadProductLine(true);
                    setproductLineName('');
                  }}>
                  <PlusOutlined className={styles.fontSize12} />
                  新建产品线
                </Button>
              </div>
            </Form.Item>
            <Form.Item label="场景类目" name="sceneCategory" rules={[{ required: false }]}>
              <Input style={{ width: 480 }} autoComplete="off" placeholder="请输入场景类目" showCount maxLength={50} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="适用性别" name="targetGender" rules={[{ required: false }]}>
              <Input style={{ width: 480 }} autoComplete="off" placeholder="请输入适用性别" showCount maxLength={20} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item label="适用年龄" name="targetAge" rules={[{ required: false }]}>
              <Input style={{ width: 480 }} autoComplete="off" placeholder="请输入年龄" showCount maxLength={20} onChange={fillLoginForm} />
            </Form.Item>
            <Form.Item style={{ width: 480 }} label="是否组合商品" name="isCombined" rules={[{ required: false }]}>
              <Select
                placeholder="请选择"
                disabled={drawerStatus === 'edit'}
                style={{ width: 480 }}
                value={isCombined}
                allowClear
                onChange={(value: any) => {
                  if (editStatus) {
                    return;
                  }
                  setIsCombined(value);
                  goodsForm.setFieldValue('isCombined', value);
                  const updatedSkuTableList = skuTableList.map((item: any, index: any) => ({
                    ...item,
                    goodsMappingVOList: [],
                    sysCombinedMappingVOList: [],
                  }));
                  setSkuTableList(updatedSkuTableList);
                }}
                options={[
                  { value: 1, label: '是' },
                  { value: 0, label: '否' },
                ]}
              />
            </Form.Item>
          </Form>
          <div className={styles.goodsDetailTitle}>
            <span className={styles.topTitle}>销售规格</span>
            <Button type="default" onClick={handleAddSku}>
              新增SKU
            </Button>
          </div>
          <div className={styles.tableBox}>
            <Table
              columns={columns as any}
              dataSource={skuTableList.filter((item: any) => item.deleted !== 1)}
              scroll={{ x: 1500 }}
              pagination={{
                // total: tableInfo.total,
                // pageSize: tableInfo.pageSize,
                // current: tableInfo.pageNum,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '50', '100'],
                showTotal: total => `共 ${total} 条`,
              }}
              // onChange={handleTableChange}
            />
          </div>
        </Drawer>
        {/* sku详情抽屉 */}
        <SKUDetail
          open={skuOpen}
          drawer={skuSwitch}
          relevanceItem={relevanceItem}
          isCombined={isCombined}
          arrNumber={arrNumber}
          drawerStatus={drawerStatus}
          onRelevanceItemChange={handleRelevanceItemChange}></SKUDetail>
        <Modal
          title="新建类目"
          open={creadCateGory}
          onOk={getAddCategory}
          onCancel={() => {
            setCreadCategory(false);
          }}>
          <div>
            <div className={styles.popupText} style={{ marginTop: 30 }}>
              类目层级
            </div>
            <Select
              placeholder="请选择"
              className={styles.popupInput}
              value={categoryTierId}
              allowClear
              onChange={(value: any) => {
                setCategoryTierId(value);
                getListByStratum(value);
                setSelectParentId(null);
              }}
              options={categoryTierList}></Select>
            <div className={styles.popupText}>类目名称</div>
            <Input
              className={styles.popupInput}
              value={categoryName}
              onChange={(e: any) => {
                setCategoryName(e.target.value);
              }}
              allowClear
              placeholder="请输入类目名称"
              showCount
              maxLength={20}
            />
            {categoryTierId && categoryTierId != '1' && (
              <>
                <div className={styles.popupText}>父级ID</div>
                <Select
                  placeholder="请选择"
                  className={styles.popupInput}
                  value={selectParentId}
                  allowClear
                  onChange={(value: any) => {
                    setSelectParentId(value);
                  }}
                  options={parentIdList}></Select>
              </>
            )}
          </div>
        </Modal>
        <Modal
          title="新建品牌"
          open={creadBrand}
          onOk={getAddBrand}
          onCancel={() => {
            setCreadBrand(false);
          }}>
          <div>
            <div className={styles.popupText} style={{ marginTop: 30 }}>
              品牌名称
            </div>
            <Input
              className={styles.popupInput}
              value={brandName}
              onChange={(e: any) => {
                setBrandName(e.target.value);
              }}
              placeholder="请输入品牌名称"
              showCount
              maxLength={20}
            />
          </div>
        </Modal>
        <Modal
          title="新建产品线"
          open={creadProductLine}
          onOk={getAddProductLine}
          onCancel={() => {
            setCreadProductLine(false);
          }}>
          <div>
            <div className={styles.popupText} style={{ marginTop: 30 }}>
              产品线名称
            </div>
            <Input
              className={styles.popupInput}
              value={productLineName}
              onChange={(e: any) => {
                setproductLineName(e.target.value);
              }}
              placeholder="请输入产品线名称"
              showCount
              maxLength={20}
            />
          </div>
        </Modal>
        <Modal
          title="编辑"
          open={editToastOpen}
          onOk={() => {
            handleEditSave();
          }}
          onCancel={() => {
            setEditToastOpen(false);
          }}>
          <p>当前修改影响上层应用统计数据结果，确认修改吗？</p>
        </Modal>
        <Modal
          title="删除"
          open={openCheckAdd}
          okText="新建"
          onOk={() => {
            setSkuTableList([]);
            setOpenCheckAdd(false);
            setDrawerStatus('add');
          }}
          onCancel={() => {
            setOpenCheckAdd(false);
          }}>
          <p>该商品已出单，请重新创建新的组合商品</p>
        </Modal>
      </div>
    );
  },
);
export default CreadGoods;
